import { RouteComponentProps } from "@reach/router";
import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "rebass/styled-components";
import image from "../img/prizes-hero@1x.jpg";
import image2x from "../img/prizes-hero@2x.jpg";
import Copy from "./Copy";
import Hero from "./Hero";
import { Main, Wrapper } from "./Layout";
import SubHeading from "./SubHeading";

const Prizes: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Main>
        <Hero
          title={t("prizes.title", "Prizes")}
          image={image}
          image2x={image2x}
        ></Hero>
        <Copy>
          <Box my="4rem">
            <Box my="2rem">
              <SubHeading>{t("prizes.season.title", "Season")}</SubHeading>
              <h4>
                {t("prizes.season.firstPrize.title_23", "First Place Season")}
              </h4>
              <ul>
                <li>
                  {t(
                    "prizes.season.firstPrize.item1_23",
                    "Universal Gift Card worth 7500 NOK from OBOS"
                  )}
                </li>
                <li>
                  {t(
                    "prizes.season.firstPrize.item2_23",
                    "Annual subscription to TV 2 Play (value 389 NOK/month)"
                  )}
                </li>
                <li>{t("prizes.season.firstPrize.item3_23", "FC 2025")}</li>
                <li>
                  {t(
                    "prizes.season.firstPrize.item4_23",
                    "Signed National Team Jersey provided by Norsk Tipping"
                  )}
                </li>
                <li>
                  {t(
                    "prizes.season.firstPrize.item5_23",
                    "2 VIP tickets to any Eliteserien match of choice"
                  )}
                </li>
              </ul>
            </Box>
            <Box my="2rem">
              <h4>
                {t("prizes.season.secondPrize.title_23", "Second Place Season")}
              </h4>
              <ul>
                <li>
                  {t(
                    "prizes.season.secondPrize.item1_23",
                    "Universal Gift Card worth 7500 NOK from OBOS"
                  )}
                </li>
                <li>
                  {t(
                    "prizes.season.secondPrize.item2_23",
                    "Annual subscription to TV 2 Play (value 389 NOK/month)"
                  )}
                </li>
                <li>{t("prizes.season.secondPrize.item3_23", "FC 2025")}</li>
                <li>
                  {t(
                    "prizes.season.secondPrize.item4_23",
                    "Signed National Team Jersey provided by Norsk Tipping"
                  )}
                </li>
              </ul>
            </Box>
            <Box my="2rem">
              <h4>
                {t("prizes.season.thirdPrize.title_23", "Third Place Season")}
              </h4>
              <ul>
                <li>
                  {t(
                    "prizes.season.thirdPrize.item1_23",
                    "Universal Gift Card worth 7500 NOK from OBOS"
                  )}
                </li>
                <li>
                  {t(
                    "prizes.season.thirdPrize.item2_23",
                    "Annual subscription to TV 2 Play (value 389 NOK/month)"
                  )}
                </li>
                <li>{t("prizes.season.thirdPrize.item3_23", "FC 2025")}</li>
                <li>
                  {t(
                    "prizes.season.thirdPrize.item4_23",
                    "Signed National Team Jersey provided by Norsk Tipping"
                  )}
                </li>
              </ul>
            </Box>
          </Box>
          <Box my="4rem">
            <Box my="2rem">
              <SubHeading>
                {t(
                  "prizes.cup.title_23",
                  "ESF CUP - “New Opportunity” - From Round 10 Winner"
                )}
              </SubHeading>
              <h4>{t("prizes.cup.subHeading_23", "Winner")}</h4>
              <ul>
                <li>{t("prizes.cup.item1_23", "85-inch TV")}</li>
                <li>
                  {t(
                    "prizes.cup.item2_23",
                    "Annual subscription to TV 2 Play (value 389 NOK/month)"
                  )}
                </li>
              </ul>
            </Box>
          </Box>
          <Box my="4rem">
            <Box my="2rem">
              <SubHeading>
                {t("prizes.monthly.title", "Monthly Phases")}
              </SubHeading>
              <h4>{t("prizes.monthly.subHeading", "Manager of the Month")}</h4>
              <ul>
                <li>
                  {t(
                    "prizes.monthly.item1_23",
                    "Gift Card worth 2500 NOK provided by COOP. Can be used in all stores (grocery and construction)"
                  )}
                </li>
                <li>
                  {t("prizes.monthly.item2_23", "Double room at Thon Hotels")}
                </li>
                <li>
                  {t(
                    "prizes.monthly.item3_23",
                    "Annual subscription to TV 2 Play (value 389 NOK/month)"
                  )}
                </li>
              </ul>
            </Box>
          </Box>
        </Copy>
      </Main>
    </Wrapper>
  );
};

export default Prizes;
