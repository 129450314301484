import { RouteComponentProps } from "@reach/router";
import { hiDPI, size } from "polished";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { RootState } from "../core/store";
import { getCurrentEvent } from "../core/store/events/reducers";
import { IEvent } from "../core/store/events/types";
import { getPlayerData } from "../core/store/player/reducers";
import { IPlayer } from "../core/store/player/types";
import i18n from "../i18n";
// Hero images
import heroImg1x from "../img/home/hero.jpg";
import heroImg2x from "../img/home/hero@2x.jpg";
// Highlight images
import step1 from "../img/home/step-1@1x.png";
import step12x from "../img/home/step-1@2x.png";
import step13x from "../img/home/step-1@3x.png";
import step2 from "../img/home/step-2@1x.png";
import step22x from "../img/home/step-2@2x.png";
import step23x from "../img/home/step-2@3x.png";
import step3 from "../img/home/step-3@1x.png";
import step32x from "../img/home/step-3@2x.png";
import step33x from "../img/home/step-3@3x.png";
import step4 from "../img/home/step-4@1x.png";
import step42x from "../img/home/step-4@2x.png";
import step43x from "../img/home/step-4@3x.png";
import { ReactComponent as AltiboxLogo } from "../img/logos/altibox-logo.svg";
import { ReactComponent as ObosLogo } from "../img/logos/obos-logo.svg";
import { ReactComponent as Tv2Logo } from "../img/logos/tv2-logo.svg";
import { ReactComponent as NTLogo } from "../img/logos/norsk-tipping.svg";
import HighlightsProvider from "./highlights/HighlightsProvider";
import { Main, Wrapper } from "./Layout";
import Login from "./Login";
import ScoutProvider from "./scout/ScoutProvider";
import Status from "./status/Status";
import TriangleCard from "./TriangleCard";

const HomeHero = styled.div`
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-image: url(${heroImg1x});

  ${hiDPI(2)} {
    background-image: url(${heroImg2x});
    background-size: cover;
  }

  margin: 0 ${({ theme }) => theme.space[3]};
  margin-bottom: 100px;
  padding-top: 270px;
  text-align: center;
  height: 0;
  position: relative;
  border-radius: 12px;
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    border-radius: 12px;
  }
`;

const Tagline = styled.h3`
  display: inline-block;
  margin: 0 ${({ theme }) => theme.space[2]};
  padding: ${({ theme }) => theme.space[5]};
  background: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fonts.impact};
  font-size: 20px;
  transform: translateY(-50%);
  line-height: 1;
  border-radius: 12px;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    font-size: 34px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    font-size: 52px;
  }
`;

const Highlights = styled.div`
  margin: ${({ theme }) => theme.space[4]} ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    justify-content: space-between;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-left: 0;
    margin-right: 0;
  }
`;

const HighlightsItem = styled.div`
  margin-bottom: ${({ theme }) => theme.space[4]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 calc(43%);
    margin-bottom: 0;
  }
`;
const VideoEmbed = styled.div`
  display: flex;
  justify-content: center;
  margin: ${({ theme }) => `${theme.space[6]} ${theme.space[2]} 0`};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: none;
  }
`;

const SponsorBarOuter = styled.div`
  background-color: #f2f9fa;
`;

const SponsorBarInner = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    max-width: 1220px;
    margin: auto;
  }
`;

const SponsorList = styled.ul`
  margin: 0;
  padding: ${({ theme }) => theme.space[2]};
  list-style-type: none;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    justify-content: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
`;

const SponsorItem = styled.li`
  margin-bottom: ${({ theme }) => theme.space[3]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 27%;
    margin-bottom: 0;
    padding: 0 5rem;
    text-align: right;

    :not(:first-child) {
      border-left: 2px solid #a5a5a5;
      text-align: left;
    }
  }
`;

const SponsorBox = styled.div`
  text-align: center;
  display: inline-block;
`;

const SponsorHeading = styled.h3`
  margin: 0 0 ${({ theme }) => theme.space[3]};
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.fonts.baseSemiBold};
  color: ${({ theme }) => theme.colors.black};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-bottom: 0.5rem;
  }
`;

const SponsorWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    flex-direction: row;
    gap: ${({ theme }) => theme.space[8]};
  }
`;

const StyledAltiboxLogo = styled(AltiboxLogo)`
  ${size(37, 84)}

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    ${size(55, 133)}
  }
`;

const StyledNTLogo = styled(NTLogo)`
  ${size(37, 140)}

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    ${size(35, 210)}
  }
`;

const StyledObosLogo = styled(ObosLogo)`
  ${size(37, 84)}

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    ${size(55, 133)}
  }
`;

const PresentedList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  list-style-type: none;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-top: 2rem;
  }
`;

const PresentedItem = styled.li`
  padding: 0;
  line-height: 1;

  :not(:last-child) {
    padding-right: ${({ theme }) => theme.space[2]};
  }
`;

const StyledTv2Logo = styled(Tv2Logo)`
  ${size(32, 41)}

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${size(40, 53)}
    margin-top: -5px;
  }
`;

interface IPropsFromState {
  now: IEvent | null;
  player: IPlayer | null;
}

interface IProvider {
  link: string;
  url: string;
  title: string;
}
interface IHighlights {
  url: string;
}

type Props = RouteComponentProps & IPropsFromState & WithTranslation;

class Home extends React.Component<Props> {
  public componentDidMount() {
    this.TrackFacebook();
  }

  public TrackFacebook() {
    const fbq = (window as any).fbq;
    if (fbq) {
      fbq("trackCustom", "FPLLanding");
    }
  }

  public render() {
    const { t, now, player } = this.props;

    const providers: IProvider[] = [
      {
        url: "https://www.tv2.no/rest/cms-feeds-dw-rest/v2/cms/article/sport/fotball/fantasy",
        link: "https://www.tv2.no/sport/fotball/eliteserien",
        title: i18n.t("feed.providers.title1", "Latest Fantasy-news from TV 2"),
      },
      {
        url: "https://www.eliteserien.no/rss-nyheter",
        link: "https://www.eliteserien.no/nyheter",
        title: i18n.t("feed.providers.title2", "Latest news from Eliteserien"),
      },
    ];
    let hpHighlightsUrl =
      "https://tv2news.content-discovery.cf.eu-north-1-preprod.vmnd.tv/api/v1/assets?platform=web&page%5bsize%5d=10&page%5bnumber%5d=1&sort=-event-occurrence-time&query=%28%28video-type%3aSportAutomatic%29%20and%20%28tv2-tournament-id%3a%2256f79b03-9608-4b12-9903-2ebaa1a07922%22%20or%20tv2-tournament-id%3a%22e53e02b8-a512-51d3-aa98-ceda8e3a01b1%22%29%20and%20%28type-of-action%3agoal%20or%20type-of-action%3aredcard%20or%20type-of-action%3ahighlight%29%29";
    const production_url =
      "https://tv2news.content-discovery.cf.eu-north-1-prod.vmnd.tv/api/v1/assets?platform=web&page%5bsize%5d=10&page%5bnumber%5d=1&sort=-event-occurrence-time&query=%28%28video-type%3aSportAutomatic%29%20and%20%28tv2-tournament-id%3a%2256f79b03-9608-4b12-9903-2ebaa1a07922%22%20or%20tv2-tournament-id%3a%22e53e02b8-a512-51d3-aa98-ceda8e3a01b1%22%29%20and%20%28type-of-action%3agoal%20or%20type-of-action%3aredcard%20or%20type-of-action%3ahighlight%29%29";
    if (process.env.REACT_APP_SENTRY_ENVIRONMENT === "production") {
      hpHighlightsUrl = production_url;
    }

    const highlights: IHighlights[] = [
      {
        url: hpHighlightsUrl,
      },
    ];

    // Show the status page if have a team and the game has started
    if (now && player && player.entry) {
      return <Status />;
    }

    return (
      <>
        <Wrapper>
          <Main isWide={true}>
            <Login />
            <HomeHero>
              <Tagline>{t("home.title", "How to play Fantasy")}</Tagline>
            </HomeHero>
            <Highlights>
              <HighlightsItem>
                <TriangleCard
                  title={t("home.highlightsLeftTitle", "Choose Your Team")}
                  desc={t(
                    "home.highlightsLeftDesc",
                    "Use the 100mNOK budget to set up your team of 15 players from the Eliteserien"
                  )}
                  lgSrc={step13x}
                  mdSrc={step12x}
                  smSrc={step1}
                />
              </HighlightsItem>
              <HighlightsItem>
                <TriangleCard
                  title={t(
                    "home.highlightsCenterTitle",
                    "Participate in Leagues"
                  )}
                  desc={t(
                    "home.highlightsCenterDesc",
                    "Compete against friends, family and colleagues in your own private leagues"
                  )}
                  lgSrc={step23x}
                  mdSrc={step22x}
                  smSrc={step2}
                />
              </HighlightsItem>
            </Highlights>
            <Highlights>
              <HighlightsItem>
                <TriangleCard
                  title={t("home.highlightsBottomLeftTitle", "Win cool prizes")}
                  desc={t(
                    "home.highlightsLeftBottomDesc",
                    "Fight for exclusive VIP tickets"
                  )}
                  lgSrc={step33x}
                  mdSrc={step32x}
                  smSrc={step3}
                />
              </HighlightsItem>
              <HighlightsItem>
                <TriangleCard
                  title={t(
                    "home.highlightsBottomCenterTitle",
                    "Team up with the Scout"
                  )}
                  desc={t(
                    "home.highlightsCenterBottomDesc",
                    "Our fantasy expert provides you with the best hints and tricks"
                  )}
                  lgSrc={step43x}
                  mdSrc={step42x}
                  smSrc={step4}
                />
              </HighlightsItem>
            </Highlights>
            <VideoEmbed>
              <iframe
                width="560"
                height="315"
                src="https://youtube.com/embed/cLracM095a0?feature=shared"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </VideoEmbed>
            <VideoEmbed>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/ioBwHygiLIg?si=crHSUC54UKEGaBF6"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </VideoEmbed>
            {providers.map((provider: IProvider) => (
              <ScoutProvider
                key={provider.url}
                link={provider.link}
                url={provider.url}
                title={provider.title}
                numberOfArticles={4}
              />
            ))}
            {highlights.map((highlight: IHighlights) => (
              <HighlightsProvider
                key={highlight.url}
                url={highlight.url}
                numberOfHighlights={4}
              />
            ))}
          </Main>
        </Wrapper>
        <SponsorBarOuter>
          <SponsorBarInner>
            <SponsorList>
              <SponsorItem>
                <SponsorBox>
                  <SponsorHeading>
                    {t("home.mainPartner", "Main partner")}
                  </SponsorHeading>
                  <SponsorWrap>
                    <a
                      href="https://www.altibox.no/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <StyledAltiboxLogo />
                    </a>
                    <a
                      href="https://www.norsk-tipping.no/selskapet"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <StyledNTLogo />
                    </a>
                    <a
                      href="https://nye.obos.no/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <StyledObosLogo />
                    </a>
                  </SponsorWrap>
                </SponsorBox>
              </SponsorItem>
              <SponsorItem>
                <SponsorBox>
                  <SponsorHeading>
                    {t("home.poweredBy", "Powered by")}
                  </SponsorHeading>
                  <PresentedList>
                    <PresentedItem>
                      <a
                        href="https://www.tv2.no/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <StyledTv2Logo />
                      </a>
                    </PresentedItem>
                  </PresentedList>
                </SponsorBox>
              </SponsorItem>
            </SponsorList>
          </SponsorBarInner>
        </SponsorBarOuter>
      </>
    );
  }
}

export { Home as HomeTest };

const mapStateToProps = (state: RootState): IPropsFromState => ({
  now: getCurrentEvent(state),
  player: getPlayerData(state),
});

export default withTranslation()(connect(mapStateToProps)(Home));
