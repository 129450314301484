import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { RootState } from "../core/store";
import { IGroupFixture } from "../core/store/fixtures/types";
import { getTeamsById } from "../core/store/teams/reducers";
import { ITeamsById } from "../core/store/teams/types";
import PlayButton from "../img/icons/play-button.svg";
import Badge from "./Badge";
import { ControlArrowRight } from "./icons/Arrows";
import Tv from "./Tv";
import { hideVisually } from "polished";
import { formatRawAsISO } from "../core/utils/datetime";
import { formatInTimeZone } from "date-fns-tz";

const StyledFixture = styled.div`
  overflow: auto;
  border-radius: ${(props) => props.theme.radii[0]};
  background: ${({ theme }) => theme.colors.bgWhiteOpacity85};
  color: ${(props) => props.theme.colors.black};

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    position: relative;
  }
`;

const Teams = styled.div`
  display: flex;
  margin: 0.7rem 0;
  align-items: center;
`;

const FixtureTV = styled.div`
  flex: 0 0 15%;
  padding-right: ${(props) => props.theme.space[1]};
  padding-left: ${(props) => props.theme.space[1]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex-basis: 11%;
    padding-right: ${(props) => props.theme.space[2]};
    padding-left: ${(props) => props.theme.space[2]};
  }
`;

const Team = styled.div`
  flex: 0 0 calc(38% - (6.6rem / 2));
  display: flex;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex-basis: calc(37% - (6.6rem / 2));
  }
`;

const Tickets = styled.div`
  flex: 0 0 9%;
  margin-left: auto;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex-basis: 14%;
  }
`;

const TeamH = styled(Team)`
  justify-content: flex-end;
`;

const TeamA = styled(Team)`
  justify-content: flex-start;
`;

const TeamName = styled.abbr`
  vertical-align: middle;
  font-family: ${({ theme }) => theme.fonts.impact};
  font-size: ${(props) => props.theme.fontSizes[3]};
  text-transform: uppercase;
  text-decoration: none;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    &:after {
      content: attr(title);
    }
    span {
      display: none;
    }
  }
`;

const FixtureBadge = styled.div`
  margin: 0 ${(props) => props.theme.space[2]};
`;

const FixtureKOTime = styled.time`
  flex: 0 0 6.6rem;
  display: inline-block;
  padding: ${({ theme }) => theme.space[2]};
  border-radius: 40px;
  background-color: ${({ theme }) => theme.colors.blueDarker3};
  color: white;
  font-family: ${(props) => props.theme.fonts.impact};
  font-size: ${(props) => props.theme.fontSizes[3]};
  text-align: center;
  line-height: 1.1;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding: 0.8rem 1.6rem;
    font-size: ${({ theme }) => theme.fontSizes[1]};
  }
`;

const FixtureScore = styled.div`
  flex: 0 0 6.6rem;
  display: flex;
  padding: 0.4rem;
  background-color: ${({ theme }) => theme.colors.blackLighter};
`;

const FixtureScoreTeam = styled.span`
  flex: 1;
  padding: 0.3rem 0;
  color: white;
  font-size: 1.8rem;
  line-height: 1;
  text-align: center;

  :first-child {
    border-right: 1px solid rgba(255, 255, 255, 0.5);
  }
`;

const TicketsLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: ${(props) => props.theme.space[1]};
  color: ${(props) => props.theme.colors.blue};
  text-decoration: none;
  vertical-align: middle;

  :hover {
    text-decoration: underline;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding: ${(props) => props.theme.space[2]};
  }
`;

const TicketsText = styled.span`
  font-size: ${({ theme }) => theme.fontSizes[3]};
  font-weight: bold;

  @media (max-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${hideVisually}
  }
`;

const VideoLinkUrl = styled.a`
  display: inline-flex;
  align-items: center;
  align-items: right;
  font-weight: 600;
  padding: 0.4rem;
  color: ${({ theme }) => theme.colors.blue};
  text-decoration: none;
  font-size: ${({ theme }) => theme.fontSizes[3]};
  :hover {
    text-decoration: underline;
  }
`;

const NoTicketsUrl = styled.div`
  display: flex;
`;

const HighlightsIconWrapper = styled.span`
  display: inline-block;
  line-height: 1;
  margin-right: 5px;
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-right: 15px;
    margin-left: 50px;
  }
`;
const HighlightsIcon = styled.img`
  width: 20px;
  height: 20px;
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 28px;
    height: 28px;
  }
`;

interface IPropsFromState {
  teamsById: ITeamsById;
}

interface IOwnProps {
  fixture: IGroupFixture;
}

type Props = IPropsFromState & IOwnProps;

const Fixture: React.FC<Props> = ({ fixture, teamsById }) => {
  const { t } = useTranslation();
  return (
    <StyledFixture>
      <Teams>
        <FixtureTV>
          {fixture.tv_channel && (
            <a
              href="https://bit.ly/TV2Fantasy23"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Tv
                channel={fixture.tv_channel}
                sizes="(min-width: 610px) 60px, 40px"
              />
            </a>
          )}
        </FixtureTV>

        <TeamH>
          <TeamName title={teamsById[fixture.team_h].name}>
            <span>{teamsById[fixture.team_h].short_name}</span>
          </TeamName>
          <FixtureBadge>
            <Badge
              team={teamsById[fixture.team_h]}
              isPresentation={true}
              sizes="(min-width: 1024px) 40px, 30px"
            />
          </FixtureBadge>
        </TeamH>
        {fixture.started && fixture.team_h_score !== null ? (
          <FixtureScore>
            <FixtureScoreTeam>{fixture.team_h_score}</FixtureScoreTeam>
            <FixtureScoreTeam>{fixture.team_a_score}</FixtureScoreTeam>
          </FixtureScore>
        ) : fixture.kickoff_time ? (
          <FixtureKOTime dateTime={formatRawAsISO(fixture.kickoff_time)}>
            {formatInTimeZone(
              fixture.kickoffDate,
              Intl.DateTimeFormat().resolvedOptions().timeZone,
              "HH:mm"
            )}
          </FixtureKOTime>
        ) : (
          <FixtureKOTime as="span">-</FixtureKOTime>
        )}
        <TeamA>
          <FixtureBadge>
            <Badge
              team={teamsById[fixture.team_a]}
              isPresentation={true}
              sizes="(min-width: 1024px) 40px, 30px"
            />
          </FixtureBadge>
          <TeamName title={teamsById[fixture.team_a].name}>
            <span>{teamsById[fixture.team_a].short_name}</span>
          </TeamName>
        </TeamA>
        {fixture.started && fixture.forzify_id ? (
          <VideoLinkUrl
            href={
              "https://highlights.eliteserien.no/game/" + fixture.forzify_id
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <HighlightsIconWrapper>
              <HighlightsIcon
                src={PlayButton}
                alt={t("fixtures.highlights", "Highlights")}
              />
            </HighlightsIconWrapper>
          </VideoLinkUrl>
        ) : fixture.tickets_url ? (
          <Tickets>
            <TicketsLink
              href={fixture.tickets_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <TicketsText>{t("fixtures.tickets", "Tickets")}</TicketsText>
              <ControlArrowRight />
            </TicketsLink>
          </Tickets>
        ) : (
          <NoTicketsUrl />
        )}
      </Teams>
    </StyledFixture>
  );
};

const mapStateToProps = (state: RootState): IPropsFromState => ({
  teamsById: getTeamsById(state),
});

export default connect(mapStateToProps)(Fixture);
