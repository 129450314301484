import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState } from "../../core/store";
import { getCurrentEvent } from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import { getPlayerData } from "../../core/store/player/reducers";
import { IPlayer } from "../../core/store/player/types";
import Title from "../Title";
import EventInfo from "./EventInfo";
import EventStatusBar from "./EventStatusBar";
import PlayerAvailability from "./PlayerAvailability";
import StatusDreamTeam from "./StatusDreamTeam";
import TransfersPanel from "./TransfersPanel";
import HighlightsProvider from "../highlights/HighlightsProvider";

const StatusWrapper = styled.div`
  position: relative;
  z-index: 3;
  max-width: 1220px;
  margin: 0 auto;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding-right: ${({ theme }) => theme.space[2]};
    padding-left: ${({ theme }) => theme.space[2]};
  }
`;

const StatusCols = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    justify-content: space-between;
  }
`;

const StatusMain = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 66.66%;
  }
`;

const StatusSecondary = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 30%;
  }
`;

interface IPropsFromState {
  now: IEvent | null;
  player: IPlayer | null;
}
interface IHighlights {
  url: string;
}
let hpHighlightsUrl =
  "https://tv2news.content-discovery.cf.eu-north-1-preprod.vmnd.tv/api/v1/assets?platform=web&page%5bsize%5d=10&page%5bnumber%5d=1&sort=-event-occurrence-time&query=%28%28video-type%3aSportAutomatic%29%20and%20%28tv2-tournament-id%3a%2256f79b03-9608-4b12-9903-2ebaa1a07922%22%20or%20tv2-tournament-id%3a%22e53e02b8-a512-51d3-aa98-ceda8e3a01b1%22%29%20and%20%28type-of-action%3agoal%20or%20type-of-action%3aredcard%20or%20type-of-action%3ahighlight%29%29";
const production_url =
  "https://tv2news.content-discovery.cf.eu-north-1-prod.vmnd.tv/api/v1/assets?platform=web&page%5bsize%5d=10&page%5bnumber%5d=1&sort=-event-occurrence-time&query=%28%28video-type%3aSportAutomatic%29%20and%20%28tv2-tournament-id%3a%2256f79b03-9608-4b12-9903-2ebaa1a07922%22%20or%20tv2-tournament-id%3a%22e53e02b8-a512-51d3-aa98-ceda8e3a01b1%22%29%20and%20%28type-of-action%3agoal%20or%20type-of-action%3aredcard%20or%20type-of-action%3ahighlight%29%29";
if (process.env.REACT_APP_SENTRY_ENVIRONMENT === "production") {
  hpHighlightsUrl = production_url;
}

const highlights: IHighlights[] = [
  {
    url: hpHighlightsUrl,
  },
];

const Status: React.FC<IPropsFromState> = ({ now, player }) => {
  const { t } = useTranslation();

  if (!now || !player) {
    return null;
  }
  return (
    <StatusWrapper>
      <Box mt={4} mb={2} px={2}>
        <p>
          {t("status.loggedIn", "You are logged in as {{ name }}", {
            name: `${player.first_name} ${player.last_name}`,
          })}
        </p>
        <Title>{`${now.name} Status`}</Title>
      </Box>
      <Box mb={4}>
        <EventStatusBar />
      </Box>
      <Box mb={4}>
        <EventInfo />
      </Box>
      <Box mb={4}>
        <StatusDreamTeam />
      </Box>

      <StatusCols>
        <StatusMain>
          <Box mb={4}>
            <TransfersPanel />
          </Box>
          <Box mb={4}>
            <TransfersPanel isOut={true} />
          </Box>
        </StatusMain>
        <StatusSecondary>
          <Box mb={4}>
            <PlayerAvailability />
          </Box>
        </StatusSecondary>
      </StatusCols>
      {highlights.map((highlight: IHighlights) => (
        <HighlightsProvider
          key={highlight.url}
          url={highlight.url}
          numberOfHighlights={4}
        />
      ))}
    </StatusWrapper>
  );
};

const mapStateToProps = (state: RootState): IPropsFromState => ({
  now: getCurrentEvent(state),
  player: getPlayerData(state),
});

export default connect(mapStateToProps)(Status);
