import styled, { css } from "styled-components";

// Shared components in Scout and Highlights provider
export const ProviderLink = styled.a`
  color: #000;
  text-decoration: none;
`;

export const ProviderArticleImg = styled.img<{
  aspectRatio?: boolean | "default";
}>`
  width: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: ${({ theme }) => theme.radii[1]};
  ${({ aspectRatio = true }) =>
    aspectRatio &&
    css`
      aspect-ratio: 14/9;
    `};
`;

const ProviderContentStyles = css`
  flex-wrap: nowrap;
  flex-direction: row;
`;

export const ProviderContent = styled.div<{
  backgroundColor: string;
  wide?: boolean | "default";
}>`
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.radii[1]};
  background-color: ${(props) => props.backgroundColor};

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    ${ProviderContentStyles}
  }

  ${({ wide = false }) =>
    wide &&
    css`
      @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
        ${ProviderContentStyles}
      }
    `};
`;

export const ProviderSection = styled.section`
  margin-bottom: ${({ theme }) => theme.space[4]};
  padding: ${({ theme }) => theme.space[2]};
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    padding: ${({ theme }) => theme.space[0]};
  }
`;
