import styled, { css } from "styled-components/macro";

interface IProps {
  mine: boolean;
}

const StandingsRow = styled.tr<IProps>`
  ${(props) =>
    props.mine &&
    css`
      tr {
        background: ${({ theme }) => theme.colors.tableBlueLight};

        &:nth-child(odd) {
          background: ${({ theme }) => theme.colors.tableBlueDark};
        }
        color: ${({ theme }) => theme.colors.black};
        > a {
          color: ${({ theme }) => theme.colors.red};
        }
      }
    `}
`;
export default StandingsRow;
