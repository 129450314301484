import { size } from "polished";
import * as React from "react";
import { connect } from "react-redux";
import styled, { css } from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import { getElementsById } from "../../core/store/elements/reducers";
import { showElementSummary } from "../../core/store/elements/thunks";
import { IElementsById } from "../../core/store/elements/types";
import { IPickLight } from "../../core/store/entries/types";
import { ReactComponent as Captain } from "../../img/icons/captain.svg";
import { ReactComponent as Sub } from "../../img/icons/sub.svg";
import { ReactComponent as ViceCaptain } from "../../img/icons/vice-captain.svg";
import { ReactComponent as x2 } from "../../img/icons/x2.svg";
import ElementDialogButton from "../ElementDialogButton";
import Shirt from "../Shirt";
import {
  Controls,
  InfoControl,
  PitchElementWrap,
  PrimaryControl,
  StyledPitchElement,
} from "../Pitch";
import PitchElementData from "../PitchElementData";
import { UnstyledButton } from "../Utils";
import { getSettings } from "../../core/store/game/reducers";
import { ISettings } from "../../core/store/game/types";

const StyledSub = styled(Sub)`
  ${size(14)}

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    ${size(16)}
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${size(20)}
  }
`;

const StyledButton = styled(UnstyledButton)`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    width: 100%;
    padding: 0;
    border: 0;
    background-color: transparent;
    line-height: 1;
    cursor: pointer;
    height: auto;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
    justify-content: start;
  }
`;

const StyledCaptain = styled(Captain)<IStyledCaptainProps>`
  ${size(14)};
  position: absolute;
  top: 22%;
  right: 4px;
  color: white;

  ${(props) =>
    props.variant === "2capt" &&
    css`
      color: black;
      fill: #fac101;
    `}

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    ${size(16)}
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${size(20)}
  }
`;

const StyledViceCaptain = styled(ViceCaptain)<IStyledCaptainProps>`
  ${size(14)};
  position: absolute;
  top: 22%;
  right: 4px;
  color: white;

  ${(props) =>
    props.variant === "2capt" &&
    css`
      color: black;
      fill: #fac101;
    `}

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    ${size(16)}
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${size(20)}
  }
`;

const StyledX2 = styled(x2)`
  ${size(14)};
  position: absolute;
  top: 22%;
  right: 4px;
  color: #fac101;
  fill: #28aae1;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    ${size(16)}
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${size(20)}
  }
`;

const DreamTeamWrap = styled.div`
  position: absolute;
  top: 44%;
  right: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    right: 10px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    right: 20px;
  }
`;

interface IPropsFromState {
  elementsById: IElementsById;
  settings: ISettings | null;
}

interface IPropsFromDispatch {
  showElementDialog: (elementId: number) => void;
}

interface IOwnProps {
  actionMe: () => void;
  chipName: string | null;
  pick: IPickLight;
  renderDreamTeam: (pick: IPickLight) => React.ReactNode;
  renderElementMenu: () => void;
  renderPickValue: (pick: IPickLight) => React.ReactNode;
}

interface IStyledCaptainProps {
  variant?: string;
}

type Props = IPropsFromState & IPropsFromDispatch & IOwnProps;

const TeamPitchElement: React.FC<Props> = ({
  actionMe,
  chipName,
  pick,
  elementsById,
  renderElementMenu,
  renderPickValue,
  renderDreamTeam,
  settings,
}) => {
  if (!settings) return null;

  const element = elementsById[pick.element];

  return (
    <PitchElementWrap variant={pick.subStatus || ""}>
      <StyledPitchElement>
        <StyledButton type="button" onClick={renderElementMenu}>
          <Shirt elementId={element.id} />
          <PitchElementData element={element} value={renderPickValue(pick)} />
        </StyledButton>
        <Controls>
          {actionMe && typeof pick.subStatus !== "undefined" && (
            <PrimaryControl>
              <UnstyledButton type="button" onClick={actionMe}>
                <StyledSub />
              </UnstyledButton>
            </PrimaryControl>
          )}
          <InfoControl>
            <ElementDialogButton elementId={pick.element} />
          </InfoControl>
        </Controls>

        {pick.is_captain &&
          (!chipName || chipName === "rich" || chipName === "wildcard") && (
            <StyledCaptain />
          )}

        {pick.is_vice_captain &&
          (!chipName || chipName === "rich" || chipName === "wildcard") && (
            <StyledViceCaptain />
          )}

        {pick.is_captain && chipName === "2capt" && (
          <StyledCaptain variant="2capt" />
        )}

        {pick.is_vice_captain && chipName === "2capt" && (
          <StyledViceCaptain variant="2capt" />
        )}

        {chipName === "frush" &&
          element.element_type === 4 &&
          pick.position <= settings.squad_squadplay && <StyledX2 />}

        <DreamTeamWrap>{renderDreamTeam(pick)}</DreamTeamWrap>
      </StyledPitchElement>
    </PitchElementWrap>
  );
};

const mapStateToProps = (state: RootState): IPropsFromState => ({
  elementsById: getElementsById(state),
  settings: getSettings(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  showElementDialog: (elementId) => dispatch(showElementSummary(elementId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamPitchElement);
