import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import LogoBar from "../LogoBar";
import SubHeading from "../SubHeading";
import { ControlArrowRight } from "../icons/Arrows";

interface IProps {
  title: string;
  link?: string;
  showMoreLink?: boolean;
}

const StyledMoreLink = styled.a`
  display: inline-flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes[1]};
  color: ${({ theme }) => theme.colors.red};
  text-decoration: none;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledProviderHeader = styled.header`
  margin-bottom: ${({ theme }) => theme.space[2]};
`;

const StyledLogoBar = styled(LogoBar)`
  border: none;
  justify-content: space-between;
  > :first-child {
    display: none;
  }
`;

const ProviderHeader: React.FC<IProps> = ({
  title,
  link,
  showMoreLink = true,
}) => {
  const { t } = useTranslation();
  return (
    <StyledProviderHeader>
      <StyledLogoBar justifyContent="left">
        <SubHeading variant="dark">{title}</SubHeading>
        {showMoreLink && (
          <StyledMoreLink href={link} target="_blank" rel="noopener noreferrer">
            {t("scout.more", "More")}
            <ControlArrowRight />
          </StyledMoreLink>
        )}
      </StyledLogoBar>
    </StyledProviderHeader>
  );
};

export default ProviderHeader;
