import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import {
  cancelProposedChip,
  proposeAvailableChip,
} from "../../core/store/chips/actions";
import {
  getActiveChipName,
  getAvailableChipNames,
  getProposedChipName,
} from "../../core/store/chips/reducers";
import { getElementsById } from "../../core/store/elements/reducers";
import { IElementsById } from "../../core/store/elements/types";
import { getNextEvent } from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import { ITransferState } from "../../core/store/my-team/types";
import {
  getFreeTransfers,
  getProposedTransfers,
  getToSpend,
  getTransferCosts,
  getTransferState,
} from "../../core/store/squad/reducers";
import { makeTransfers } from "../../core/store/squad/thunks";
import { ITransfersData } from "../../core/store/squad/types";
import { formatRawAsLocalI18n } from "../../core/utils/datetime";
import { dateLocales } from "../../i18n";
import Alert, { AlertGroup, AlertItem } from "../Alert";
import Button from "../Button";
import Dialog, { DialogButtonItem } from "../Dialog";
import Table from "../Table";

interface IOwnProps {
  handleHide: () => void;
}

const DialogHeading = styled.div`
  padding: ${({ theme }) => theme.space[2]};
  background-color: ${({ theme }) => theme.colors.blueDarker2};
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.impact};
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 600;
  line-height: 1;
`;

interface IPropsFromState {
  activeChip: string;
  elementsById: IElementsById;
  freeTransfers: number;
  nextEvent: IEvent | null;
  proposedChip: string;
  transferCosts: number;
  transfers: ITransfersData[];
  transferState: ITransferState | null;
  wildcardAvailable: boolean;
  richUncleAvaliable: boolean;
  toSpend: number;
}

interface IPropsFromDispatch {
  makeTransfers: () => void;
  cancelChip: (chip: string) => void;
  proposeChip: (chip: string) => void;
}

type Props = IOwnProps & IPropsFromState & IPropsFromDispatch;

const ConfirmTransfersDialog = ({
  freeTransfers,
  handleHide,
  elementsById,
  makeTransfers,
  nextEvent,
  proposedChip,
  transferCosts,
  transfers,
  transferState,
  wildcardAvailable,
  richUncleAvaliable,
  cancelChip,
  proposeChip,
  toSpend,
}: Props) => {
  const { i18n, t } = useTranslation();
  if (!nextEvent || !transferState) {
    return null;
  }

  return (
    <Dialog closeDialog={handleHide}>
      <Dialog.Header closeDialog={handleHide}>
        <DialogHeading>
          {t("confirmTransfers.heading", "Confirm Transfers")}
        </DialogHeading>
      </Dialog.Header>
      <Dialog.Body isPadded={true}>
        <Box mb={4}>
          <Table>
            <thead>
              <tr>
                <th>{t("confirmTransfers.out", "Out")}</th>
                <th>{t("confirmTransfers.in", "In")}</th>
                <th>{t("confirmTransfers.cost", "Cost")}</th>
              </tr>
            </thead>
            <tbody>
              {transfers.map((t, index) => (
                <tr key={t.element_out}>
                  {/*
                   * Non-null assertion used here because elements
                   * are always transferred out in this game.
                   */}
                  <td>{elementsById[t.element_out!].web_name}</td>
                  <td>{elementsById[t.element_in].web_name}</td>
                  <td>
                    {transferCosts && index >= freeTransfers
                      ? transferState.cost
                      : 0}{" "}
                    pts
                  </td>
                </tr>
              ))}
              <tr>
                <td />
                <td>{t("confirmTransfers.totalCost", "Total cost")}</td>
                <td>{transferCosts} pts</td>
              </tr>
            </tbody>
          </Table>
        </Box>
        <span className="notranslate">
          {transferCosts > 0 ? (
            <Alert type="warning">
              <AlertGroup>
                <AlertItem>
                  {t(
                    "confirmTransfers.transferAlert",
                    `
                  These transfers will be active for ${
                    nextEvent.name
                  } (${formatRawAsLocalI18n(
                      nextEvent.deadline_time,
                      dateLocales[i18n.language]
                    )}) and will deduct ${transferCosts}pts from your score!
                  `,
                    {
                      name: nextEvent.name,
                      time: formatRawAsLocalI18n(
                        nextEvent.deadline_time,
                        dateLocales[i18n.language]
                      ),
                      costs: transferCosts,
                    }
                  )}
                </AlertItem>
              </AlertGroup>
              {wildcardAvailable && proposedChip !== "rich" && (
                <AlertGroup>
                  <AlertItem>
                    {t(
                      "confirmTransfers.playYourWildcard",
                      "Play your wildcard to make unlimited free transfers this Round."
                    )}
                  </AlertItem>
                  <Button onClick={() => proposeChip("wildcard")} width={1}>
                    {t("confirmTransfers.playWildcard", `Play Wildcard`)}
                  </Button>
                </AlertGroup>
              )}
            </Alert>
          ) : (
            <Alert type="warning">
              <AlertGroup>
                <AlertItem>
                  {t(
                    "confirmTransfers.theseTransfersWill",
                    `These transfers will be active for ${
                      nextEvent.name
                    } (${formatRawAsLocalI18n(
                      nextEvent.deadline_time,
                      dateLocales[i18n.language]
                    )})`,
                    {
                      name: nextEvent.name,
                      time: formatRawAsLocalI18n(
                        nextEvent.deadline_time,
                        dateLocales[i18n.language]
                      ),
                    }
                  )}
                </AlertItem>
              </AlertGroup>
              {proposedChip === "wildcard" && (
                <AlertGroup>
                  <AlertItem>
                    <strong>
                      {t(
                        "confirmTransfers.youAreProposingWildcard",
                        "You are proposing to play your wildcard!"
                      )}
                    </strong>
                  </AlertItem>
                  <AlertItem>
                    {t(
                      "confirmTransfers.cancelYourWildcard",
                      "Cancel your wildcard if you would prefer to spend points on these transfers."
                    )}
                  </AlertItem>
                  <Button onClick={() => cancelChip("wildcard")} width={1}>
                    {t("confirmTransfers.cancelWildcard", "Cancel Wildcard")}
                  </Button>
                </AlertGroup>
              )}
              {proposedChip === "rich" && (
                <AlertGroup>
                  <AlertItem>
                    <b>
                      {t(
                        "confirmTransfers.proposingRichUncle",
                        "You are proposing to play your Rich Uncle chip! This allows you to make unlimited free transfers with an unlimited budget for a single round. At the next deadline, your squad is returned to how it was at the start of the round."
                      )}
                    </b>
                  </AlertItem>
                  <AlertItem>
                    {t(
                      "confirmTransfers.cancelYourRichUncle",
                      "Cancel Rich Uncle chip if you would prefer not to do this."
                    )}
                  </AlertItem>
                  <Button
                    onClick={() => {
                      cancelChip("rich");
                      return handleHide();
                    }}
                    width={1}
                  >
                    {t("confirmTransfers.cancelRichUncle", `Cancel Rich Uncle`)}
                  </Button>
                </AlertGroup>
              )}
            </Alert>
          )}
          <Box mt={4}>
            <div>
              <DialogButtonItem>
                <Button onClick={makeTransfers} width={1}>
                  {t("confirmTransfers.confirmTransfers", "Confirm Transfers")}

                  {transferCosts > 0 && !proposedChip && (
                    <>
                      <br />
                      (-{transferCosts}pts)
                    </>
                  )}
                  {proposedChip === "wildcard" && (
                    <>
                      <br />(
                      {t(
                        "confirmTransfers.andPlayWildcard",
                        "and play Wildcard"
                      )}
                      )
                    </>
                  )}
                  {proposedChip === "rich" && (
                    <>
                      <br />(
                      {t(
                        "confirmTransfers.andPlayRichUncle",
                        "and play Rich Uncle"
                      )}
                      )
                    </>
                  )}
                </Button>
              </DialogButtonItem>
              <DialogButtonItem>
                <Button variant="secondary" onClick={handleHide} width={1}>
                  {t("confirmTransfers.changeTransfers", "Change Transfers")}
                </Button>
              </DialogButtonItem>
            </div>
          </Box>
        </span>
      </Dialog.Body>
    </Dialog>
  );
};

export { ConfirmTransfersDialog as ConfirmTransfersDialogTest };

const mapStateToProps = (state: RootState): IPropsFromState => {
  const availableChips = getAvailableChipNames(state);

  return {
    toSpend: getToSpend(state),
    activeChip: getActiveChipName(state),
    elementsById: getElementsById(state),
    freeTransfers: getFreeTransfers(state),
    nextEvent: getNextEvent(state),
    proposedChip: getProposedChipName(state),
    transferCosts: getTransferCosts(state),
    transferState: getTransferState(state),
    transfers: getProposedTransfers(state),
    wildcardAvailable: availableChips.indexOf("wildcard") > -1,
    richUncleAvaliable: availableChips.indexOf("rich") > -1,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  makeTransfers: () => dispatch(makeTransfers()),
  cancelChip: (chip: string) => dispatch(cancelProposedChip(chip)),
  proposeChip: (chip: string) => dispatch(proposeAvailableChip(chip)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmTransfersDialog);
