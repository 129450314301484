import styled from "styled-components";
import PlaceholderShirt from "../../img/headshots/shirt_placeholdershort.png";

const StyledSquadPlaceholder = styled.img`
  display: block;
  margin: auto;
  max-width: 100px;
  width: 100%;
  height: auto;
`;

const SquadPlaceholder = () => {
  return (
    <StyledSquadPlaceholder
      src={`${PlaceholderShirt}`}
      alt=""
      role="presentation"
    />
  );
};

export default SquadPlaceholder;
