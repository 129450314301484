import React from "react";
import styled, { css } from "styled-components/macro";

const TabHeadingOuter = styled.div<ITabHeadingOuter>`
  padding-left: ${({ theme }) => theme.space[2]};
  ${(props) =>
    props.isCentered &&
    css`
      text-align: center;
    `}
  ${(props) =>
    props.hasBorder &&
    css`
      border-top: 1px solid ${({ theme }) => theme.colors.white};
    `}
`;

const TabHeadingInner = styled.div<ITabHeadingInner>`
  display: inline-block;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    ${(props) =>
      props.isBlock &&
      css`
        display: block;
      `}
  }
`;

const TabHeadingTitle = styled.h3`
  font-size: ${({ theme }) => theme.fontSizes[1]};
`;

interface IOwnProps {
  title: React.ReactNode;
}

interface ITabHeadingOuter {
  hasBorder?: boolean;
  isCentered?: boolean;
}

interface ITabHeadingInner {
  isBlock?: boolean;
}

type Props = IOwnProps & ITabHeadingOuter & ITabHeadingInner;

const TabHeading: React.FC<Props> = ({
  hasBorder = false,
  isBlock = false,
  isCentered = false,
  title,
}) => (
  <TabHeadingOuter isCentered={isCentered} hasBorder={hasBorder}>
    <TabHeadingInner isBlock={isBlock}>
      <TabHeadingTitle>{title}</TabHeadingTitle>
    </TabHeadingInner>
  </TabHeadingOuter>
);

export default TabHeading;
