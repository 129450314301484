import { size } from "polished";
import styled from "styled-components";
import { ReactComponent as GBFlag } from "../img/flags/gb.svg";
import { ReactComponent as NOFlag } from "../img/flags/no.svg";

const FlagsWrap = styled.div`
  width: 81px;
  height: 36px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 32px;
`;

const FlagList = styled.ul`
  padding: 8px 0;
  font-size: 0;
  list-style-type: none;
`;

const FlagItem = styled.li`
  display: inline-block;
  vertical-align: middle;
  padding: 0 ${({ theme }) => theme.space[2]};
  line-height: 1;

  :not(:last-child) {
    border-right: 1px solid #cac5dd;
  }
`;

const FlagLink = styled.a`
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
`;

const StyledNOFlag = styled(NOFlag)`
  ${size(20, 20)}
`;

const StyledGBFlag = styled(GBFlag)`
  ${size(20, 20)}
`;

const LanguageToggle = () => (
  <FlagsWrap>
    <FlagList>
      <FlagItem>
        <FlagLink href="https://fantasy.eliteserien.no">
          <StyledNOFlag />
        </FlagLink>
      </FlagItem>
      <FlagItem>
        <FlagLink href="https://en.fantasy.eliteserien.no">
          <StyledGBFlag />
        </FlagLink>
      </FlagItem>
    </FlagList>
  </FlagsWrap>
);
export default LanguageToggle;
