import React from "react";
import styled from "styled-components/macro";
import Copy from "./Copy";

const StyledCollapsible = styled.div`
  margin-bottom: ${({ theme }) => theme.space[2]};

  > h5 {
    margin-bottom: 0;
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.space[1]};
  padding: ${({ theme }) => theme.space[2]};
  border-radius: ${({ theme }) => theme.radii[1]};
  border: 1px solid ${({ theme }) => theme.colors.grey};
  background: ${({ theme }) => theme.colors.bgWhiteOpacity85};
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.base};
  font-size: 1.4rem;
  text-align: left;

  :hover {
    color: black;
    background-color: ${({ theme }) => theme.colors.primary};

    svg {
      fill: white;
    }
  }

  svg {
    fill: ${({ theme }) => theme.colors.black};
  }

  &[aria-expanded="true"] {
    svg {
      transform: rotate(180deg);
    }
  }
`;

const CollapsibleContent = styled.div`
  padding: ${({ theme }) => theme.space[2]};
  background: ${({ theme }) => theme.colors.bgWhiteOpacity50};

  &[aria-hidden] {
    display: none;
  }

  &[aria-hidden="false"] {
    display: block;
  }
`;

// Used to generate unique ids for collapsible content / aria controls
let lastId = 0;
const newId = (prefix = "ism-id") => `${prefix}${++lastId}`;

interface IProps {
  headingText: string;
}

interface IState {
  open: boolean;
}

class Collapsible extends React.Component<IProps, IState> {
  public state = { open: false };
  public id = "";
  public toggle = () => this.setState({ open: !this.state.open });

  componentDidMount() {
    this.id = newId("ism-collapsible-");
  }

  render() {
    const { children, headingText } = this.props;
    return (
      <StyledCollapsible>
        <h5>
          <Button
            type="button"
            onClick={this.toggle}
            aria-expanded={this.state.open}
            aria-controls={this.id}
          >
            {headingText}
          </Button>
        </h5>
        <CollapsibleContent id={this.id} aria-hidden={!this.state.open}>
          <Copy>{children}</Copy>
        </CollapsibleContent>
      </StyledCollapsible>
    );
  }
}

export default Collapsible;
