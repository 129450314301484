import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box, Flex } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import {
  cancelProposedChip,
  proposeAvailableChip,
} from "../../core/store/chips/actions";
import {
  getActiveChipName,
  getAvailableChipNames,
  getPotentialChips,
  getProposedChipName,
} from "../../core/store/chips/reducers";
import { IPotentialChip } from "../../core/store/chips/types";
import { getElementTypesBySquadPosition } from "../../core/store/element-types/reducers";
import { IElementTypesBySquadPosition } from "../../core/store/element-types/types";
import {
  canAutocomplete,
  canReset,
  getErrors,
  getFreeTransfersRemaining,
  getProposedElements,
  getToSpend,
  getTransferCosts,
} from "../../core/store/squad/reducers";
import { autoComplete, reset } from "../../core/store/squad/thunks";
import { IProposedElements, ISquadErrors } from "../../core/store/squad/types";
import { integerToMoney } from "../../core/utils/money";
import { ChipName, getChipName } from "../../utils/chips";
import Alert from "../Alert";
import Button from "../Button";
import Dialog from "../Dialog";
import DialogManager from "../DialogManager";
import { ScoreboardPanel } from "./ScoreboardPanel";

const StyledBasic = styled.div`
  margin-bottom: ${({ theme }) => theme.space[2]};
  border: 1px solid ${({ theme }) => theme.colors.white};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    border-radius: ${({ theme }) => theme.radii[1]};
  }
`;

const ScoreboardButtons = styled(Flex)`
  flex: 1;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    border-right: 0;
  }
`;

const ScoreboardPanels = styled(Flex)`
  flex: 1;
  border-top: 1px solid ${({ theme }) => theme.colors.white};
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    border-top: 0;
    border-left: 1px solid ${({ theme }) => theme.colors.white};
  }
`;

const PanelWrapper = styled(Box)`
  flex: 1;
  border-left: 1px solid ${({ theme }) => theme.colors.white};

  &:first-of-type {
    border-left: 0;
  }
`;

const ButtonWrap = styled.div`
  flex: 1;
  padding: ${({ theme }) => theme.space[2]};
`;

const CostScoreboardUnit = styled.div`
  flex: 1 0 50%;
  padding: 0 ${({ theme }) => theme.space[2]};

  :nth-child(4) > div {
    border-bottom-width: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    flex: 1;

    :nth-child(3) > div {
      border-bottom-width: 0;
    }

    :nth-child(4) > div {
      border-bottom-width: 1px;
    }
  }
`;

const CostButtonWrap = styled.div`
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

const DialogHeading = styled.div`
  padding: ${({ theme }) => theme.space[2]};
  background-color: ${({ theme }) => theme.colors.blueDarker2};
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.impact};
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 600;
  line-height: 1;
`;

interface IBasicProps {
  autoPick: () => void;
  canAutocomplete: boolean;
  canReset: boolean;
  currencyDivisor: number;
  elementTypesByPosition: IElementTypesBySquadPosition;
  isBudgetExceeded: boolean;
  isNeedElements: boolean;
  proposedElements: IProposedElements;
  reset: () => void;
  toSpend: number;
}

const Basic: React.FC<IBasicProps> = ({
  autoPick,
  canAutocomplete,
  canReset,
  currencyDivisor,
  elementTypesByPosition,
  isBudgetExceeded,
  isNeedElements,
  proposedElements,
  reset,
  toSpend,
}) => {
  const { t } = useTranslation();
  return (
    <StyledBasic>
      <ScoreboardButtons>
        <ButtonWrap>
          <Button disabled={!canAutocomplete} onClick={autoPick} width={1}>
            {t("squad.autoPick", "Auto Pick")}
          </Button>
        </ButtonWrap>
        <ButtonWrap>
          <Button disabled={!canReset} onClick={reset} type="reset" width={1}>
            {t("reset", "Reset")}
          </Button>
        </ButtonWrap>
      </ScoreboardButtons>
      <ScoreboardPanels>
        <PanelWrapper>
          <ScoreboardPanel
            heading={t("squad.playersSelected", "Players Selected")}
            value={`${Object.keys(proposedElements).length} / ${
              Object.keys(elementTypesByPosition).length
            }`}
            isError={isNeedElements}
            isSuccess={!isNeedElements}
          />
        </PanelWrapper>
        <PanelWrapper>
          <ScoreboardPanel
            heading={t("squad.moneyRemaining", "Money Remaining")}
            value={`${integerToMoney(toSpend, currencyDivisor)}`}
            isError={isBudgetExceeded}
            isSuccess={!isBudgetExceeded}
          />
        </PanelWrapper>
      </ScoreboardPanels>
    </StyledBasic>
  );
};

interface IExplainChipDialogProps {
  chip: string;
  handleHide: () => void;
  transferCosts: number;
}

const ExplainChipDialog: React.FC<IExplainChipDialogProps> = ({
  chip,
  handleHide,
  transferCosts,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog closeDialog={handleHide}>
      <Dialog.Header closeDialog={handleHide}>
        <DialogHeading>
          {chip === "wildcard" && t("squad.playWildcardTest", "Play Wildcard")}
          {chip === "rich" && t("squad.playRichUncleText", "Play Rich Uncle")}
        </DialogHeading>
      </Dialog.Header>
      <Dialog.Body isPadded={true}>
        <Alert type="info">
          {transferCosts ? (
            <>
              <p>
                {chip === "wildcard" &&
                  t(
                    "squad.wildcardText1",
                    "You are now proposing to play your wildcard. Unlimited transfers can be made this Round without points deduction."
                  )}
                {chip === "rich" &&
                  t(
                    "squad.richUncleText1",
                    "You are now proposing to play your Rich Uncle chip. Unlimited transfers with an unlimted budget can be made this Round without points deduction."
                  )}
              </p>
              <p>
                {chip === "wildcard" &&
                  t(
                    "squad.wildcardText2",
                    "Your wildcard will not be activated until you confirm your transfers."
                  )}
                {chip === "rich" &&
                  t(
                    "squad.richUncleText2",
                    "Your Rich Uncle will not be activated until you confirm your transfers"
                  )}
              </p>
            </>
          ) : (
            <p>
              {chip === "wildcard" &&
                t(
                  "squad.wildcardText3",
                  "To activate your wildcard you must be making enough proposed transfers that points will be deducted."
                )}
              {chip === "rich" &&
                t(
                  "squad.richUncleText3",
                  "To activate your Rich Uncle chip you must be making enough proposed transfers that points will be deducted."
                )}
            </p>
          )}
        </Alert>
      </Dialog.Body>
    </Dialog>
  );
};

interface ICostProps {
  activeChipName: string;
  availableChipNames: string[];
  autoPick: () => void;
  canAutocomplete: boolean;
  canReset: boolean;
  currencyDivisor: number;
  freeTransfersRemaining: number;
  isBudgetExceeded: boolean;
  proposedChipName: string;
  reset: () => void;
  toSpend: number;
  transferCosts: number;
  cancelChip: (chip: string) => void;
  proposeChip: (chip: string) => void;
  chips: IPotentialChip[];
}

const Cost: React.FC<ICostProps> = ({
  activeChipName,
  availableChipNames,
  autoPick,
  canAutocomplete,
  canReset,
  currencyDivisor,
  freeTransfersRemaining,
  isBudgetExceeded,
  proposedChipName,
  reset,
  toSpend,
  transferCosts,
  cancelChip,
  proposeChip,
  chips,
}) => {
  const { t } = useTranslation();

  const richUncleChip = chips.filter((c) => c.name === "rich")[0];
  const wildCardChip = chips.filter((c) => c.name === "wildcard")[0];

  return (
    <>
      <Flex flexWrap="wrap" alignItems="center" mb={2}>
        <CostScoreboardUnit>
          <CostButtonWrap>
            <Button disabled={!canAutocomplete} onClick={autoPick} width={1}>
              {t("squad.autoPick", "Auto Pick")}
            </Button>
          </CostButtonWrap>
        </CostScoreboardUnit>
        <CostScoreboardUnit>
          <CostButtonWrap>
            <Button disabled={!canReset} onClick={reset} type="reset" width={1}>
              {t("squad.reset", "Reset")}
            </Button>
          </CostButtonWrap>
        </CostScoreboardUnit>

        {activeChipName ? (
          <CostScoreboardUnit>
            <ScoreboardPanel
              heading={t("scoreboard.chip.active", "Active Chip")}
              value={getChipName(activeChipName as ChipName, t)}
            />
          </CostScoreboardUnit>
        ) : wildCardChip.status_for_entry === "played" ? (
          <CostScoreboardUnit>
            <ScoreboardPanel heading="Wildcard" value={"Played"} />
          </CostScoreboardUnit>
        ) : wildCardChip.status_for_entry === "active" ? (
          <CostScoreboardUnit>
            <ScoreboardPanel heading="Wildcard" value={"Active"} />
          </CostScoreboardUnit>
        ) : proposedChipName === "wildcard" ? (
          <CostScoreboardUnit>
            <CostButtonWrap>
              <Button width={1} onClick={() => cancelChip("wildcard")}>
                {t("squad.cancelWildcardText", "Cancel Wildcard")}
              </Button>
            </CostButtonWrap>
          </CostScoreboardUnit>
        ) : (
          <DialogManager
            render={(showDialog, handleShow, handleHide) => (
              <>
                <CostScoreboardUnit>
                  <CostButtonWrap>
                    <Button
                      disabled={
                        proposedChipName === "rich" || activeChipName === "rich"
                      }
                      width={1}
                      onClick={handleShow}
                    >
                      {t("squad.playWildcardTest", "Play Wildcard")}
                    </Button>
                  </CostButtonWrap>
                </CostScoreboardUnit>
                {showDialog && (
                  <ExplainChipDialog
                    chip="wildcard"
                    handleHide={() => {
                      handleHide();
                      if (transferCosts) {
                        proposeChip("wildcard");
                      }
                    }}
                    transferCosts={transferCosts}
                  />
                )}
              </>
            )}
          />
        )}

        {activeChipName ? (
          <>&nbsp;</>
        ) : richUncleChip.status_for_entry === "played" ? (
          <CostScoreboardUnit>
            <ScoreboardPanel heading="Rich Uncle" value={"Played"} />
          </CostScoreboardUnit>
        ) : richUncleChip.status_for_entry === "active" ? (
          <CostScoreboardUnit>
            <ScoreboardPanel heading="Rich Uncle" value={"Active"} />
          </CostScoreboardUnit>
        ) : proposedChipName === "rich" ? (
          <CostScoreboardUnit>
            <CostButtonWrap>
              <Button width={1} onClick={() => cancelChip("rich")}>
                {t("squad.cancelRichUncleText", "Cancel Rich Uncle")}
              </Button>
            </CostButtonWrap>
          </CostScoreboardUnit>
        ) : (
          <DialogManager
            render={(showDialog, handleShow, handleHide) => (
              <>
                <CostScoreboardUnit>
                  <CostButtonWrap>
                    <Button
                      disabled={
                        proposedChipName === "wildcard" ||
                        activeChipName === "wildcard"
                      }
                      width={1}
                      onClick={handleShow}
                    >
                      {t("squad.playRichUncleText", "Play Rich Uncle")}
                    </Button>
                  </CostButtonWrap>
                </CostScoreboardUnit>
                {showDialog && (
                  <ExplainChipDialog
                    chip="rich"
                    handleHide={() => {
                      handleHide();
                      if (transferCosts) {
                        proposeChip("rich");
                      }
                    }}
                    transferCosts={transferCosts}
                  />
                )}
              </>
            )}
          />
        )}
      </Flex>
      <Flex flexWrap="wrap" alignItems="center" mb={2}>
        <CostScoreboardUnit>
          <ScoreboardPanel
            heading={t("squad.freeTransfers", "Free Transfers")}
            value={
              activeChipName === "wildcard" || activeChipName === "rich"
                ? "Unlimited"
                : `${freeTransfersRemaining}`
            }
          />
        </CostScoreboardUnit>
        <CostScoreboardUnit>
          <ScoreboardPanel
            heading={t("squad.cost", "Cost")}
            value={`${transferCosts} pts`}
            isError={transferCosts > 0}
          />
        </CostScoreboardUnit>
        <CostScoreboardUnit>
          <ScoreboardPanel
            heading={t("squad.moneyRemaining", "Money Remaining")}
            value={
              proposedChipName === "rich" || activeChipName === "rich"
                ? `-- mNOK`
                : `${integerToMoney(toSpend, currencyDivisor)}`
            }
            isError={isBudgetExceeded}
            isSuccess={!isBudgetExceeded}
          />
        </CostScoreboardUnit>
      </Flex>
    </>
  );
};

interface IOwnProps {
  scoreboard: string;
}

interface IPropsFromState {
  activeChipName: string;
  availableChipNames: string[];
  canAutocomplete: boolean;
  canReset: boolean;
  currencyDivisor: number;
  elementTypesByPosition: IElementTypesBySquadPosition | null;
  errors: ISquadErrors;
  freeTransfersRemaining: number;
  proposedChipName: string;
  proposedElements: IProposedElements;
  toSpend: number;
  transferCosts: number;
  chips: IPotentialChip[];
}

interface IPropsFromDispatch {
  autoPick: () => void;
  reset: () => void;
  cancelChip: (chip: string) => void;
  proposeChip: (chip: string) => void;
}

type Props = IOwnProps & IPropsFromState & IPropsFromDispatch;

class Scoreboard extends React.Component<Props> {
  public render() {
    const {
      elementTypesByPosition,
      errors,
      proposedElements,
      scoreboard,
      ...rest
    } = this.props;
    if (!elementTypesByPosition) {
      return null;
    }
    const isNeedElements = Boolean(errors.needElements);
    const isBudgetExceeded = Boolean(errors.budgetExceeded);
    switch (scoreboard) {
      case "basic":
        return (
          <Basic
            {...rest}
            elementTypesByPosition={elementTypesByPosition}
            isNeedElements={isNeedElements}
            isBudgetExceeded={isBudgetExceeded}
            proposedElements={proposedElements}
          />
        );
      case "cost":
        return <Cost {...rest} isBudgetExceeded={isBudgetExceeded} />;
      default:
        return null;
    }
  }
}

const mapStateToProps = (state: RootState): IPropsFromState => ({
  activeChipName: getActiveChipName(state),
  availableChipNames: getAvailableChipNames(state),
  canAutocomplete: canAutocomplete(state),
  canReset: canReset(state),
  currencyDivisor: 10,
  elementTypesByPosition: getElementTypesBySquadPosition(state),
  errors: getErrors(state),
  freeTransfersRemaining: getFreeTransfersRemaining(state),
  proposedChipName: getProposedChipName(state),
  proposedElements: getProposedElements(state),
  toSpend: getToSpend(state),
  transferCosts: getTransferCosts(state),
  chips: getPotentialChips(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  autoPick: () => {
    if (!dispatch(autoComplete())) {
      // We should do something :-)
      window.console.log("Failed to autocomplete");
    }
  },
  cancelChip: (chip: string) => dispatch(cancelProposedChip(chip)),
  proposeChip: (chip: string) => dispatch(proposeAvailableChip(chip)),
  reset: () => dispatch(reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Scoreboard);
