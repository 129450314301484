import { Box } from "rebass";
import styled from "styled-components";

const PitchWrapper = styled(Box)`
  border-top: 1px solid ${({ theme }) => theme.colors.primary};
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.backgroundwhite50};

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    border: 1px solid ${({ theme }) => theme.colors.primary};
    border-radius: ${({ theme }) => theme.radii[0]};
  }
`;

export default PitchWrapper;
