import * as React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { getElement } from "../core/store/elements/reducers";
import { RootState } from "../core/store";
import OnErrorImg from "../img/headshots/shirt_onerror.png";

const Img = styled.img`
  display: block;
  margin: auto;
  max-width: 200px;
  width: 100%;
  height: auto;
`;

interface IProps {
  elementId: number;
}

type Props = IProps;

const Shirt: React.FC<Props> = ({ elementId }) => {
  const element = useSelector((state: RootState) =>
    getElement(state, elementId)
  );

  if (!element) {
    return null;
  }

  return (
    <Img
      src={`https://beta.toppfotball.no/Fantasy/players/${element.fiks_id}.png`}
      alt={element ? `${element.first_name} ${element.second_name}` : ""}
      role="presentation"
      onError={(e) => {
        e.currentTarget.src = `${OnErrorImg}`;
      }}
    />
  );
};
export default Shirt;
