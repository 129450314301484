import { Link as RouterLink } from "@reach/router";
import { useTranslation } from "react-i18next";
import { Box, Flex } from "rebass/styled-components";
import styled from "styled-components/macro";
import LanguageToggle from "./LanguageToggle";
import LogoSVG from "../img/hero-logo.svg";
import Facebook from "../img/icons/facebook-round.svg";
import Insta from "../img/icons/instagram.svg";
import Linkedin from "../img/icons/linkedin-round.svg";
import Twitter from "../img/icons/twitter-round.svg";
import Youtube from "../img/icons/youtube-round.svg";
import TicTok from "../img/icons/tiktok-round.svg";
import { Wrapper } from "./Layout";

const StyledFooter = styled.div`
  padding-top: ${(props) => props.theme.space[4]};
  padding-bottom: ${(props) => props.theme.space[6]};
  border-top: 5px solid ${({ theme }) => theme.colors.blue};
  background-color: ${({ theme }) => theme.colors.blueDarker4};
  font-family: ${({ theme }) => theme.fonts.impact};

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    font-size: 1.6rem;
  }
`;

const Inner = styled.div`
  margin: 0 auto;
  padding-left: ${(props) => props.theme.space[2]};
  padding-right: ${(props) => props.theme.space[2]};
  text-align: center;
  color: white;
`;

const Logo = styled.img`
  width: 75px;
`;

const LangLogoWrap = styled.div`
  display: flex;
  justify-content: center;
  gap: 100px;
  margin-top: ${({ theme }) => theme.space[3]};
`;

const SocialIcon = styled.img`
  width: 20px;
  height: 20px;
  color: white;
  fill: white;
`;

const Link = styled.a`
  color: white;
  line-height: 1;
  margin-right: 1rem;
`;

const InlineBlock = styled.span`
  display: inline-block;
  margin-right: 5px;
`;

const SocialIconWrapper = styled.span`
  display: inline-block;
  font-size: 1rem;
  line-height: 1;
  margin-right: 5px;
`;

const Footer = () => {
  const { t } = useTranslation();
  return (
    <StyledFooter>
      <Wrapper>
        <Inner>
          <LangLogoWrap>
            <LanguageToggle />
            <RouterLink to="/">
              <Logo src={LogoSVG} alt="" />
            </RouterLink>
          </LangLogoWrap>
          <Flex
            my={4}
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
          >
            <Box mb={2}>
              <InlineBlock>{t("footer.email", "Email")}:</InlineBlock>
              <Link href="mailto:support@eliteserien.no">
                support@eliteserien.no
              </Link>
            </Box>
            <Box mb={2} ml={2}>
              <Link href="https://www.eliteserien.no/om-eliteserien">
                {t("footer.contactUs", "Contact Us")}
              </Link>
            </Box>
          </Flex>
          <Flex
            mb={8}
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
          >
            <Flex mb={2} alignItems="center" justifyContent="center">
              <SocialIconWrapper>
                <SocialIcon src={Facebook} alt="Facebook letter f logo" />
              </SocialIconWrapper>
              <Link
                href="https://www.facebook.com/eliteserien/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </Link>
            </Flex>
            <Flex mb={2} mx={4} alignItems="center" justifyContent="center">
              <SocialIconWrapper>
                <SocialIcon src={Twitter} alt="X logo" />
              </SocialIconWrapper>
              <Link
                href="https://twitter.com/eliteserien"
                target="_blank"
                rel="noopener noreferrer"
              >
                X
              </Link>
            </Flex>
            <Flex mb={2} alignItems="center" justifyContent="center">
              <SocialIconWrapper>
                <SocialIcon src={Insta} alt="Instagrams instant camera logo" />
              </SocialIconWrapper>
              <Link
                href="https://www.instagram.com/eliteserien/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </Link>
            </Flex>
            <Flex mb={2} mx={4} alignItems="center" justifyContent="center">
              <SocialIconWrapper>
                <SocialIcon src={Youtube} alt="Youtube logo" />
              </SocialIconWrapper>
              <Link
                href="https://www.youtube.com/channel/UCKB1WCVnk1j3UeM-82MTScA"
                target="_blank"
                rel="noopener noreferrer"
              >
                Youtube
              </Link>
            </Flex>
            <Flex mb={2} mx={4} alignItems="center" justifyContent="center">
              <SocialIconWrapper>
                <SocialIcon src={Linkedin} alt="LinkedIn logo" />
              </SocialIconWrapper>
              <Link
                href="https://www.linkedin.com/company/norsk-toppfotball/"
                target="_blank"
                rel="noopener noreferrer"
              >
                LinkedIn
              </Link>
            </Flex>
            <Flex mb={2} mx={4} alignItems="center" justifyContent="center">
              <SocialIconWrapper>
                <SocialIcon src={TicTok} alt="TikTok logo" />
              </SocialIconWrapper>
              <Link
                href="https://www.tiktok.com/@eliteserien"
                target="_blank"
                rel="noopener noreferrer"
              >
                TikTok
              </Link>
            </Flex>
          </Flex>
          <Flex mb={4} alignItems="center" justifyContent="center">
            <Box ml={2}>
              <b>Foto</b>: NTB - <b>Redaktør</b>: Thomas Torjusen
            </Box>
          </Flex>
          <Flex mb={4} alignItems="center" justifyContent="center">
            <Box>
              <Link href="https://www.eliteserien.no/vilkar-og-betingelser">
                {t("footer.termsAndConditions", "Terms and Conditions")}
              </Link>
            </Box>
            <Box ml={2}>
              <Link href="https://www.eliteserien.no/personvern">
                {t("footer.privacyPolicy", "Privacy Policy")}
              </Link>
            </Box>
          </Flex>
        </Inner>
      </Wrapper>
    </StyledFooter>
  );
};

export default Footer;
