import { Link } from "@reach/router";
import { hideVisually, hiDPI, size } from "polished";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState } from "../core/store";
import { getLoginError, getPlayerData } from "../core/store/player/reducers";
import { login } from "../core/store/player/thunks";
import { ILoginData } from "../core/store/player/types";
import ESPromoSplashLeft1x from "../img/home/es-promo-splash-left.png";
import ESPromoSplashLeft2x from "../img/home/es-promo-splash-left@2x.png";
import ESPromoSplashRight1x from "../img/home/es-promo-splash-right.png";
import ESPromoSplashRight2x from "../img/home/es-promo-splash-right@2x.png";
import { ReactComponent as ESPromoLogo } from "../img/logos/es-fantasy-logo.svg";
import Alert from "./Alert";
import TV2Login from "./auth/TV2Login";
import Button from "./Button";
import Copy from "./Copy";
import { FieldWrap, InputField } from "./FieldRenderers";
import SubHeading from "./SubHeading";
import TextDivider from "./TextDivider";

const LoginWrap = styled.div`
  margin-left: ${({ theme }) => theme.space[3]};
  margin-top: ${({ theme }) => theme.space[3]};
  margin-right: ${({ theme }) => theme.space[3]};
  margin-bottom: ${({ theme }) => theme.space[6]};
  padding: ${({ theme }) => theme.space[6]};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.radii[0]};
  background: ${({ theme }) => theme.colors.backgroundwhite50};
`;

const CopyWrap = styled(Copy)`
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.baseBold};
  color: ${({ theme }) => theme.colors.blackLighter};
  margin-top: -20px;
`;

const Username = styled(FieldWrap)`
  flex: 1 0 28%;
  padding-right: ${({ theme }) => theme.space[2]};
`;

const Password = styled(FieldWrap)`
  flex: 1 0 22%;
  padding-right: ${({ theme }) => theme.space[2]};
`;

const LoginButtonWrap = styled.div`
  margin-bottom: ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 1 0 20%;
    margin-top: 2.3rem;
    margin-bottom: ${({ theme }) => theme.space[4]};
  }
`;

const LoginForm = styled.form`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const LoginLink = styled(Link)`
  color: ${({ theme }) => theme.colors.blackLighter};
  margin-left: ${({ theme }) => theme.space[2]};
  font-weight: bold;
`;

const SocialIntro = styled.p`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${hideVisually()};
  }
`;

const SocialLogin = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    flex-direction: row;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-right: 15%;
    margin-left: 15%;
  }
`;

const StyledPromoBox = styled.div`
  background-image: url(${ESPromoSplashLeft1x}), url(${ESPromoSplashRight1x});

  ${hiDPI(2)} {
    background-image: url(${ESPromoSplashLeft2x}), url(${ESPromoSplashRight2x});
  }

  background-position: left bottom, right top;
  background-repeat: no-repeat, no-repeat;
  background-size: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1.5rem 2.5rem 1.5rem;
  padding: ${({ theme }) => theme.space[6]};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.greyDarker4};

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    background-size: 40%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    background-size: contain;
    flex-direction: row;
    height: 150px;
  }
`;

const PromoLogo = styled(ESPromoLogo)`
  ${size(40, 200)}
`;

const PromoTextWrap = styled.div`
  flex-grow: 1;
  flex-basis: min-content;
  padding: 2rem;
  text-align: center;
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    text-align: left;
    padding: 0 2rem 0 4rem;
  }
`;

const PromoButtonWrap = styled.div`
  min-width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    min-width: 20%;
  }
`;

const PromoText = styled.p`
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 0;
`;

const mapState = (state: RootState) => ({
  error: getLoginError(state),
  player: getPlayerData(state),
});

const mapDispatch = {
  login: (data: ILoginData) => login(data),
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Login: React.FC<PropsFromRedux> = ({ error, login, player }) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { t } = useTranslation();

  // TODO - Probably should send user to correct page after login
  const doLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login({
      email,
      password,
    });
  };

  if (player && player.id) {
    return (
      <CopyWrap>
        <Copy>
          <p>
            {t(
              "login.loggedInAs",
              `You are logged in as ${player.first_name} ${player.last_name}`,
              {
                firstName: player.first_name,
                lastName: player.last_name,
              }
            )}
          </p>
        </Copy>
      </CopyWrap>
    );
  }

  return (
    <>
      <LoginWrap>
        {error && (
          <div>
            <Box mb={2}>
              <Alert type="error">
                {t("login.incorrectDetails", "Incorrect login details")}
              </Alert>
            </Box>
          </div>
        )}
        <Box mb={4}>
          <SubHeading>{t("login.title", "Login")}</SubHeading>
        </Box>
        <LoginForm onSubmit={doLogin}>
          <Username>
            <InputField
              id="loginEmail"
              name="email"
              type="email"
              label={t("login.form.emailLabel", "Email")}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </Username>
          <Password>
            <InputField
              id="loginPassword"
              name="password"
              type="password"
              label={t("login.form.pwd", "Password")}
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
          </Password>
          <LoginButtonWrap>
            <Button width={1} type="submit" disabled={!(email && password)}>
              {t("login.form.loginCta", "Login")}
            </Button>
          </LoginButtonWrap>
          <LoginLink to="/player/password-reset/">
            {t("login.form.forgotPwd", "Forgot your password?")}
          </LoginLink>
        </LoginForm>
        <TextDivider>{t("login.form.or", "Or")}</TextDivider>
        <SocialIntro>
          {t("login.form.orLoginWithSocial", "Log in using:")}
        </SocialIntro>
        <SocialLogin>
          <TV2Login />
          <Box flex="1" m={1}>
            <Button
              width={1}
              variant="secondary"
              href="/api/player/social/login/facebook/"
            >
              {t("login.social.1", "Login with Facebook")}
            </Button>
          </Box>
          <Box flex="1" m={1}>
            <Button width={1} href="/api/player/social/login/twitter/">
              {t("login.social.2", "Login with X")}
            </Button>
          </Box>
        </SocialLogin>
      </LoginWrap>
      <StyledPromoBox>
        <PromoLogo />
        <PromoTextWrap>
          <SubHeading>
            {t("login.promo.heading", "Play Eliteserien Fantasy")}
          </SubHeading>
          <PromoText>
            {t(
              "login.promo.paragraph",
              "Who knows most about Eliteserien? Create a team of 15 players and compete against friends and familiy. Honor and glory is on the line, every single round."
            )}
          </PromoText>
        </PromoTextWrap>
        <PromoButtonWrap>
          <Button width={1} as={Link} to="/player">
            {t("login.promo.signUpNow", "Register now!")}
          </Button>
        </PromoButtonWrap>
      </StyledPromoBox>
    </>
  );
};

export default connector(Login);
