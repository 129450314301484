import { Link } from "@reach/router";
import { hiDPI, ellipsis } from "polished";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import { getElementsById } from "../../core/store/elements/reducers";
import { IElementsById } from "../../core/store/elements/types";
import { getEntry } from "../../core/store/entries/reducers";
import { fetchEntrySummary } from "../../core/store/entries/thunks";
import { IEntry } from "../../core/store/entries/types";
import { getCurrentEvent } from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import { getPlayerData } from "../../core/store/player/reducers";
import { IPlayer } from "../../core/store/player/types";
import { ControlArrowRight } from "../icons/Arrows";

import Button from "../Button";
import BoldLink from "../BoldLink";
import Title from "../Title";
import eventInfoBg1x from "../../img/event-info-bg.png";
import eventInfoBg2x from "../../img/event-info-bg@2x.png";
import eventInfoBgLg1x from "../../img/event-info-bg-lg.png";
import eventInfoBgLg2x from "../../img/event-info-bg-lg@2x.png";
import eventInfoPlayers1x from "../../img/event-info-players.png";
import eventInfoPlayers2x from "../../img/event-info-players@2x.png";

const StyledEventInfo = styled.div`
  position: relative;
  background-image: url(${eventInfoBg1x});
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 1rem;
  margin: 1rem;
  border-radius: 6px;

  ${hiDPI(2)} {
    background-image: url(${eventInfoBg2x});
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    background-image: url(${eventInfoBgLg1x});
    margin: 0;

    ${hiDPI(2)} {
      background-image: url(${eventInfoBgLg2x});
    }
  }
`;
const PlayersImage = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    position: absolute;
    top: 5%;
    right: 2%;
    width: 500px;
    height: 100%;
    background-image: url(${eventInfoPlayers1x});
    background-repeat: no-repeat;
    background-size: contain;

    ${hiDPI(2)} {
      background-image: url(${eventInfoPlayers2x});
    }
  }
`;

const TitleWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 1rem 2rem;
  > ${Title} {
    font-size: 2rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    grid-template-columns: 1fr;
  }
`;

const BoldLinkWrap = styled.div`
  grid-column: 2 / 4;
  justify-self: right;

  > :first-child {
    color: ${({ theme }) => theme.colors.black};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    grid-column: 1;
    justify-self: left;
  }
`;

const ButtonWrap = styled.div`
  grid-column: 1 / 4;
  padding-top: 2rem;
  > :first-child {
    width: 100%;
    text-align: center;
    @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
      width: 25%;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    grid-column: 1;
    grid-row-start: 2;
    padding: 1rem 0;
  }
`;

const EventStats = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
  grid-gap: 1px;
  padding: 2px;
  margin-bottom: 0;
  align-items: stretch;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    grid-gap: 0;
  }
`;

const EventStat = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: ${({ theme }) => theme.space[2]};
  list-style-type: none;
  background-color: ${({ theme }) => theme.colors.whiteDarker};
  color: ${({ theme }) => theme.colors.black};
  text-align: center;

  &:last-child:nth-child(2n-1) {
    grid-column: span 2;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    &:last-child:nth-child(2n-1) {
      grid-column: span 1;
    }
  }
`;

const EventStatsWrap = styled.div`
  clip-path: inset(2px round 0 0 4px 4px);
  overflow: clip;
`;

const ItemHeading = styled.h4`
  margin: 0;
  color: inherit;
`;

const ItemValue = styled.div`
  ${ellipsis()};
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.black};
`;

const ArrowLink = styled(Link)`
  color: ${({ theme }) => theme.colors.black};
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;

interface IPropsFromState {
  elementsById: IElementsById;
  entry: IEntry | null;
  now: IEvent | null;
  player: IPlayer;
}

interface IPropsFromDispatch {
  fetchEntrySummary: (entryId: number) => void;
}

type Props = IPropsFromState & IPropsFromDispatch;

const EventInfo: React.FC<Props> = ({ elementsById, entry, now, player }) => {
  const { t } = useTranslation();

  if (!now || !entry) {
    return null;
  }
  const matches = now.chip_plays.filter((cp) => cp.chip_name === "wildcard");
  const wildcards = matches.length ? matches[0].num_played : "-";
  return (
    <StyledEventInfo>
      <PlayersImage />
      <TitleWrap>
        <Title>{now.name}</Title>
        <BoldLinkWrap>
          <BoldLink to="/prizes">{t("prizes.title", "Prizes")}</BoldLink>
        </BoldLinkWrap>
        <ButtonWrap>
          <Button as={Link} to="/my-team" variant="primary">
            {t("navigation.myTeam", "My Team")}
          </Button>
        </ButtonWrap>
      </TitleWrap>
      <EventStatsWrap>
        <EventStats>
          {entry.started_event <= now.id && (
            <EventStat>
              <ItemHeading>
                {t("eventInfo.yourScore", "Your score")}
              </ItemHeading>
              <ItemValue>
                <ArrowLink to={`/entry/${player.entry}/event/${now.id}`}>
                  {entry.summary_event_points}
                  <ControlArrowRight />
                </ArrowLink>
              </ItemValue>
            </EventStat>
          )}
          <EventStat>
            <ItemHeading>
              {t("eventInfo.averageScore", "Average score")}
            </ItemHeading>
            <ItemValue>{now.average_entry_score || "-"}</ItemValue>
          </EventStat>
          <EventStat>
            <ItemHeading>
              {t("eventInfo.highestScore", "Highest score")}
            </ItemHeading>
            <ItemValue>
              {now.highest_scoring_entry ? (
                <ArrowLink
                  to={`/entry/${now.highest_scoring_entry}/event/${now.id}/`}
                >
                  {now.highest_score}
                  <ControlArrowRight />
                </ArrowLink>
              ) : (
                "-"
              )}
            </ItemValue>
          </EventStat>
          {now.id > 1 && (
            <EventStat>
              <ItemHeading>
                {t("eventInfo.transfersMade", "Transfers Made")}
              </ItemHeading>
              <ItemValue>
                {now.transfers_made ? now.transfers_made.toLocaleString() : "-"}
              </ItemValue>
            </EventStat>
          )}
          <EventStat>
            <ItemHeading>
              {t("eventInfo.wildcardsPlayed", "Wildcards Played")}
            </ItemHeading>
            <ItemValue>
              {wildcards === null ? "-" : wildcards.toLocaleString()}
            </ItemValue>
          </EventStat>
          <EventStat>
            <ItemHeading>
              {t("eventInfo.mostCaptained", "Most Captained")}
            </ItemHeading>
            <ItemValue>
              {now.most_captained
                ? elementsById[now.most_captained].web_name
                : "-"}
            </ItemValue>
          </EventStat>
        </EventStats>
      </EventStatsWrap>
    </StyledEventInfo>
  );
};

const mapStateToProps = (state: RootState): IPropsFromState => {
  const player = getPlayerData(state) as IPlayer;
  const entryId = player.entry || 0;
  const now = getCurrentEvent(state) as IEvent;
  return {
    elementsById: getElementsById(state),
    entry: getEntry(state, entryId),
    now,
    player,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchEntrySummary: (entryId) => dispatch(fetchEntrySummary(entryId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventInfo);
