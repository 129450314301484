import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RootState, ThunkDispatch } from "../../core/store";
import { getURL } from "../../core/store/urls/reducers";
import { fetchURL } from "../../core/store/urls/thunks";
import i18n from "../../i18n";
import ProviderHeader from "../provider-components/ProviderHeader";
import {
  ProviderArticleImg,
  ProviderContent,
  ProviderLink,
  ProviderSection,
} from "../provider-components/ProviderStyles";

const Article = styled.article`
  padding: ${({ theme }) => `${theme.space[4]} ${theme.space[4]} 0`};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.blueDarker4};

    ${ProviderLink} {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

interface IProps {
  url: string;
  numberOfHighlights: number;
}
interface IHighlights {
  title: string;
  images: { defaultUrl: string };
  frontend_url: string;
  id: string;
}

const HighlightsProvider: React.FC<IProps> = ({ url, numberOfHighlights }) => {
  const [highlightsData, sethighlightsData] = useState<[] | null>(null);

  // Fetch feed
  const reduxDispatch = useDispatch<ThunkDispatch>();
  useEffect(() => {
    reduxDispatch(fetchURL(url));
  }, [reduxDispatch, url]);

  // Get raw feed content, will be null until we have some
  const content = useSelector((state: RootState) => getURL(state, url));
  const slugify = (value: string) => {
    return value
      ? value
          .toString()
          .toLowerCase()
          .replace(/æ/g, "ae")
          .replace(/ø/g, "o")
          .replace(/å/g, "a")
          .normalize("NFD")
          .replace(/[^a-z0-9- ]/g, "")
          .replace(/ +/g, " ")
          .trim()
          .replace(/ - /g, "-")
          .replace(/ /g, "-")
      : "";
  };

  const slugifyTitle = (asset: { title: string }) => {
    if (!asset) return "/slug";
    const maxTitleLength = 50;
    const titleToSlugify = asset.title?.substring(0, maxTitleLength) || "";
    return titleToSlugify ? `/${slugify(titleToSlugify)}` : "";
  };

  const createVideoPlayerUrl = (
    asset: { title: string; [x: string]: any; id: any },
    turneringType: string
  ) => {
    const slug = slugifyTitle(asset).substring(1); // Remove leading slash
    const idField =
      turneringType === "spiller" ? "tv2-player-id" : "tv2-tournament-id";
    let urlStart = "https://www.stage.tv2.no";
    if (process.env.REACT_APP_SENTRY_ENVIRONMENT === "production") {
      urlStart = "https://www.tv2.no";
    }
    return `${urlStart}/video/sportauto/fotball/${turneringType}/${slug}/${asset[idField]}/all/${asset.id}`;
  };

  // Parse feed data
  useEffect(() => {
    const parseContent = async () => {
      try {
        const feed = await JSON.parse(content.text);
        if (feed && feed.data) {
          const filteredSslFeedItems = feed.data.splice(0, numberOfHighlights);
          sethighlightsData(filteredSslFeedItems);
        }
      } catch (e) {}
    };
    if (content) {
      parseContent();
    }
  }, [content, numberOfHighlights]);
  if (!highlightsData) {
    return null;
  }
  return (
    <ProviderSection>
      <ProviderHeader
        title={i18n.t("feed.providers.title3", "Videos from TV 2")}
        showMoreLink={false}
      />
      <ProviderContent backgroundColor="white" wide={true}>
        {highlightsData.map((item: IHighlights) => (
          <Article key={item.id}>
            <ProviderLink
              href={createVideoPlayerUrl(item, "spiller")}
              target="_blank"
              rel={item.title}
            >
              <ProviderArticleImg
                src={item.images.defaultUrl}
                alt={item.title}
                aspectRatio={false}
              />
              <h4>{item.title}</h4>
            </ProviderLink>
          </Article>
        ))}
      </ProviderContent>
    </ProviderSection>
  );
};

export default HighlightsProvider;
