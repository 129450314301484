import { useEffect, createContext, useState } from "react";
import { getAuth, getConfig } from "../../utils/allauth";

export interface AuthContextType {
  auth: any;
  config?: {
    status: number;
    [key: string]: any;
  };
}

export const AuthContext = createContext<AuthContextType | null>(null);

export function AuthContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [auth, setAuth] = useState<any>({ status: 200 });
  const [config, setConfig] = useState<{ status: number; data?: any }>({
    status: 200,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [authData, configData] = await Promise.all([
          getAuth(),
          getConfig(),
        ]);
        setAuth(authData);
        setConfig(configData);
      } catch (error) {
        setAuth({ status: 200, data: { isAuthenticated: false } });
        setConfig({ status: 200, data: {} });
      }
    };

    fetchData();

    // Auth change listener
    const onAuthChanged = (e: CustomEvent) => {
      setAuth(e.detail);
    };

    document.addEventListener(
      "allauth.auth.change",
      onAuthChanged as EventListener
    );

    return () => {
      document.removeEventListener(
        "allauth.auth.change",
        onAuthChanged as EventListener
      );
    };
  }, []);

  const loading = !auth || !config || config.status !== 200;

  if (loading) {
    return <div>Starting...</div>;
  }

  if (config.status !== 200) {
    return <div>Error loading configuration</div>;
  }

  return (
    <AuthContext.Provider value={{ auth, config }}>
      {children}
    </AuthContext.Provider>
  );
}
