import { size } from "polished";
import styled from "styled-components/macro";
import { ReactComponent as Logo } from "../../img/logos/es-fantasy-logo.svg";
import { getPrefersReducedMotion } from "../../utils/reducedMotion";
import Link from "../Link";
import SponsorImages from "./SponsorRotatingImages";
import { ISponsors } from "./types";

const ReduceMotionWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    margin-bottom: ${(props) => props.theme.space[4]};
  }
`;
const ReduceMotionWrapNested = styled.div`
  display: flex;
  gap: ${(props) => props.theme.space[1]};

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    gap: ${(props) => props.theme.space[6]};
  }
`;

const StyledImage = styled.img`
  ${size(21, 60)}
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    ${size(29, 81)}
  }
`;

const StyledLogo = styled(Logo)`
  ${size(38, 118)}

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    ${size(38, 173)}
  }
`;

const GameHeading = styled.h1`
  position: relative;
  z-index: 3;
  margin: 0;
  line-height: 0.8;
`;

const Sponsors = () => {
  const images: ISponsors = [
    {
      image: `${process.env.PUBLIC_URL}/img/sponsors/sponsor-1.svg`,
      altText: "Altibox",
    },
    {
      image: `${process.env.PUBLIC_URL}/img/sponsors/sponsor-2.svg`,
      altText: "TV2",
    },
    {
      image: `${process.env.PUBLIC_URL}/img/sponsors/sponsor-3.svg`,
      altText: "Obos",
    },
    {
      image: `${process.env.PUBLIC_URL}/img/sponsors/sponsor-4.svg`,
      altText: "Norsk Tipping",
    },
  ];
  return getPrefersReducedMotion() ? (
    <ReduceMotionWrap>
      <StyledLogo />
      <ReduceMotionWrapNested>
        {images.map((image, index) => (
          <StyledImage key={index} src={image.image} alt={image.altText} />
        ))}
      </ReduceMotionWrapNested>
    </ReduceMotionWrap>
  ) : (
    <>
      <SponsorImages images={images} />
      <GameHeading>
        <Link to="/">
          <StyledLogo />
        </Link>
      </GameHeading>
      <SponsorImages images={images} />
    </>
  );
};

export default Sponsors;
