import playerComp1 from "../img/player-comps/player-comp@1x.png";
import playerComp1x2 from "../img/player-comps/player-comp@2x.png";
import playerComp2 from "../img/player-comps/player-comp2@1x.png";
import playerComp2x2 from "../img/player-comps/player-comp2@2x.png";
import playerComp3 from "../img/player-comps/player-comp3@1x.png";
import playerComp3x2 from "../img/player-comps/player-comp3@2x.png";
import playerComp4 from "../img/player-comps/player-comp4@1x.png";
import playerComp4x2 from "../img/player-comps/player-comp4@2x.png";
import playerComp5 from "../img/player-comps/player-comp5@1x.png";
import playerComp5x2 from "../img/player-comps/player-comp5@2x.png";
import playerComp6 from "../img/player-comps/player-comp6@1x.png";
import playerComp6x2 from "../img/player-comps/player-comp6@2x.png";
import playerCompWide1 from "../img/player-comps/player-comp-wide@1x.png";
import playerCompWide1x2 from "../img/player-comps/player-comp-wide@2x.png";
import playerCompWide2 from "../img/player-comps/player-comp-wide2@1x.png";
import playerCompWide2x2 from "../img/player-comps/player-comp-wide2@2x.png";
import playerCompWide3 from "../img/player-comps/player-comp-wide3@1x.png";
import playerCompWide3x2 from "../img/player-comps/player-comp-wide3@2x.png";
import playerCompWide4 from "../img/player-comps/player-comp-wide4@1x.png";
import playerCompWide4x2 from "../img/player-comps/player-comp-wide4@2x.png";
import playerCompWide5 from "../img/player-comps/player-comp-wide5@1x.png";
import playerCompWide5x2 from "../img/player-comps/player-comp-wide5@2x.png";
import playerCompWide6 from "../img/player-comps/player-comp-wide6@1x.png";
import playerCompWide6x2 from "../img/player-comps/player-comp-wide6@2x.png";

export interface IPlayerCompProps {
  images: Record<string, string>;
}

let choice = -1;

export const getPlayerComp = () => {
  const playerImages = [
    {
      x1: playerComp1,
      x2: playerComp1x2,
      x1wide: playerCompWide1,
      x2wide: playerCompWide1x2,
    },
    {
      x1: playerComp2,
      x2: playerComp2x2,
      x1wide: playerCompWide2,
      x2wide: playerCompWide2x2,
    },
    {
      x1: playerComp3,
      x2: playerComp3x2,
      x1wide: playerCompWide3,
      x2wide: playerCompWide3x2,
    },
    {
      x1: playerComp4,
      x2: playerComp4x2,
      x1wide: playerCompWide4,
      x2wide: playerCompWide4x2,
    },
    {
      x1: playerComp5,
      x2: playerComp5x2,
      x1wide: playerCompWide5,
      x2wide: playerCompWide5x2,
    },
    {
      x1: playerComp6,
      x2: playerComp6x2,
      x1wide: playerCompWide6,
      x2wide: playerCompWide6x2,
    },
  ];

  if (choice < 0) {
    choice = Math.floor(Math.random() * playerImages.length);
  }
  return playerImages[choice];
};
