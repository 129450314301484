import { hiDPI } from "polished";
import * as React from "react";
import styled from "styled-components/macro";
import { getPlayerComp, IPlayerCompProps } from "../utils/player-comps";
import Navigation from "./nav/Navigation";
import Sponsors from "./sponsors/Sponsors";

const GameHeaderOuter = styled.div`
  min-height: 150px;
`;

const GameHeaderInner = styled.div`
  position: relative;
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
  padding: ${({ theme }) => `0 ${theme.space[4]} ${theme.space[2]}`};
`;

const HeaderUpperNavWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 100px;
  margin-top: ${(props) => props.theme.space[4]};
`;

const GameHeaderUpper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    margin-bottom: ${(props) => props.theme.space[6]};
  }
`;

const PlayerImage = styled.div<IPlayerCompProps>`
  position: absolute;
  left: 0;
  right: 0;
  bottom: -15px;
  z-index: 1;
  width: 350px;
  height: 151px;
  margin: auto;
  background-image: url(${(props) => props.images.x1});
  background-repeat: no-repeat;
  background-size: 350px 151px;

  ${hiDPI(2)} {
    background-image: url(${(props) => props.images.x2});
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    bottom: 15px;
    width: 500px;
    height: 112px;
    background-image: url(${(props) => props.images.x1wide});
    background-size: 500px 110px;

    ${hiDPI(2)} {
      background-image: url(${(props) => props.images.x2wide});
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    width: 600px;
    height: 135px;
    background-size: 600px 135px;
  }
`;

const TransparentGradient = styled.div`
  z-index: -1;
  position: absolute;
  top: 40px;
  width: 100%;
  min-height: 200px;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.4) 25.5%,
    rgba(255, 255, 255, 0.58) 47.5%,
    rgba(255, 255, 255, 0.4) 70%,
    rgba(255, 255, 255, 0) 97%
  );

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    top: 0px;
    min-height: 350px;
  }
`;

const GameHeader: React.FC = () => {
  return (
    <GameHeaderOuter>
      <TransparentGradient />
      <GameHeaderInner>
        <HeaderUpperNavWrap>
          <GameHeaderUpper>
            <Sponsors />
          </GameHeaderUpper>
          <PlayerImage images={getPlayerComp()} />
          <Navigation />
        </HeaderUpperNavWrap>
      </GameHeaderInner>
    </GameHeaderOuter>
  );
};
export default GameHeader;
