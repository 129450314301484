import { RouteComponentProps, useNavigate } from "@reach/router";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import * as allauth from "../../utils/allauth";
import Alert from "../Alert";
import Button from "../Button";
import SubHeading from "../SubHeading";

const CallbackContainer = styled(Box)`
  margin-top: 6rem;
  margin-bottom: 3rem;
  padding: 3rem;
  background-color: #f2f9fa;
  text-align: center;
`;

const AuthCallback: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  // isLoading state is used to track the authentication process
  const [, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const processAuth = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);

        // Check if we're already authenticated
        const auth = await allauth.getAuth();
        if (auth.status === 200 && auth.meta?.is_authenticated) {
          navigate("/");
          return;
        }

        // Check if there's an error in the URL
        const errorParam = urlParams.get("error");
        const errorDescription = urlParams.get("error_description");

        if (errorParam) {
          // Provide more helpful error message based on the error type
          let errorMessage = errorDescription || "Authentication failed";
          if (
            errorParam === "unknown" &&
            urlParams.get("error_process") === "login"
          ) {
            errorMessage =
              "TV 2 authentication service returned an unknown error. Please check your credentials or try again later.";
          }

          setError(errorMessage);
          setLoading(false);
          return;
        }

        // Process authentication response from the URL
        const code = urlParams.get("code");
        const state = urlParams.get("state");

        if (code && state) {
          try {
            const response = await allauth.authenticateByToken(
              "auth0", // provider id
              code, // the authorization code
              allauth.AuthProcess.LOGIN
            );

            if (response.status === 200 && response.meta?.is_authenticated) {
              const returnPath =
                localStorage.getItem("auth_return_path") || "/";
              localStorage.removeItem("auth_return_path");
              navigate(returnPath);
            } else {
              setError(response.message || "Authentication failed");
              setLoading(false);
            }
          } catch (apiError) {
            setError("API error during authentication");
            setLoading(false);
          }
        } else {
          // If we're missing code or state but have an id_token (for implicit flow)
          const idToken = urlParams.get("id_token");
          if (idToken) {
            try {
              const response = await allauth.authenticateByToken(
                "auth0",
                idToken,
                allauth.AuthProcess.LOGIN
              );

              if (response.status === 200 && response.meta?.is_authenticated) {
                navigate("/");
              } else {
                setError(response.message || "Authentication failed");
                setLoading(false);
              }
            } catch (tokenError) {
              setError("API error during token authentication");
              setLoading(false);
            }
          } else {
            // Missing required parameters
            setError("Invalid authentication response");
            setLoading(false);
          }
        }
      } catch (err) {
        setError("An unexpected error occurred during authentication");
        setLoading(false);
      }
    };

    processAuth();
  }, [navigate]);

  if (error) {
    return (
      <CallbackContainer>
        <SubHeading>
          {t("login.tv2.authError", "Authentication Error")}
        </SubHeading>
        <Alert type="error">{error}</Alert>
        <Box mt={4}>
          <Button width={1} onClick={() => navigate("/")}>
            {t("login.tv2.backToLogin", "Back to Login")}
          </Button>
        </Box>
      </CallbackContainer>
    );
  }

  return (
    <CallbackContainer>
      <SubHeading>
        {t("login.tv2.authenticating", "Authenticating...")}
      </SubHeading>
      <p>
        {t(
          "login.tv2.pleaseWait",
          "Please wait while we complete your authentication with TV2."
        )}
      </p>
    </CallbackContainer>
  );
};

export default AuthCallback;
