import { useEffect, useState } from "react";
import styled from "styled-components";
import { ISponsorImageStyles, SponsorImagesProps } from "./types";

const Image = styled.img<ISponsorImageStyles>`
  opacity: ${(props) => (props.$isVisible ? 1 : 0)};
  transition: opacity 1s ease-in-out;
`;

const SponsorImages = ({ images }: SponsorImagesProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const imageCount = Object.keys(images).length;

  useEffect(() => {
    const interval = setInterval(() => {
      setIsVisible(false);

      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % imageCount);
        setIsVisible(true);
      }, 1000);
    }, 4000);

    return () => clearInterval(interval);
  }, [imageCount]);

  return (
    <Image
      $isVisible={isVisible}
      src={images[currentIndex].image}
      alt={images[currentIndex].altText}
    />
  );
};
export default SponsorImages;
