import { Location } from "@reach/router";
import { useTranslation } from "react-i18next";
import { Box } from "rebass/styled-components";
import * as allauth from "../../utils/allauth";
import Button from "../Button";

const TV2Login = () => {
  const { t } = useTranslation();

  const handleTV2Login = () => {
    // Store the current path to redirect back after authentication
    localStorage.setItem("auth_return_path", "/");

    const callbackUrl = "/api/accounts/auth0/login/callback/";

    try {
      // Redirect to Auth0 provider for SSO login
      allauth.redirectToProvider(
        "auth0",
        callbackUrl,
        allauth.AuthProcess.LOGIN
      );
    } catch (error) {
      console.error("Error during TV 2 login redirect:", error);
    }
  };

  return (
    <Location>
      {() => (
        <Box flex="1" m={1}>
          <Button width={1} variant="secondary" onClick={handleTV2Login}>
            {t("login.tv2Login", "TV 2 Login")}
          </Button>
        </Box>
      )}
    </Location>
  );
};

export default TV2Login;
