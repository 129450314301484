import { hideVisually, rgba } from "polished";
import * as React from "react";
import ReactDOM from "react-dom";
import styled, { css } from "styled-components/macro";
import { ReactComponent as Close } from "../img/icons/close.svg";
import "./OverflowScrolling.css";

const CloseIcon = styled(Close)`
  fill: white;
`;

const CloseLabel = styled.span`
  ${hideVisually}
`;

const Button = styled.button`
  position: absolute;
  top: -16px;
  right: -16px;
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
`;

// https://css-tricks.com/considerations-styling-modal/
// 1. commented out height to see if we can be more flexible

const StyledDialog = styled.dialog`
  z-index: ${(props) => props.theme.zIndex.dialog};
  max-width: 90%;
  /* height: 400px; [1] */
  max-height: 90%;
  padding: 0;
  border: 0;
  border-radius: ${(props) => props.theme.radii[0]};
  /* Ugly hack to deal with sub-pixel rounding in chrome bug causing blurry content with transform */
  width: 640px;
  color: currentColor;
`;

const Overlay = styled.div`
  position: fixed;
  z-index: ${(props) => props.theme.zIndex.overlay};
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${rgba("black", 0.5)};
`;

const StyledHeader = styled.div<IStyledHeaderProps>`
  flex: 1;
  margin: ${({ theme }) =>
    `${theme.space[2]} ${theme.space[2]} ${theme.space[1]}`};
  padding: ${({ theme }) => theme.space[2]};
  border-radius: ${(props) => props.theme.radii[0]};
  background: ${(props) => props.theme.colors.blueDarker4};
  font-family: ${({ theme }) => theme.fonts.impactMedium};
  font-size: ${({ theme }) => theme.fontSizes[3]};
  color: ${(props) => props.theme.colors.white};
  text-align: center;
`;

export const DialogButtonItem = styled.div`
  margin-bottom: ${({ theme }) => theme.space[2]};

  &:last-child {
    margin-bottom: 0;
  }
`;

interface IDialogHeaderProps {
  children: React.ReactNode;
  closeDialog: () => void;
}

interface IStyledHeaderProps {
  variant?: "light" | undefined;
}

type DialogHeaderProps = IDialogHeaderProps & IStyledHeaderProps;

const DialogHeader: React.FC<DialogHeaderProps> = ({
  children,
  variant,
  closeDialog,
}) => (
  <StyledHeader variant={variant}>
    {children}
    <Button onClick={closeDialog}>
      <CloseLabel>close</CloseLabel>
      <CloseIcon />
    </Button>
  </StyledHeader>
);

const isVisible = (elem: HTMLElement) =>
  !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);

interface IDialogBodyProps {
  children: any;
  isPadded?: boolean;
}

const StyledDialogBody = styled.div<IDialogBodyProps>`
  max-height: calc(90vh - 128px);
  overflow-y: auto;
  ${(props) =>
    props.isPadded &&
    css`
      padding: ${props.theme.space[4]} ${props.theme.space[3]};
    `};
  > ul {
    padding: 0;
  }
`;

const DialogBody: React.SFC<IDialogBodyProps> = ({ children, isPadded }) => (
  <StyledDialogBody isPadded={isPadded} className="ism-overflow-scroll">
    {children}
  </StyledDialogBody>
);

DialogBody.defaultProps = {
  isPadded: true,
};

interface IDialogProps {
  children: any;
  closeDialog: (
    e:
      | React.MouseEvent<HTMLButtonElement>
      | React.MouseEvent<HTMLDivElement>
      | KeyboardEvent
  ) => void;
  focusable: string;
}

class Dialog extends React.Component<IDialogProps> {
  public static Header = DialogHeader;
  public static Body = DialogBody;

  public static defaultProps = {
    focusable:
      "a[href], area[href], input:not([disabled]), " +
      "select:not([disabled]), textarea:not([disabled]), " +
      "button:not([disabled]), iframe, object, embed, " +
      "*[tabindex], *[contenteditable]",
  };

  private docref = React.createRef<HTMLDivElement>();
  private focusReturn = document.activeElement;

  public componentDidMount() {
    document.addEventListener("keydown", this.handleKeydown);
    const docRef = this.docref.current;
    if (docRef) {
      const focusElement = docRef.querySelector(
        this.props.focusable
      ) as HTMLElement;
      if (focusElement) {
        focusElement.focus();
      }
    }
  }

  public componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeydown);
    const focusReturn = this.focusReturn as HTMLElement;
    if (focusReturn) {
      if (focusReturn.focus) {
        focusReturn.focus();
      }
    }
  }

  public handleKeydown = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      this.props.closeDialog(e);
    } else if (e.key === "9") {
      this.handleTab(e);
    }
  };

  public handleOutsideMouseClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      this.props.closeDialog(e);
      e.stopPropagation();
    }
  };

  public handleTab(e: KeyboardEvent) {
    const docRef = this.docref.current;
    const focusedItem = document.activeElement;
    if (docRef) {
      const focusableItems = Array.prototype.slice
        .call(docRef.querySelectorAll(this.props.focusable))
        .filter(isVisible);
      const numFocusableItems = focusableItems.length;
      const focusedIndex = focusableItems.indexOf(focusedItem);
      if (!e.shiftKey && focusedIndex === numFocusableItems - 1) {
        // Moving past last focusable item so focus first
        focusableItems[0].focus();
        e.preventDefault();
      } else if (e.shiftKey && focusedIndex === 0) {
        // Moving before first focusable item so focus last
        focusableItems[numFocusableItems - 1].focus();
        e.preventDefault();
      }
    }
  }

  public render() {
    // https://github.com/facebook/react/issues/11387
    return ReactDOM.createPortal(
      <Overlay
        onClick={(e) => {
          e.stopPropagation();
          this.handleOutsideMouseClick(e);
        }}
        role="presentation"
      >
        <StyledDialog aria-labelledby="ism-dialog-title" open={true}>
          <div role="document" ref={this.docref}>
            {this.props.children}
          </div>
        </StyledDialog>
      </Overlay>,
      document.getElementById("root-dialog") as HTMLElement
    );
  }
}

export default Dialog;
