import React from "react";
import styled, { css } from "styled-components";
import { size } from "polished";
import { ReactComponent as EliteSerienLogo } from "../img/logos/es-lion-logo.svg";

interface ILogoProps {
  justifyContent: string;
}

const StyledLogoBar = styled.div<ILogoProps>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  margin-top: ${({ theme }) => theme.space[5]};
  margin-bottom: ${({ theme }) => theme.space[3]};
  padding: ${({ theme }) => theme.space[2]};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.backgroundwhite50};

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    border-radius: ${({ theme }) => theme.radii[0]};
  }

  ${({ justifyContent }) =>
    (justifyContent === "flex-start" || justifyContent === "space-between") &&
    css`
      padding-left: 55px;
    `}
`;

const Logo = styled(EliteSerienLogo)`
  ${size(24, 32)};
  margin-right: 1.6rem;
`;

interface IProps {
  children: React.ReactNode;
  justifyContent?: string;
}

const LogoBar: React.FC<IProps> = ({
  children,
  justifyContent = "right",
  ...props
}) => {
  return (
    <StyledLogoBar justifyContent={justifyContent} {...props}>
      <Logo />
      {children}
    </StyledLogoBar>
  );
};

export default LogoBar;
