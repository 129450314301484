import styled, { css } from "styled-components/macro";

const ScorePrefixStyles = css`
  display: inline-block;
  width: ${({ theme }) => theme.space[5]};
  height: ${({ theme }) => theme.space[5]};
  border-radius: 100%;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  line-height: 2.6rem;
  text-align: center;
  vertical-align: middle;
`;

export const WinPrefix = styled.span`
  ${ScorePrefixStyles}
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.successDark};
`;

export const LosePrefix = styled.span`
  ${ScorePrefixStyles}
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.error};
`;

export const DrawPrefix = styled.span`
  ${ScorePrefixStyles}
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.blackLighter}; ;
`;
