import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../core/store";
import { getElementStats } from "../core/store/element-stats/reducers";
import { IElementStat } from "../core/store/element-stats/types";
import { getElementTypesById } from "../core/store/element-types/reducers";
import { IElementTypesById } from "../core/store/element-types/types";
import { elementDialogHide } from "../core/store/elements/actions";
import {
  getElement,
  getElementDialog,
  getFixturesWithBlanks,
  getHistory,
  getHistoryTotals,
  getSeasonHistory,
} from "../core/store/elements/reducers";
import {
  IElement,
  IElementFixture,
  IElementFixtureBlank,
  IElementHistory,
  IElementSeasonHistory,
} from "../core/store/elements/types";
import { getCurrentEvent, getNextEvent } from "../core/store/events/reducers";
import { IEvent } from "../core/store/events/types";
import { getPlayerData, getWatched } from "../core/store/player/reducers";
import {
  addToWatchlist,
  removeFromWatchlist,
} from "../core/store/player/thunks";
import { IPlayer } from "../core/store/player/types";
import { proposeElement } from "../core/store/squad/thunks";
import { getTeamsById } from "../core/store/teams/reducers";
import { ITeamsById } from "../core/store/teams/types";
import { formatRawAsLocal, formatRawAsLocalI18n } from "../core/utils/datetime";
import { integerToMoney } from "../core/utils/money";
import { dateLocales } from "../i18n";
import OnErrorImg from "../img/headshots/shirt_onerror.png";
import { getIncludedStats } from "../utils/stats";
import Alert from "./Alert";
import Badge from "./Badge";
import Button from "./Button";
import Dialog from "./Dialog";

import FixtureForElement from "./FixtureForElement";
import "./OverflowScrolling.css";
import Panel from "./Panel";
import { DrawPrefix, LosePrefix, WinPrefix } from "./ScorePrefix";
import SubHeading from "./SubHeading";
import Table from "./Table";
import TabPanel from "./tabs/TabPanel";
import Tabs from "./tabs/Tabs";
import Tooltip, { TooltipLabel } from "./Tooltip";
import HighlightsProvider from "./highlights/HighlightsProvider";

const TabsWrapper = styled.div`
  padding: 20px 10px 10px 10px;
`;

const Summary = styled.div`
  padding: ${({ theme }) => theme.space[3]} 0;
`;

const Card = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 140px;
  padding: ${({ theme }) => theme.space[2]};
  border: 2px solid ${({ theme }) => theme.colors.blueDarker4};
  border-radius: ${({ theme }) => theme.radii[0]};
  background: linear-gradient(0deg, rgba(222, 242, 248, 0) 0%, #fff 100%),
    linear-gradient(90deg, #def2f8 0%, #e7d6f4 100%);
`;

const TablesWrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    justify-content: space-between;
  }
`;

const TableWrapper = styled.div`
  margin-top: ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 calc(50% - 1rem);
  }
`;

const CardInfo = styled.div`
  width: 58%;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: 40%;
  }
`;

const Img = styled.img`
  position: absolute;
  bottom: 0;
  left: -23px;
  max-width: 180px;

  @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    left: -10px;
    max-width: 180px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    left: 10px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    left: 50px;
    max-width: 175px;
  }
`;

const ScrollTable = styled.div`
  overflow-x: auto;
  width: 100%;
  /* We're adding this in OverflowScrolling.css
	 * because the property gets removed from the
	 * styled component on test */
  /* -webkit-overflow-scrolling: touch; */
`;

const HistoryTable = styled(Table)`
  white-space: nowrap;
`;

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ElementDialogHeaderWrap = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space[1]};
  margin-right: ${({ theme }) => theme.space[3]};
`;

const ElementHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.space[4]};
  width: 100%;
  padding: ${({ theme }) => theme.space[2]};
  border-radius: ${({ theme }) => theme.radii[0]};
  background-color: ${({ theme }) => theme.colors.blueDarker4};
  line-height: 1;
`;

const NameMoneyWrap = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space[4]};
`;

const ElementMoney = styled.span`
  display: flex;
  align-items: center;
  max-width: 75px;
  max-height: 40px;
  padding: ${({ theme }) => theme.space[1]} ${({ theme }) => theme.space[2]};
  border-radius: ${({ theme }) => theme.radii[0]};
  background: white;
  font-family: ${({ theme }) => theme.fonts.impact};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.blueDarker4};
  line-height: 1;
`;

const ElementFullName = styled.h1`
  margin: 0;
  font-family: ${({ theme }) => theme.fonts.impact};
  font-size: ${({ theme }) => theme.fontSizes[5]};
  font-weight: 600;
  color: white;
  text-align: left;
  line-height: 1;
`;

const FixtureForElementWrap = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
`;

const StyledTableHeadOne = styled.th`
  width: 10%;
`;

const StyledTableHeadTwo = styled.th`
  width: 25%;
`;

const TeamBadge = styled.img`
  max-width: 50px;
  max-height: 50px;
`;

const ElementInfo = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const ElementInfoItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f2f2f2;
  background-color: ${({ theme }) => theme.colors.blueDarker4};
  color: white;

  :first-of-type {
    border-top-left-radius: ${({ theme }) => theme.radii[0]};
    border-top-right-radius: ${({ theme }) => theme.radii[0]};
  }

  :last-of-type {
    border-bottom-right-radius: ${({ theme }) => theme.radii[0]};
    border-bottom-left-radius: ${({ theme }) => theme.radii[0]};
    border: 0;
  }
`;

const ElementInfoHeading = styled.h3`
  margin: 0;
  padding: 10px;
  width: 50%;
  font-size: 14px;
  line-height: 1;
`;

const ElementInfoValue = styled.div`
  padding: 10px;
  width: 50%;
  font-size: 14px;
  line-height: 1;
`;

const TableHeading = styled.h3`
  padding: 5px 10px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
  background: #003b5c;
  color: white;
  font-family: "TV 2 Text Light", "Helvetica", "Arial", sans-serif;
  text-transform: none;
  font-size: 16px;
`;

const NoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;
  min-height: 50px;
  border-bottom-right-radius: ${({ theme }) => theme.radii[0]};
  border-bottom-left-radius: ${({ theme }) => theme.radii[0]};
  background-color: #efefef;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    min-height: 172px;
  }
`;

const TdBold = styled.td`
  font-weight: bold;
  height: 35px;
`;

interface IElementFixtureFPL extends IElementFixture {
  difficulty: number;
}

interface IOwnProps {
  element: IElement;
}
interface IHighlights {
  url: string;
}

interface IPropsFromDispatch {
  addToWatched: (elementCode: number) => void;
  closeDialog: () => void;
  proposeElement: (elementId: number) => boolean;
  removeFromWatched: (elementCode: number) => void;
}

interface IPropsFromState {
  currentEvent: IEvent | null;
  elementTypesById: IElementTypesById;
  fixtures: Array<IElementFixtureFPL | IElementFixtureBlank>;
  nextEvent: IEvent | null;
  player: IPlayer | null;
  seasonHistory: IElementSeasonHistory[];
  stats: ReadonlyArray<IElementStat>;
  teamsById: ITeamsById;
  watched: number[];
  history: IElementHistory[];
  historyTotals: Record<string, number | string>;
}

type Props = IOwnProps & IPropsFromDispatch & IPropsFromState & WithTranslation;

class ElementDialog extends React.Component<Props> {
  public added = false;
  public componentWillUnmount() {
    this.props.closeDialog();
  }

  public addElement = (elementId: number) => {
    this.props.proposeElement(elementId);
    this.props.closeDialog();
  };

  public renderWatchlistButton() {
    const { addToWatched, element, player, removeFromWatched, t, watched } =
      this.props;
    if (!player || !player.entry) {
      return null;
    }
    const inWatched = watched.indexOf(element.code) > -1;
    if (inWatched) {
      return this.added ? (
        <p>
          {t(
            "elementDialog.watchlist.view",
            "View your watchlist on the transfers page"
          )}
        </p>
      ) : (
        <Button
          onClick={() => {
            removeFromWatched(element.code);
          }}
          width={1}
        >
          {t("elementDialog.watchlist.remove", "Remove from Watchlist")}
        </Button>
      );
    }
    return (
      <Button
        onClick={() => {
          this.added = true;
          addToWatched(element.code);
        }}
        width={1}
      >
        {t("elementDialog.watchlist.add", "Add to Watchlist")}
      </Button>
    );
  }

  public render() {
    const {
      closeDialog,
      // currentEvent,
      // nextEvent,
      element,
      elementTypesById,
      fixtures,
      history,
      historyTotals,
      i18n,
      seasonHistory,
      stats,
      t,
      teamsById: teams,
    } = this.props;

    // Only get the last three in the history
    const lastThreeHistory = history.slice(-3);
    const firstThreeFixtures = fixtures.slice(0, 3);
    // Element info
    const elementType = elementTypesById[element.element_type];
    const elementFullname = `${element.first_name} ${element.second_name}`;
    const elementCost = `${integerToMoney(element.now_cost, 10)}m`;
    const includedStats = getIncludedStats(stats);

    let hpHighlightsUrl = `https://tv2news.content-discovery.cf.eu-north-1-preprod.vmnd.tv/api/v1/assets?platform=web&page%5Bsize%5D=5&page%5Bnumber%5D=1&query=%28fiks-id-player%3A%22person-${element.fiks_id}%22%20AND%20%28type-of-action%3AGoal%20OR%20type-of-action%3ARedCard%29%20AND%20%28tv2-tournament-id%3A%2256f79b03-9608-4b12-9903-2ebaa1a07922%22%20OR%20tv2-tournament-id%3A%22e53e02b8-a512-51d3-aa98-ceda8e3a01b1%22%29%29`;
    const production_url = `https://tv2news.content-discovery.cf.eu-north-1-prod.vmnd.tv/api/v1/assets?platform=web&page%5Bsize%5D=5&page%5Bnumber%5D=1&query=%28fiks-id-player%3A%22person-${element.fiks_id}%22%20AND%20%28type-of-action%3AGoal%20OR%20type-of-action%3ARedCard%29%20AND%20tv2-tournament-id%3A%22e53e02b8-a512-51d3-aa98-ceda8e3a01b1%22%29`;
    if (process.env.REACT_APP_SENTRY_ENVIRONMENT === "production") {
      hpHighlightsUrl = production_url;
    }

    const highlights: IHighlights[] = [
      {
        url: hpHighlightsUrl,
      },
    ];

    return (
      <Dialog closeDialog={closeDialog}>
        <ElementDialogHeaderWrap>
          <Dialog.Header closeDialog={closeDialog} variant="light">
            <HeaderWrap>
              <ElementHeader>
                <NameMoneyWrap>
                  <ElementFullName>{elementFullname}</ElementFullName>
                  <ElementMoney>{elementCost}</ElementMoney>
                </NameMoneyWrap>
              </ElementHeader>
            </HeaderWrap>

            {/* <Box p={2} bg="black">
            <Flex alignItems="center" justifyContent="space-between">
              <Box>
                <Button onClick={() => this.addElement(element.id)}>
                  {t("elementDialog.addPlayerCTA", "Add player")}
                </Button>
              </Box>
              <Box>
                <div style={{ color: "white" }}>
                  {t("elementDialog.playerProfile", "View player profile")}
                </div>
              </Box>
            </Flex>
          </Box> */}
          </Dialog.Header>
          <TeamBadge
            src={`${process.env.PUBLIC_URL}/img/badges/badge_${element.team_code}.png`}
            alt={t(
              "elementDialog.tabs.summary.teamBadgeAlt",
              "Football club badge for {{name}}",
              { name: teams[element.team].name }
            )}
            role="presentation"
          />
        </ElementDialogHeaderWrap>
        <Dialog.Body isPadded={false}>
          {element.news && (
            <Box m={2}>
              <Alert type="news" copnr={element.chance_of_playing_next_round}>
                {element.news}
              </Alert>
            </Box>
          )}
          <TabsWrapper>
            <Tabs>
              <TabPanel
                label={t("elementDialog.tabs.summary.heading", "Summary")}
                link="Summary"
              >
                <Summary>
                  <Card>
                    <Img
                      src={`https://beta.toppfotball.no/Fantasy/players/${element.fiks_id}.png`}
                      alt={t(
                        "elementDialog.tabs.summary.playerImageAlt",
                        "Player image for {{name}}",
                        { name: elementFullname }
                      )}
                      role="presentation"
                      onError={(e) => {
                        e.currentTarget.src = `${OnErrorImg}`;
                      }}
                    />

                    <CardInfo>
                      <ElementInfo>
                        <ElementInfoItem>
                          <ElementInfoHeading>
                            {t(
                              "elementDialog.tabs.summary.position",
                              "Position"
                            )}
                            :
                          </ElementInfoHeading>
                          <ElementInfoValue>
                            {elementType.singular_name}
                          </ElementInfoValue>
                        </ElementInfoItem>
                        <ElementInfoItem>
                          <ElementInfoHeading>
                            {t(
                              "elementDialog.tabs.summary.selectedBy",
                              "Selected by"
                            )}
                            :
                          </ElementInfoHeading>
                          <ElementInfoValue>
                            {element.selected_by_percent}%
                          </ElementInfoValue>
                        </ElementInfoItem>
                        <ElementInfoItem>
                          <ElementInfoHeading>
                            {t(
                              "elementDialog.tabs.summary.totalScore",
                              "Total score"
                            )}
                            :
                          </ElementInfoHeading>
                          <ElementInfoValue>
                            {element.total_points}
                          </ElementInfoValue>
                        </ElementInfoItem>
                      </ElementInfo>
                    </CardInfo>
                  </Card>

                  <TablesWrapper>
                    <TableWrapper>
                      <TableHeading>
                        {t(
                          "elementDialog.tabs.summary.recentMatches.title",
                          "Recent matches"
                        )}
                      </TableHeading>
                      {lastThreeHistory.length === 0 ? (
                        <NoData>
                          {t(
                            "elementDialog.tabs.summary.recentMatches.noData",
                            "No recent matches"
                          )}
                        </NoData>
                      ) : (
                        <Panel>
                          <Table>
                            <thead>
                              <tr>
                                <StyledTableHeadOne>
                                  <Tooltip
                                    content={t(
                                      "elementDialog.tabs.summary.recentMatches.roundTooltip",
                                      "Round"
                                    )}
                                  >
                                    <TooltipLabel>
                                      {t(
                                        "elementDialog.tabs.summary.recentMatches.roundLabel",
                                        "RD"
                                      )}
                                    </TooltipLabel>
                                  </Tooltip>
                                </StyledTableHeadOne>
                                <th>
                                  {t(
                                    "elementDialog.tabs.summary.recentMatches.opponent",
                                    "Opp"
                                  )}
                                </th>
                                <StyledTableHeadTwo>
                                  {t(
                                    "elementDialog.tabs.summary.recentMatches.points",
                                    "Pts"
                                  )}
                                </StyledTableHeadTwo>
                              </tr>
                            </thead>
                            <tbody>
                              {lastThreeHistory.map((h: IElementHistory) => {
                                return (
                                  <tr key={h.fixture}>
                                    <TdBold>{h.round}</TdBold>
                                    <TdBold>
                                      <FixtureForElementWrap>
                                        <Badge
                                          team={teams[h.opponent_team]}
                                          isPresentation={true}
                                          sizes="25px"
                                        />
                                        (
                                        {h.was_home
                                          ? t(
                                              "elementDialog.tabs.history.homeShort",
                                              "H"
                                            )
                                          : t(
                                              "elementDialog.tabs.history.awayShort",
                                              "A"
                                            )}
                                        ){" "}
                                        {h.team_h_score !== null &&
                                          `${h.team_h_score} - ${h.team_a_score}`}
                                        {h.team_h_score === h.team_a_score ? (
                                          <DrawPrefix>
                                            {t(
                                              "elementDialog.tabs.history.draw",
                                              "D"
                                            )}
                                          </DrawPrefix>
                                        ) : h.team_h_score > h.team_a_score &&
                                          h.was_home ? (
                                          <WinPrefix>
                                            {t(
                                              "elementDialog.tabs.history.win",
                                              "W"
                                            )}
                                          </WinPrefix>
                                        ) : h.team_a_score > h.team_h_score &&
                                          !h.was_home ? (
                                          <WinPrefix>
                                            {t(
                                              "elementDialog.tabs.history.win",
                                              "W"
                                            )}
                                          </WinPrefix>
                                        ) : (
                                          <LosePrefix>
                                            {t(
                                              "elementDialog.tabs.history.loss",
                                              "L"
                                            )}
                                          </LosePrefix>
                                        )}
                                      </FixtureForElementWrap>
                                    </TdBold>
                                    <TdBold>{h.total_points}</TdBold>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </Panel>
                      )}
                    </TableWrapper>
                    <TableWrapper>
                      <TableHeading>
                        {t(
                          "elementDialog.tabs.summary.upcoming.title",
                          "Upcoming"
                        )}
                      </TableHeading>
                      {firstThreeFixtures.length === 0 ? (
                        <NoData>
                          {t(
                            "elementDialog.tabs.summary.upcoming.noData",
                            "No upcoming matches"
                          )}
                        </NoData>
                      ) : (
                        <Panel>
                          <Table>
                            <thead>
                              <tr>
                                <StyledTableHeadOne>
                                  <Tooltip
                                    content={t(
                                      "elementDialog.tabs.summary.upcoming.roundTooltip",
                                      "Round"
                                    )}
                                  >
                                    <TooltipLabel>
                                      {t(
                                        "elementDialog.tabs.summary.upcoming.roundLabel",
                                        "RD"
                                      )}
                                    </TooltipLabel>
                                  </Tooltip>
                                </StyledTableHeadOne>
                                <th>
                                  {t(
                                    "elementDialog.tabs.summary.upcoming.opponent",
                                    "Opp"
                                  )}
                                </th>
                                <StyledTableHeadTwo>
                                  {t(
                                    "elementDialog.tabs.summary.upcoming.date",
                                    "Date"
                                  )}
                                </StyledTableHeadTwo>
                              </tr>
                            </thead>
                            <tbody>
                              {firstThreeFixtures.map((fixture) =>
                                fixture.code ? (
                                  <tr key={fixture.code}>
                                    <TdBold>{fixture.event}</TdBold>

                                    <TdBold>
                                      <FixtureForElementWrap>
                                        <FixtureForElement
                                          showBadge={true}
                                          element={element}
                                          fixture={fixture}
                                          teamsById={teams}
                                        />
                                      </FixtureForElementWrap>
                                    </TdBold>

                                    <TdBold>
                                      {fixture.kickoff_time ? (
                                        <time dateTime={fixture.kickoff_time}>
                                          {formatRawAsLocalI18n(
                                            fixture.kickoff_time,
                                            dateLocales[i18n.language],
                                            "dd/MM/yy"
                                          )}
                                        </time>
                                      ) : (
                                        <Tooltip
                                          content={t(
                                            "elementDialog.tabs.summary.upcoming.tbcLong",
                                            "Date To Be Confirmed"
                                          )}
                                        >
                                          <span>
                                            <TooltipLabel>
                                              {t(
                                                "elementDialog.tabs.summary.upcoming.tbcShort",
                                                "TBC"
                                              )}
                                            </TooltipLabel>
                                          </span>
                                        </Tooltip>
                                      )}
                                    </TdBold>
                                  </tr>
                                ) : (
                                  <tr key={fixture.event!}>
                                    <TdBold>{fixture.event}</TdBold>
                                    <TdBold>
                                      {t(
                                        "elementDialog.tabs.summary.upcoming.none",
                                        "None"
                                      )}
                                    </TdBold>
                                    <TdBold />
                                  </tr>
                                )
                              )}
                            </tbody>
                          </Table>
                        </Panel>
                      )}
                    </TableWrapper>
                  </TablesWrapper>
                </Summary>
              </TabPanel>

              <TabPanel
                label={t("elementDialog.tabs.highlights.heading", "Highlights")}
                link="History"
              >
                {highlights.map((highlight: IHighlights) => (
                  <HighlightsProvider
                    key={highlight.url}
                    url={highlight.url}
                    numberOfHighlights={4}
                  />
                ))}
              </TabPanel>

              <TabPanel
                label={t("elementDialog.tabs.history.heading", "History")}
                link="History"
              >
                <Box p={3}>
                  <SubHeading>
                    {t("elementDialog.tabs.history.subHeading", "This Season")}
                  </SubHeading>
                </Box>
                <Box>
                  {history.length ? (
                    <Panel>
                      <ScrollTable className="ism-overflow-scroll">
                        <HistoryTable>
                          <thead>
                            <tr>
                              <th>
                                {t(
                                  "elementDialog.tabs.history.roundShort",
                                  "RD"
                                )}
                              </th>
                              <th>
                                {t(
                                  "elementDialog.tabs.history.opponentShort",
                                  "OPP"
                                )}
                              </th>
                              <th>
                                {t(
                                  "elementDialog.tabs.history.pointsShort",
                                  "PTS"
                                )}
                              </th>
                              {includedStats.map((s) => (
                                <th key={s.name}>
                                  <abbr title={s.label}>
                                    {s.label
                                      .split(" ")
                                      .map((w) => w[0])
                                      .join("")
                                      .toUpperCase()}
                                  </abbr>
                                </th>
                              ))}
                              <th>
                                {t(
                                  "elementDialog.tabs.history.netTransfersShort",
                                  "NT"
                                )}
                              </th>
                              <th>
                                {t(
                                  "elementDialog.tabs.history.selectedByShort",
                                  "SB"
                                )}
                              </th>
                              <th>kr</th>
                            </tr>
                          </thead>
                          <tbody>
                            {history.map((h) => (
                              <tr key={h.fixture}>
                                <td>{h.round}</td>
                                <td>
                                  {teams[h.opponent_team].short_name} (
                                  {h.was_home
                                    ? t(
                                        "elementDialog.tabs.history.homeShort",
                                        "H"
                                      )
                                    : t(
                                        "elementDialog.tabs.history.awayShort",
                                        "A"
                                      )}
                                  ){" "}
                                  {h.team_h_score !== null &&
                                    `${h.team_h_score} - ${h.team_a_score}`}
                                </td>
                                <td>{h.total_points}</td>
                                {includedStats.map((s) => (
                                  <td key={s.name}>{h[s.name]}</td>
                                ))}
                                <td>{h.transfers_balance}</td>
                                <td>{h.selected}</td>
                                <td>kr{integerToMoney(h.value, 10)}</td>
                              </tr>
                            ))}
                            <tr>
                              <td colSpan={2}>
                                {t(
                                  "elementDialog.tabs.history.totals",
                                  "Totals"
                                )}
                              </td>
                              <td>{historyTotals.total_points}</td>
                              {includedStats.map((s) => (
                                <td key={s.name}>{historyTotals[s.name]}</td>
                              ))}
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                            </tr>
                          </tbody>
                        </HistoryTable>
                      </ScrollTable>
                    </Panel>
                  ) : (
                    <Box px={3}>
                      <p>
                        {t(
                          "elementDialog.tabs.history.noData",
                          "Data will appear here once the season is underway."
                        )}
                      </p>
                    </Box>
                  )}
                </Box>

                {seasonHistory && seasonHistory.length > 0 && (
                  <>
                    <Box p={3}>
                      <SubHeading>
                        {t(
                          "elementDialog.tabs.history.previousSeasons",
                          "Previous Seasons"
                        )}
                      </SubHeading>
                    </Box>
                    <Box>
                      <Panel>
                        <ScrollTable>
                          <HistoryTable>
                            <thead>
                              <tr>
                                <th>
                                  {t(
                                    "elementDialog.tabs.history.season",
                                    "Season"
                                  )}
                                </th>
                                <th>
                                  <Tooltip
                                    content={t(
                                      "elementDialog.tabs.history.points",
                                      "Points"
                                    )}
                                  >
                                    <TooltipLabel>TP</TooltipLabel>
                                  </Tooltip>
                                </th>
                                {includedStats.map((s) => (
                                  <th key={s.name}>
                                    <Tooltip content={s.label}>
                                      <TooltipLabel>
                                        {s.label
                                          .split(" ")
                                          .map((w) => w[0])
                                          .join("")
                                          .toUpperCase()}
                                      </TooltipLabel>
                                    </Tooltip>
                                  </th>
                                ))}
                                <th>
                                  <abbr
                                    title={t(
                                      "elementDialog.tabs.history.priceAtStart",
                                      "Price at start of season"
                                    )}
                                  >
                                    krS
                                  </abbr>
                                </th>
                                <th>
                                  <abbr
                                    title={t(
                                      "elementDialog.tabs.history.priceAtEnd",
                                      "Price at end of season"
                                    )}
                                  >
                                    krE
                                  </abbr>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {seasonHistory.map((h) => (
                                <tr key={h.season_name}>
                                  <td>{h.season_name}</td>
                                  <td>{h.total_points}</td>
                                  {stats.map((s) => (
                                    <td key={s.name}>{h[s.name]}</td>
                                  ))}
                                  <td>kr{integerToMoney(h.start_cost, 10)}</td>
                                  <td>kr{integerToMoney(h.end_cost, 10)}</td>
                                </tr>
                              ))}
                            </tbody>
                          </HistoryTable>
                        </ScrollTable>
                      </Panel>
                    </Box>
                  </>
                )}
              </TabPanel>

              <TabPanel
                label={t("elementDialog.tabs.schedule.heading", "Schedule")}
                link="Schedule"
              >
                <Box p={3}>
                  <SubHeading>
                    {t("elementDialog.tabs.schedule.subHeading", "This Season")}
                  </SubHeading>
                </Box>
                <Box>
                  <Panel>
                    <Table>
                      <thead>
                        <tr>
                          <th>
                            {t("elementDialog.tabs.schedule.date", "Date")}
                          </th>
                          <th>
                            {t("elementDialog.tabs.schedule.roundShort", "RD")}
                          </th>
                          <th>
                            {t(
                              "elementDialog.tabs.schedule.opponent",
                              "Opponent"
                            )}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {fixtures.map((fixture) =>
                          fixture.code ? (
                            <tr key={fixture.code}>
                              <td>
                                {fixture.kickoff_time ? (
                                  <time dateTime={fixture.kickoff_time}>
                                    {formatRawAsLocal(fixture.kickoff_time)}
                                  </time>
                                ) : (
                                  <Tooltip
                                    content={t(
                                      "elementDialog.tabs.schedule.tbcLong",
                                      "Date To Be Confirmed"
                                    )}
                                  >
                                    <span>
                                      <TooltipLabel>
                                        {t(
                                          "elementDialog.tabs.schedule.tbcShort",
                                          "TBC"
                                        )}
                                      </TooltipLabel>
                                    </span>
                                  </Tooltip>
                                )}
                              </td>
                              <td>{fixture.event}</td>
                              <td>
                                <FixtureForElement
                                  element={element}
                                  fixture={fixture}
                                  teamsById={teams}
                                />
                              </td>
                            </tr>
                          ) : (
                            <tr key={fixture.event!}>
                              <td>&nbsp;</td>
                              <td>{fixture.event}</td>
                              <td>
                                {t("elementDialog.tabs.schedule.none", "None")}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </Panel>
                </Box>
              </TabPanel>
            </Tabs>
          </TabsWrapper>

          <Box mx={2} my={3}>
            {this.renderWatchlistButton()}
          </Box>
        </Dialog.Body>
      </Dialog>
    );
  }
}

export { ElementDialog as ElementDialogTest };

const mapStateToProps = (
  state: RootState,
  ownProps: IOwnProps
): IPropsFromState => ({
  currentEvent: getCurrentEvent(state),
  elementTypesById: getElementTypesById(state),
  fixtures: getFixturesWithBlanks(state, ownProps.element.id) as Array<
    IElementFixtureFPL | IElementFixtureBlank
  >,
  history: getHistory(state, ownProps.element.id),
  historyTotals: getHistoryTotals(state, ownProps.element.id),
  nextEvent: getNextEvent(state),
  player: getPlayerData(state),
  seasonHistory: getSeasonHistory(state, ownProps.element.id).slice().reverse(),
  stats: getElementStats(state),
  teamsById: getTeamsById(state),
  watched: getWatched(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  addToWatched: (elementCode) => dispatch(addToWatchlist(elementCode)),
  closeDialog: () => dispatch(elementDialogHide()),
  proposeElement: (elementId) => dispatch(proposeElement(elementId)),
  removeFromWatched: (elementCode) =>
    dispatch(removeFromWatchlist(elementCode)),
});

const ConnectedDialog = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ElementDialog)
);

/* tslint:disable:max-classes-per-file */
interface IContainerProps {
  element: IElement | undefined;
}

class ElementDialogContainer extends React.Component<IContainerProps> {
  public render() {
    const element = this.props.element;
    return element ? <ConnectedDialog element={element} /> : null;
  }
}

export default connect((state: RootState): IContainerProps => {
  const elementId = getElementDialog(state);
  return {
    element: getElement(state, elementId),
  };
})(ElementDialogContainer);
