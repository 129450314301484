import styled from "styled-components";
import PlaceholderImg from "../img/headshots/shirt_placeholder.png";

const Img = styled.img`
  display: block;
  margin: auto;
  max-width: 200px;
  width: 100%;
  height: auto;
`;

const PlaceholderShirt = () => {
  return <Img src={`${PlaceholderImg}`} alt="" role="presentation" />;
};

export default PlaceholderShirt;
