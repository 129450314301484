import * as React from "react";
import styled, { css } from "styled-components/macro";

const StyledAlert = styled.div<IStyleProps>`
  padding: 0.75rem 1.25rem;
  text-align: ${(props) => props.textAlign};
  border: 1px solid transparent;
  border-radius: ${({ noRadius }) => (noRadius ? 0 : ".25rem")};

  ${(props) =>
    props.type === "error" &&
    css`
      background-color: ${props.theme.colors.error};
      color: white;
    `}

  ${(props) =>
    props.type === "success" &&
    css`
      background-color: ${props.theme.colors.success};
    `}

  ${(props) =>
    props.type === "warning" &&
    css`
      background-color: #ffe65b;
    `}

  ${(props) =>
    props.type === "info" &&
    css`
      background-color: ${({ theme }) => theme.colors.blueDarker4};
      color: ${({ theme }) => theme.colors.white};
    `}

    ${(props) =>
    (props.copnr || props.copnr === 0) &&
    css`
      background-color: ${props.theme.colors.elementStatus[props.copnr].bg};
      color: ${props.theme.colors.elementStatus[props.copnr].color};
    `}
`;

export const AlertItem = styled.p`
  margin-top: ${(props) => props.theme.space[1]};
  margin-bottom: ${(props) => props.theme.space[1]};
  color: currentColor;
`;

export const AlertWrap = styled.div`
  margin: ${(props) => props.theme.space[2]};
`;

export const AlertGroup = styled.div`
  margin-top: ${({ theme }) => theme.space[3]};
  margin-bottom: ${({ theme }) => theme.space[3]};
`;

interface IStyleProps {
  copnr?: number | null;
  type: "error" | "success" | "warning" | "info" | "news";
  textAlign?: "left" | "center";
  noRadius?: boolean;
}

interface IProps extends IStyleProps {
  children: React.ReactNode;
}

const Alert: React.FC<IProps> = ({
  children,
  copnr = null,
  textAlign = "center",
  type,
  noRadius = false,
}) => (
  <StyledAlert
    copnr={copnr}
    type={type}
    textAlign={textAlign}
    noRadius={noRadius}
  >
    {children}
  </StyledAlert>
);

export default Alert;
