import { TFunction } from "i18next";
import { IElementStat } from "../core/store/element-stats/types";

interface IStatDetails {
  description: string;
  label: string;
}

const allStatsDetails = (
  t: TFunction
): Record<string, IStatDetails | undefined> => ({
  total_points: {
    description: t(
      "stats.total_points.description",
      "Total points earned this season."
    ),
    label: t("stats.total_points.label", "Total points"),
  },
  event_points: {
    description: t(
      "stats.round_points.description",
      "Total points earned in the latest round."
    ),
    label: t("stats.round_points.label", "Round points"),
  },
  now_cost: {
    description: t(
      "stats.now_cost.description",
      "Current buying price in the transfer market."
    ),
    label: t("stats.now_cost.label", "Price"),
  },
  selected_by_percent: {
    description: t(
      "stats.selected_by_percent.description",
      "The percentage of overall Fantasy managers who currently own the player."
    ),
    label: t("stats.selected_by_percent.label", "Team selected by %"),
  },
  minutes: {
    description: t(
      "stats.minutes.description",
      "Total minutes played this season."
    ),
    label: t("stats.minutes.label", "Minutes played"),
  },
  goals_scored: {
    description: t(
      "stats.goals_scored.description",
      "Total goals scored this season."
    ),
    label: t("stats.goals_scored.label", "Goals scored"),
  },
  assists: {
    description: t(
      "stats.assists.description",
      "Total goal assists – awarded to the player from the goalscoring team who makes the final pass before a goal is scored, including own goals."
    ),
    label: t("stats.assists.label", "Assists"),
  },
  clean_sheets: {
    description: t(
      "stats.clean_sheets.description",
      "Total clean sheets – awarded to players who do not concede a goal and have played at least 60 minutes."
    ),
    label: t("stats.clean_sheets.label", "Clean sheets"),
  },
  goals_conceded: {
    description: t(
      "stats.goals_conceded.description",
      "Total number of goals conceded by a team while the player has been on the pitch."
    ),
    label: t("stats.goals_conceded.label", "Goals conceded"),
  },
  own_goals: {
    description: t(
      "stats.own_goals.description",
      "Awarded to a player who puts the ball into his own team’s goal."
    ),
    label: t("stats.own_goals.label", "Own goals"),
  },
  penalties_saved: {
    description: t(
      "stats.penalties_saved.description",
      "Awarded to a goalkeeper who touches the ball when saving a penalty."
    ),
    label: t("stats.penalties_saved.label", "Penalties saved"),
  },
  penalties_missed: {
    description: t(
      "stats.penalties_missed.description",
      "Awarded to a player who takes a penalty but does not score from the penalty spot."
    ),
    label: t("stats.penalties_missed.label", "Penalties missed"),
  },
  yellow_cards: {
    description: t(
      "stats.yellow_cards.description",
      "Total yellow cards this season."
    ),
    label: t("stats.yellow_cards.label", "Yellow cards"),
  },
  red_cards: {
    description: t(
      "stats.red_cards.description",
      "Total red cards this season."
    ),
    label: t("stats.red_cards.label", "Red cards"),
  },
  saves: {
    description: t(
      "stats.saves.description",
      "Total times a goalkeeper has saved a shot on goal."
    ),
    label: t("stats.saves.label", "Saves"),
  },
  bonus: {
    description: t(
      "stats.bonus.description",
      "The three best performing players in each match will receive additional bonus points: 3 points will be awarded to the highest scoring player, 2 to the second best and 1 to the third. More information on this season's bonus system will be updated before the first round."
    ),
    label: t("stats.bonus.label", "Bonus"),
  },
  // opta_index: {
  //   description: t(
  //     "stats.opta_index.description",
  //     "The Opta Index provides player ratings for a specific match, based on totals in a variety of statistics."
  //   ),
  //   label: t("stats.opta_index.label", "Opta index"),
  // },
  form: {
    description: t(
      "stats.form.description",
      "Form is a player’s average score per match, calculated from all matches played by his club in the last 30 days."
    ),
    label: t("stats.form.label", "Form"),
  },
  dreamteam_count: {
    description: t(
      "stats.dreamteam_count.description",
      "The number of times a player has been selected in a Round Dream Team. Players with the most points in a Round in a valid formation earn a place in the Dream Team."
    ),
    label: t("stats.dreamteam_count.label", "Times in Dream Team"),
  },
  value_form: {
    description: t(
      "stats.value_form.description",
      "Player’s form divided by player’s value."
    ),
    label: t("stats.value_form.label", "Value (form)"),
  },
  value_season: {
    description: t(
      "stats.value_season.description",
      "Player’s total points divided by player’s value."
    ),
    label: t("stats.value_season.label", "Value (season)"),
  },
  points_per_game: {
    description: t(
      "stats.points_per_game.description",
      "Player’s total points divided by player’s number of matches."
    ),
    label: t("stats.points_per_game.label", "Points per match"),
  },
  transfers_in: {
    description: t(
      "stats.transfers_in.description",
      "Total number of times a player has been transferred in to a team this season."
    ),
    label: t("stats.transfers_in.label", "Transfers in"),
  },
  transfers_out: {
    description: t(
      "stats.transfers_out.description",
      "Total number of times a player has been transferred out of a team this season."
    ),
    label: t("stats.transfers_out.label", "Transfers out"),
  },
  transfers_in_event: {
    description: t(
      "stats.transfers_in_event.description",
      "Total number of times a player has been transferred in to a team this Round."
    ),
    label: t("stats.transfers_in_event.label", "Transfers in (round)"),
  },
  transfers_out_event: {
    description: t(
      "stats.transfers_out_event.description",
      "Total number of times a player has been transferred out of a team this Round."
    ),
    label: t("stats.transfers_out_event.label", "Transfers out (round)"),
  },
  cost_change_start: {
    description: t(
      "stats.cost_change_start.description",
      "How much a player’s price has increased since the start of the season."
    ),
    label: t("stats.cost_change_start.label", "Price rise"),
  },
  cost_change_start_fall: {
    description: t(
      "stats.cost_change_start_fall.description",
      "How much a player price has fallen since the start of the season."
    ),
    label: t("stats.cost_change_start_fall.label", "Price fall"),
  },
  cost_change_event: {
    description: t(
      "stats.cost_change_event.description",
      "How much a player’s price has increased this Round."
    ),
    label: t("stats.cost_change_event.label", "Price rise (round)"),
  },
  cost_change_event_fall: {
    description: t(
      "stats.cost_change_event_fall.description",
      "How much a player’s price has fallen this Round."
    ),
    label: t("stats.cost_change_event_fall.label", "Price fall (round)"),
  },
});

let _stats: Record<string, IStatDetails | undefined> = {};

const getStats = (t: TFunction) => {
  if (!Object.keys(_stats).length) {
    _stats = allStatsDetails(t);
  }
  return _stats;
};

export const getStatDetails = (name: string, t: TFunction) => {
  const stats = getStats(t);
  return stats[name] ? stats[name] : null;
};

export const getStatNames = (t: TFunction) => Object.keys(getStats(t));

export const excludedStats = ["opta_index"];

export const getIncludedStats = (stats: readonly IElementStat[]) =>
  stats.filter((stat) => !excludedStats.includes(stat.name));
