import { RouteComponentProps } from "@reach/router";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import styled from "styled-components/macro";
import { ReactComponent as TwitterLogo } from "../../img/icons/twitter.svg";
import { Main, Secondary, Wrapper } from "../Layout";
import SubHeading from "../SubHeading";
import Title from "../Title";
import ScoutNav from "./ScoutNav";
import ScoutProvider from "./ScoutProvider";

const TitleWrap = styled.div`
  margin: ${({ theme }) => `${theme.space[0]} ${theme.space[2]}`};
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    margin: ${({ theme }) =>
      `${theme.space[2]} ${theme.space[2]} ${theme.space[6]}`};
  }
`;
const ScoutWrap = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    width: 50%;
    padding: ${({ theme }) => theme.space[0]};
    margin-bottom: ${({ theme }) => theme.space[4]};
  }
`;

const StyledSubHeading = styled(SubHeading)`
  font-size: 2rem;
`;

const Social = styled.div`
  margin-bottom: ${({ theme }) => theme.space[2]};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.radii[0]};
  background: ${({ theme }) => theme.colors.backgroundwhite50};
`;

const TwitterTimelineWrap = styled.div`
  overflow: hidden;
  border-radius: ${({ theme }) => theme.radii[1]};
`;

const SocialHeader = styled.header`
  padding: 1rem;
`;

const SocialBody = styled.div`
  padding: ${({ theme }) => theme.space[1]};
`;

const SocialLinks = styled.ul`
  padding: ${({ theme }) => theme.space[1]};
  border-radius: 3px;
  margin-bottom: ${({ theme }) => theme.space[2]};
  background-color: white;
`;

const SocialLink = styled.a<ISocialLinkStyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
  border-radius: 2px;
  color: ${(props) => props.theme.colors.social[props.network].color};
  background-color: ${(props) => props.theme.colors.social[props.network].bg};
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;

const SocialLinkItem = styled.li`
  list-style-type: none;
  :not(:last-child) {
    margin-bottom: ${({ theme }) => theme.space[2]};
  }
`;

const SocialLinkText = styled.span`
  margin-left: 1rem;
  font-size: 1.2rem;
`;

interface ISocialLinkStyleProps {
  network: string;
}

interface IProvider {
  link: string;
  url: string;
  title: string;
}

type Props = RouteComponentProps;

interface ITwitterFeed {
  title: string;
  url: string;
  socialLinkText: string;
  linkText: string;
}

const twitterFeeds: ITwitterFeed[] = [
  {
    title: "TV 2 on Social",
    url: "https://twitter.com/tv2sport",
    socialLinkText: "To Kapteiner on X",
    linkText: "Posts by To Kapteiner",
  },
  {
    title: "Eliteserien on Social",
    url: "https://twitter.com/eliteserien",
    socialLinkText: "Eliteserien on X",
    linkText: "Posts by Eliteserien",
  },
];

const Scout: React.FC<Props> = () => {
  const { t } = useTranslation();
  const twitterFeedId = "twsrc%5Etfw";

  const providers: IProvider[] = [
    {
      url: "https://www.tv2.no/rest/cms-feeds-dw-rest/v2/cms/article/sport/fotball/fantasy",
      link: "https://www.tv2.no/sport/fotball/eliteserien",
      title: i18n.t("feed.providers.title1", "Latest Fantasy-news from TV 2"),
    },
    {
      url: "https://www.eliteserien.no/rss-nyheter",
      link: "https://www.eliteserien.no/nyheter",
      title: i18n.t("feed.providers.title2", "Latest news from Eliteserien"),
    },
  ];

  useEffect(() => {
    const twttr = (window as any).twttr;
    if (twttr && twttr.widgets) {
      twttr.widgets.load();
    }
  }, []);

  return (
    <>
      <Wrapper>
        <Main>
          <TitleWrap>
            <Title>{t("scout.title", "The Scout")}</Title>
          </TitleWrap>
          <ScoutWrap>
            <ScoutNav />
          </ScoutWrap>

          {providers.map((provider: IProvider) => (
            <ScoutProvider
              key={provider.url}
              link={provider.link}
              url={provider.url}
              title={provider.title}
              numberOfArticles={3}
            />
          ))}
        </Main>
        <Secondary>
          {twitterFeeds.map((feed: ITwitterFeed) => (
            <Social>
              <SocialHeader>
                <StyledSubHeading variant="dark">
                  {`${t(feed.title)}`}
                </StyledSubHeading>
              </SocialHeader>
              <SocialBody>
                <SocialLinks>
                  <SocialLinkItem>
                    <SocialLink
                      href={feed.url}
                      network="twitter"
                      target="_blank"
                    >
                      <TwitterLogo />
                      <SocialLinkText>
                        {`${t(feed.socialLinkText)}`}
                      </SocialLinkText>
                    </SocialLink>
                  </SocialLinkItem>
                </SocialLinks>
                <TwitterTimelineWrap>
                  <a
                    className="twitter-timeline"
                    data-height="900"
                    data-link-color="#19CF86"
                    data-chrome="noborders"
                    href={`${feed.url}?ref_src=${twitterFeedId}`}
                  >
                    {`${t(feed.linkText)}`}
                  </a>
                </TwitterTimelineWrap>
              </SocialBody>
            </Social>
          ))}
        </Secondary>
      </Wrapper>
    </>
  );
};

export default Scout;
