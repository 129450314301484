import { em } from "polished";
import styled from "styled-components/macro";

export const ScrollTable = styled.div`
  @media (max-width: ${em("480px")}) {
    overflow-x: auto;

    table {
      width: 48rem;
    }
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: ${({ theme }) => theme.fontSizes[2]};

  th,
  td {
    padding: ${({ theme }) => `${theme.space[1]} ${theme.space[2]}`};
  }

  th {
    color: ${({ theme }) => theme.colors.black};
    background: ${({ theme }) => theme.colors.white};

    :first-of-type {
      border-top-left-radius: ${({ theme }) => theme.radii[1]};
    }

    :last-of-type {
      border-top-right-radius: ${({ theme }) => theme.radii[1]};
    }

    text-align: left;
  }

  tr {
    background: ${({ theme }) => theme.colors.tableBlueLight};

    &:nth-child(odd) {
      background: ${({ theme }) => theme.colors.tableBlueDark};
    }
    border-top: 1px solid ${({ theme }) => theme.colors.white};

    :first-of-type {
      border: 0;
    }
  }

  tr:last-of-type td:first-of-type {
    border-bottom-left-radius: ${({ theme }) => theme.radii[0]};
  }

  tr:last-of-type td:last-of-type {
    border-bottom-right-radius: ${({ theme }) => theme.radii[0]};
  }
`;

export default Table;
