import React from "react";
import Badge from "./Badge";
import { IFixture } from "../core/store/fixtures/types";
import { IElement, IElementFixture } from "../core/store/elements/types";
import { useTranslation } from "react-i18next";
import { ITeamsById } from "../core/store/teams/types";

interface IProps {
  showBadge?: boolean;
  fixture: IFixture | IElementFixture;
  element: IElement;
  teamsById: ITeamsById;
}

const FixtureForElement: React.FC<IProps> = ({
  showBadge = false,
  fixture,
  element,
  teamsById,
}) => {
  const isHome = element.team === fixture.team_h;
  const { t } = useTranslation();
  return isHome ? (
    <>
      {showBadge && (
        <Badge
          team={teamsById[fixture.team_a]}
          sizes="23px"
          isPresentation={true}
        />
      )}
      <span>
        {teamsById[fixture.team_a].short_name}&nbsp;(
        {t("elementDialog.tabs.history.homeShort", "H")})
      </span>
    </>
  ) : (
    <>
      {showBadge && (
        <Badge
          team={teamsById[fixture.team_h]}
          sizes="23px"
          isPresentation={true}
        />
      )}
      <span>
        {teamsById[fixture.team_h].short_name}&nbsp;(
        {t("elementDialog.tabs.history.awayShort", "A")})
      </span>
    </>
  );
};

export default FixtureForElement;

interface IMultiProps {
  delimiter?: React.ReactNode;
  fixtures: IFixture[];
  element: IElement;
  teamsById: ITeamsById;
}

export const FixturesForElement: React.FC<IMultiProps> = ({
  delimiter = ", ",
  fixtures,
  element,
  teamsById,
}) => {
  const fragments = fixtures.map((f) => (
    <FixtureForElement
      element={element}
      fixture={f}
      teamsById={teamsById}
      key={f.id}
    />
  ));
  return fragments.length
    ? fragments.reduce((result, item) => (
        <>
          {result}
          {delimiter}
          {item}
        </>
      ))
    : null;
};
