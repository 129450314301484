import { RouteComponentProps } from "@reach/router";
import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import styled from "styled-components/macro";
import * as allauth from "../../utils/allauth";
import { logout } from "../../core/store/player/thunks";
import { RootState } from "../../core/store";
import { getPlayerData } from "../../core/store/player/reducers";
import { Box } from "rebass/styled-components";
import { useTranslation } from "react-i18next";

const LogoutContainer = styled(Box)`
  margin-top: 6rem;
  margin-bottom: 3rem;
  padding: 3rem;
  background-color: #f2f9fa;
  text-align: center;
`;

const mapStateToProps = (state: RootState) => ({
  player: getPlayerData(state),
});

const mapDispatch = {
  logout: () => logout(),
};

const connector = connect(mapStateToProps, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Logout: React.FC<RouteComponentProps & PropsFromRedux> = ({
  logout,
  player,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    const performLogout = async () => {
      try {
        // First log out from the game
        await logout();

        // Check if we need to logout from TV2 by checking
        // if the player was authenticated with social auth
        const auth = await allauth.getAuth();
        console.log("auth state: ", auth);
        console.log("auth state:", JSON.stringify(auth, null, 2));
        if (
          auth &&
          auth.data?.user &&
          auth.data.user.socialaccounts?.length > 0
        ) {
          // User was logged in with social auth, we need to logout from TV2
          await allauth.logout();

          // Redirect to the TV2 logout endpoint to complete SSO logout
          // This URL should be configured in your TV2 settings
          const returnTo = encodeURIComponent(window.location.origin);
          const auth0Domain =
            process.env.REACT_APP_AUTH0_DOMAIN || "https://id-stage.tv2.no";
          window.location.href = `https://${auth0Domain}/v2/logout?client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}&returnTo=${returnTo}`;
        } else {
          // Regular logout, just redirect to home
          window.location.replace("/");
        }
      } catch (error) {
        console.error("Logout error:", error);
        // If there's an error, just redirect to home
        window.location.replace("/");
      }
    };

    performLogout();
  }, [logout]);

  return (
    <LogoutContainer>
      <h2>{t("logout.title", "Signing Out")}</h2>
      <p>{t("logout.message", "Please wait while we sign you out...")}</p>
    </LogoutContainer>
  );
};

export default connector(Logout);
