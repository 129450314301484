import * as React from "react";
import styled from "styled-components/macro";

const StyledPanel = styled.div`
  margin: 0;
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.radii[1]};
  background-color: transparent;
`;

const StyledPanelHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.space[4]} ${({ theme }) => theme.space[3]};
  border-top-left-radius: ${({ theme }) => theme.radii[0]};
  border-top-right-radius: ${({ theme }) => theme.radii[0]};
  background: ${({ theme }) => theme.colors.bgWhiteOpacity50};
`;

const PanelHeading = styled.h3`
  flex: 1;
  margin: 0;
  font-size: 2rem;
  font-family: ${({ theme }) => theme.fonts.impact};
  line-height: 1;
`;

const StyledPanelFooter = styled.div`
  padding: ${({ theme }) => theme.space[1]} ${({ theme }) => theme.space[2]};
  text-align: left;
`;

interface IPanelHeaderProps {
  title?: React.ReactNode;
  children?: React.ReactNode;
}

const PanelHeader: React.FC<IPanelHeaderProps> = ({ children, title }) => (
  <StyledPanelHeader>
    <PanelHeading>{title}</PanelHeading>
    {children}
  </StyledPanelHeader>
);

interface IPanelBodyProps {
  children: React.ReactNode;
}

const PanelBody: React.FC<IPanelBodyProps> = ({ children }) => (
  <div>{children}</div>
);

interface IPanelFooterProps {
  children: React.ReactNode;
}

const PanelFooter: React.FC<IPanelFooterProps> = ({ children }) => (
  <StyledPanelFooter>{children}</StyledPanelFooter>
);

interface IPanelProps {
  children?: React.ReactNode;
}

class Panel extends React.Component<IPanelProps> {
  public static Header = PanelHeader;
  public static Body = PanelBody;
  public static Footer = PanelFooter;

  render() {
    const { children = true } = this.props;
    return (
      <>
        <StyledPanel>{children}</StyledPanel>
      </>
    );
  }
}

export default Panel;
