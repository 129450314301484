import { Link as RouterLink } from "@reach/router";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled, { css } from "styled-components/macro";
import { RootState, ThunkDispatch } from "../core/store";
import {
  getEntry,
  getFanLeagueDetails,
  getPrivateClassicCupLeaguesForEntry,
  getPrivateClassicLeaguesForEntry,
  getPrivateH2HLeaguesForEntry,
  getPublicClassicCupLeaguesForEntry,
  getPublicClassicLeaguesForEntry,
  getPublicH2HLeaguesForEntry,
  getSystemClassicCupLeaguesForEntry,
  getSystemClassicLeaguesForEntry,
} from "../core/store/entries/reducers";
import { fetchEntrySummary } from "../core/store/entries/thunks";
import {
  IEntry,
  IFanLeagueDetails,
  ILeagueEntry,
} from "../core/store/entries/types";
import { getCurrentEvent } from "../core/store/events/reducers";
import { IEvent } from "../core/store/events/types";
import { getTotalPlayers } from "../core/store/game/reducers";
import { fetchMyTeam } from "../core/store/my-team/thunks";
import { ITransferState } from "../core/store/my-team/types";
import { getPlayerData } from "../core/store/player/reducers";
import { getTransferState } from "../core/store/squad/reducers";
import { getTeamsById } from "../core/store/teams/reducers";
import { ITeamsById } from "../core/store/teams/types";
import { getURL } from "../core/store/urls/reducers";
import { fetchURL } from "../core/store/urls/thunks";
import { IContent } from "../core/store/urls/types";
import { integerToMoney } from "../core/utils/money";
import Badge from "./Badge";
import BoldLink from "./BoldLink";
import Button from "./Button";
import Footnote from "./Footnote";
import { ControlArrowRight } from "./icons/Arrows";
import Movement from "./leagues/Movement";
import MyLeagueCupSummary from "./leagues/MyLeagueCupSummary";
import { LeagueSummaryTable } from "./leagues/Styles";
import {
  getBroadcasterLeagues,
  getFilteredSystemClassicLeagues,
} from "./leagues/utils";
import Link from "./Link";
import LogoBar from "./LogoBar";
import MedRecAd from "./MedRecAd";
import Panel from "./Panel";
import filterOnSSL from "./scout/filterOnSSL";
import SubHeading from "./SubHeading";
import TabHeading from "./TabHeading";
import Table from "./Table";
import EntryTabs from "./tabs/EntryTabs";
import TabPanel from "./tabs/TabPanel";
import { VisuallyHidden } from "./Utils";

const Parser = require("rss-parser");
const parser = new Parser();

const StyledLogoBar = styled(LogoBar)`
  background-color: ${({ theme }) => theme.colors.backgroundwhite50};
`;

const TableStyles = css`
  > tbody > tr {
    line-height: ${({ theme }) => theme.space[6]};
  }
`;

const StyledTable = styled(Table)`
  ${TableStyles};
`;

const StyledSubHeading = styled(SubHeading)`
  margin: auto 0;
  overflow-wrap: anywhere;
  font-size: 1.6rem;
`;

const EntryName = styled.h2`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1.7rem;
  margin-top: 0;
  margin-bottom: ${({ theme }) => `${theme.space[1]}`};
`;

const DataListItem = styled.li`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.space[2]} 0;
  border-top: 1px solid ${({ theme }) => theme.colors.grey};
  font-size: ${({ theme }) => theme.fontSizes[1]};
`;

const DataListHeading = styled.h5`
  flex: 1 0 65%;
  padding: 0 ${({ theme }) => theme.space[2]};
`;

const DataListValue = styled.div`
  flex: 1 0 35%;
  padding: 0 ${({ theme }) => theme.space[2]};
`;

const MovementCell = styled.td`
  width: 16%;
`;

const Rank = styled.td`
  width: 30%;
  font-weight: 800;
`;

const Name = styled.td`
  width: 54%;
  overflow-wrap: break-word;
  hyphens: auto;
`;

const linkToLeague = (l: ILeagueEntry) =>
  `/leagues/${l.id}/standings/${l.scoring}`;

const BoldText = styled.b``;

const StyledTdFixedWidth = styled.td`
  width: 33%;
`;
const StyledLink = styled(Link)`
  ${BoldText}
`;

const RSSArticleTitle = styled.h4`
  padding-top: 10px;
  margin: 0;
  font-size: 14px;
  line-height: 1;
`;

const RSSArticle = styled.article`
  padding: ${({ theme }) => theme.space[2]};
  box-sizing: border-box;
  background-color: transparent;
  text-decoration: none;
  color: #000;
  &:hover {
    background-color: ${({ theme }) => theme.colors.blueDarker3};
    ${RSSArticleTitle} {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;
const RSSArticleLink = styled.a`
  color: #000;
  text-decoration: none;
`;

const RSSArticleFigure = styled.figure`
  margin: 0;
  object-fit: contain;
  width: 100%;
`;

const RSSArticleImg = styled.img`
  max-width: 100%;
`;

const RSSArticleDesc = styled.p`
  margin-bottom: 0;
`;

interface IRowProps {
  leagueEntry: ILeagueEntry;
}

interface ILeagueSummaryProps {
  title: React.ReactNode;
  leagues: ILeagueEntry[];
}

const MovementHead = styled.th`
  width: 16%;
`;

const RankHead = styled.th`
  width: 30%;
`;

const NameHead = styled.th`
  width: 54%;
`;

const providerUrls: { [key: string]: string } = {
  8: "http://www.glimt.no/rss-nyheter",
  1: "https://www.brann.no/rss-nyheter",
  40: "http://www.fredrikstadfk.no/rss-nyheter",
  50: "https://www.hamkam.no/rss-nyheter",
  16: "https://www.fkh.no/rss-nyheter",
  1438: "https://www.kaaffa.no/rss-nyheter",
  2718: "https://www.kristiansundbk.no/rss-nyheter",
  12: "http://www.lsk.no/rss-nyheter",
  6: "http://www.moldefk.no/rss-nyheter",
  14: "http://www.odd.no/rss-nyheter",
  5: "http://www.rbk.no/rss-nyheter",
  48: "https://www.sandefjordfotball.no/rss-nyheter",
  5043: "http://www.sarpsborg08.no/rss-nyheter",
  9: "http://www.godset.no/rss-nyheter",
  3: "http://www.til.no/rss-nyheter",
  7: "http://www.viking-fk.no/rss-nyheter",
  46: "http://www.aafk.no/rss-nyheter",
  13: "http://www.brynefk.no/rss-nyheter",
  1370: "http://eikfotball.no/rss-nyheter",
  53: "http://www.kil.no/rss-nyheter",
  405: "http://www.levangerfk.no/rss-nyheter",
  11: "https://lyn1896.no/rss-nyheter",
  89: "http://www.miffotball.no/rss-nyheter",
  2: "http://www.mossfk.no/rss-nyheter",
  302: "http://www.ranheimfotball.no/rss-nyheter",
  52: "https://www.raufossfotball.no/rss-nyheter",
  103: "http://www.sandnesulf.no/rss-nyheter",
  10: "https://www.sogndalfotball.no/rss-nyheter",
  4: "http://www.stabak.no/rss-nyheter",
  37: "https://www.ikstart.no/rss-nyheter",
  15: "http://www.vif-fotball.no/rss-nyheter",
  98: "http://www.asanefotball.no/rss-nyheter",
};

interface IPropsFromState {
  entry: IEntry | null;
  mine: Boolean;
  now: IEvent | null;
  privateClassicLeagues: ILeagueEntry[];
  privateH2HLeagues: ILeagueEntry[];
  publicClassicLeagues: ILeagueEntry[];
  publicH2HLeagues: ILeagueEntry[];
  systemClassicLeagues: ILeagueEntry[];
  teamsById: ITeamsById;
  totalPlayers: number;
  transfersState: ITransferState | null;
  fanLeagueRssObj: IContent | null;
  fanLeagueDetails: IFanLeagueDetails | null;
  privateClassicCupLeagues: ILeagueEntry[];
  publicClassicCupLeagues: ILeagueEntry[];
  systemClassicCupLeagues: ILeagueEntry[];
}

interface IPropsFromDispatch {
  fetchEntrySummary: (entryId: number) => void;
  fetchMyTeam: () => void;
  fetchUrl: (url: string) => void;
}

interface IOwnProps {
  entryId: number;
}

type Props = IPropsFromState & IPropsFromDispatch & IOwnProps & WithTranslation;

class Entry extends React.Component<Props, IState> {
  public state: IState = { favTeamArticle: null };

  public componentDidMount() {
    const { entry, fanLeagueDetails } = this.props;
    if (entry && fanLeagueDetails) {
      // If entry && fanLeagueDetails - Fetch the RSS content for fan league team
      this.props.fetchUrl(providerUrls[fanLeagueDetails.team.code]);
    }

    if (this.props.mine && !this.props.transfersState) {
      this.props.fetchMyTeam();
    }
    if (!this.props.entry) {
      this.props.fetchEntrySummary(this.props.entryId);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.fanLeagueRssObj !== this.props.fanLeagueRssObj) {
      this.handleParseContent(this.props.fanLeagueRssObj);
    }
  }

  handleParseContent = async (rss: any) => {
    try {
      const rssFeed = await parser.parseString(rss.text);
      const lastItem = filterOnSSL(rssFeed.items)[0];
      this.setState({
        favTeamArticle: lastItem,
      });
    } catch (err) {}
  };

  public render() {
    const {
      entry,
      privateClassicLeagues,
      publicClassicLeagues,
      privateH2HLeagues,
      publicH2HLeagues,
      systemClassicLeagues,
      totalPlayers,
      transfersState,
      mine = false,
      t,
      fanLeagueDetails,
      privateClassicCupLeagues,
      publicClassicCupLeagues,
      systemClassicCupLeagues,
    } = this.props;

    const { favTeamArticle } = this.state;

    if (!entry) {
      return null;
    }

    // Split broadcaster leagues out from system classic leagues
    const broadcasterLeagues = getBroadcasterLeagues(systemClassicLeagues);
    const filteredSystemClassicLeagues = getFilteredSystemClassicLeagues(
      systemClassicLeagues,
      broadcasterLeagues
    );

    const hasCupLeagues =
      privateClassicCupLeagues.length > 0 ||
      publicClassicCupLeagues.length > 0 ||
      systemClassicCupLeagues.length > 0;

    // Split broadcaster cup leagues out from system classic cup leagues
    const broadcasterCupLeagues = getBroadcasterLeagues(
      systemClassicCupLeagues
    );
    const filteredSystemCupLeagues = getFilteredSystemClassicLeagues(
      systemClassicCupLeagues,
      broadcasterCupLeagues
    );
    const LeagueSummary: React.FC<ILeagueSummaryProps> = ({
      leagues,
      title,
    }) => (
      <div>
        <TabHeading hasBorder={true} title={title} />
        <LeagueSummaryTable>
          <thead>
            <tr>
              <MovementHead scope="col">
                <VisuallyHidden>
                  {t("myTeam.leagueSummary.movement", "Movement")}
                </VisuallyHidden>
              </MovementHead>
              <RankHead scope="col">
                {t("myTeam.leagueSummary.rank", "Rank")}
              </RankHead>
              <NameHead scope="col">
                {t("myTeam.leagueSummary.league", "League")}
              </NameHead>
            </tr>
          </thead>
          <tbody>
            {leagues.map((l) => (
              <LeagueSummaryRow key={l.id} leagueEntry={l} />
            ))}
          </tbody>
        </LeagueSummaryTable>
      </div>
    );

    const LeagueSummaryRow: React.FC<IRowProps> = ({ leagueEntry }) => (
      <tr>
        <MovementCell>
          <Movement
            lastRank={leagueEntry.entry_last_rank}
            rank={leagueEntry.entry_rank}
          />
        </MovementCell>
        <Rank>
          {leagueEntry.entry_rank
            ? leagueEntry.entry_rank.toLocaleString()
            : null}
        </Rank>
        <Name>
          <StyledLink to={linkToLeague(leagueEntry)}>
            {leagueEntry.name}
          </StyledLink>
        </Name>
      </tr>
    );

    return (
      <>
        <MedRecAd />
        <Box px={2}>
          <EntryName>
            {entry.player_first_name} {entry.player_last_name}
          </EntryName>

          <StyledLogoBar justifyContent="center">
            <StyledSubHeading variant="dark">{entry.name}</StyledSubHeading>
          </StyledLogoBar>

          <Box mt={2} pb={4}>
            <Panel>
              <Panel.Header
                title={t("myTeam.pointsRankings", "Points/Rankings")}
              />
              <Panel.Body>
                <StyledTable>
                  <tbody>
                    <tr>
                      <td>{t("myTeam.overallPoints", "Overall Points")}:</td>
                      <td>
                        <BoldText>{entry.summary_overall_points}</BoldText>
                      </td>
                    </tr>
                    <tr>
                      <td>{t("myTeam.overallRank", "Overall Rank")}:</td>
                      <td>
                        <BoldText>
                          {entry.summary_overall_rank &&
                            entry.summary_overall_rank.toLocaleString()}
                        </BoldText>
                      </td>
                    </tr>
                    <tr>
                      <td>{t("myTeam.totalPlayers", "Total Players")}:</td>
                      <td>
                        <BoldText>{totalPlayers.toLocaleString()}</BoldText>
                      </td>
                    </tr>
                    <tr>
                      <td>{t("myTeam.roundPoints", "Round Points")}:</td>
                      <td>
                        <BoldText>{entry.summary_event_points}</BoldText>
                      </td>
                    </tr>
                  </tbody>
                </StyledTable>
              </Panel.Body>
              <Panel.Footer>
                <BoldLink to={`/entry/${entry.id}/history`}>
                  {t("myTeam.viewRoundHistory", "View Round history")}
                </BoldLink>
              </Panel.Footer>
            </Panel>
          </Box>

          {fanLeagueDetails && favTeamArticle && (
            <Box pb={4}>
              <Panel>
                <Panel.Header title={t("myTeam.fanLeague", "Fan League")}>
                  <Badge
                    team={fanLeagueDetails.team}
                    isPresentation={true}
                    sizes="32px"
                  />
                </Panel.Header>
                <Panel.Body>
                  <RSSArticle>
                    <RSSArticleLink
                      href={favTeamArticle.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <RSSArticleFigure>
                        {favTeamArticle.enclosure && (
                          <RSSArticleImg
                            src={favTeamArticle.enclosure.url}
                            alt=""
                          />
                        )}
                      </RSSArticleFigure>
                      <RSSArticleTitle>{favTeamArticle.title}</RSSArticleTitle>
                      <RSSArticleDesc>
                        {favTeamArticle.description}
                      </RSSArticleDesc>
                    </RSSArticleLink>
                  </RSSArticle>
                </Panel.Body>
                <Panel.Footer>
                  <BoldLink to={linkToLeague(fanLeagueDetails.league)}>
                    {t("View {{team}} Fan League", {
                      team: fanLeagueDetails.team.name,
                    })}
                  </BoldLink>
                </Panel.Footer>
              </Panel>
            </Box>
          )}
          <Box pb={4}>
            <Panel>
              <Panel.Header title={t("myTeam.leaguesCups", "Leagues & Cups")} />
              <Panel>
                <EntryTabs>
                  <TabPanel
                    label={t("myTeam.leaguesTab", "Leagues")}
                    link="leagues"
                  >
                    {broadcasterLeagues.length > 0 && (
                      <LeagueSummary
                        title={t("myTeam.broadcaster", "Broadcaster League")}
                        leagues={broadcasterLeagues}
                      />
                    )}
                    {privateClassicLeagues.length > 0 && (
                      <LeagueSummary
                        title={t(
                          "myTeam.privateClassic",
                          "Private Classic Leagues"
                        )}
                        leagues={privateClassicLeagues}
                      />
                    )}
                    {privateH2HLeagues.length > 0 && (
                      <LeagueSummary
                        title={t(
                          "myTeam.privateH2H",
                          "Private Head-to-Head Leagues"
                        )}
                        leagues={privateH2HLeagues}
                      />
                    )}
                    {publicClassicLeagues.length > 0 && (
                      <LeagueSummary
                        title={t(
                          "myTeam.publicClassic",
                          "Public Classic Leagues"
                        )}
                        leagues={publicClassicLeagues}
                      />
                    )}
                    {publicH2HLeagues.length > 0 && (
                      <LeagueSummary
                        title={t(
                          "myTeam.publicH2H",
                          "Public Head-to-Head Leagues"
                        )}
                        leagues={publicH2HLeagues}
                      />
                    )}
                    <LeagueSummary
                      title={t("myTeam.general", "General Leagues")}
                      leagues={filteredSystemClassicLeagues}
                    />
                  </TabPanel>
                  <TabPanel label="Cups" link="cups">
                    {hasCupLeagues ? (
                      <>
                        {privateClassicCupLeagues.length > 0 && (
                          <MyLeagueCupSummary
                            entryId={entry.id}
                            leagues={privateClassicCupLeagues}
                            title={t("myTeam.leagueCups", "League cups")}
                          />
                        )}
                        {publicClassicCupLeagues.length > 0 && (
                          <MyLeagueCupSummary
                            entryId={entry.id}
                            leagues={publicClassicCupLeagues}
                            title={t("myTeam.publicCups", "Public cups")}
                          />
                        )}
                        {filteredSystemCupLeagues.length > 0 && (
                          <MyLeagueCupSummary
                            entryId={entry.id}
                            leagues={filteredSystemCupLeagues}
                            title={t("myTeam.generalCups", "General cups")}
                          />
                        )}
                        {broadcasterCupLeagues.length > 0 && (
                          <MyLeagueCupSummary
                            entryId={entry.id}
                            leagues={broadcasterCupLeagues}
                            title={t(
                              "myTeam.broadcasterCups",
                              "Broadcaster cups"
                            )}
                          />
                        )}
                      </>
                    ) : (
                      <Box m={2}>
                        <p>
                          {t(
                            "myTeam.none",
                            "None of your leagues have a cup yet."
                          )}
                        </p>
                      </Box>
                    )}
                  </TabPanel>
                </EntryTabs>
              </Panel>
            </Panel>
          </Box>
          <Box pb={4}>
            <Button
              size="xs"
              width={1}
              variant="secondary"
              to="/leagues"
              as={RouterLink}
              endIcon={<ControlArrowRight />}
            >
              {t("myTeam.createAndJoinLeagues", "Create and join leagues")}
            </Button>
          </Box>

          {mine && transfersState && (
            <Box pb={4}>
              <Panel>
                <Panel.Header
                  title={t(
                    "myTeam.transfersAndFinance",
                    "Transfers And Finance"
                  )}
                />
                <Panel.Body>
                  <StyledTable>
                    <tbody>
                      <tr>
                        <td>
                          {t("myTeam.roundTransfers", "Round transfers")}:
                        </td>
                        <StyledTdFixedWidth>
                          <BoldText>{transfersState.made}</BoldText>
                        </StyledTdFixedWidth>
                      </tr>
                      <tr>
                        <td>
                          {t("myTeam.totalTransfers", "Total transfers")}:
                        </td>
                        <StyledTdFixedWidth>
                          <BoldText>
                            {entry.last_deadline_total_transfers +
                              transfersState.made}
                          </BoldText>
                        </StyledTdFixedWidth>
                      </tr>
                    </tbody>
                  </StyledTable>
                </Panel.Body>
                <Panel.Footer>
                  <BoldLink to={`/entry/${entry.id}/transfers`}>
                    {t("myTeam.viewTransferHistory", "View transfer history")}
                  </BoldLink>
                </Panel.Footer>
                <Panel.Body>
                  <StyledTable>
                    <tbody>
                      <tr>
                        <td>{t("myTeam.squadValue", "Squad value")}:</td>
                        <StyledTdFixedWidth>
                          <BoldText>
                            {integerToMoney(transfersState.value, 10)}
                          </BoldText>
                        </StyledTdFixedWidth>
                      </tr>
                      <tr>
                        <td>{t("myTeam.inTheBank", "In the bank")}:</td>
                        <StyledTdFixedWidth>
                          <BoldText>
                            {integerToMoney(transfersState.bank, 10)}
                          </BoldText>
                        </StyledTdFixedWidth>
                      </tr>
                    </tbody>
                  </StyledTable>
                </Panel.Body>
              </Panel>
            </Box>
          )}
          {!mine && (
            <Box pb={4}>
              <Panel>
                <Panel.Header
                  title={
                    <>
                      {t(
                        "myTeam.transfersAndFinance2",
                        "Transfers and Finance"
                      )}{" "}
                      <sup>*</sup>
                    </>
                  }
                />
                <Panel.Body>
                  <p />
                  <ul>
                    <DataListItem>
                      <DataListHeading>
                        {t("myTeam.totalTransfers", "Total transfers")}:
                      </DataListHeading>
                      <DataListValue>
                        {entry.last_deadline_total_transfers}
                      </DataListValue>
                    </DataListItem>
                  </ul>
                </Panel.Body>
                <Panel.Footer>
                  <BoldLink to={`/entry/${entry.id}/transfers`}>
                    {t("myTeam.viewTransferHistory", "View transfer history")}
                  </BoldLink>
                </Panel.Footer>
                <Panel.Body>
                  <ul>
                    <DataListItem>
                      <DataListHeading>
                        {t("myTeam.squadValue", "Squad value")}:
                      </DataListHeading>
                      <DataListValue>
                        {entry.last_deadline_value !== null &&
                        entry.last_deadline_bank !== null
                          ? `kr${integerToMoney(
                              entry.last_deadline_value -
                                entry.last_deadline_bank,
                              10
                            )}`
                          : "-"}
                      </DataListValue>
                    </DataListItem>
                    <DataListItem>
                      <DataListHeading>
                        {t("myTeam.inTheBank", "In the bank")}:
                      </DataListHeading>
                      <DataListValue>
                        {entry.last_deadline_bank !== null
                          ? `kr${integerToMoney(entry.last_deadline_bank, 10)}`
                          : "-"}
                      </DataListValue>
                    </DataListItem>
                  </ul>
                  <Box mt={1} px={2}>
                    <Footnote>
                      {t("myTeam.footNote", "* Values at the last deadline")}
                    </Footnote>
                  </Box>
                </Panel.Body>
              </Panel>
            </Box>
          )}
          {mine && (
            <Box pb={4}>
              <Panel>
                <Panel.Header title={t("myTeam.admin", "Admin")} />
                <Panel.Body>
                  {mine && (
                    <Box p={1}>
                      <Box p={1}>
                        <Button
                          width={1}
                          size="xs"
                          variant="secondary"
                          as={RouterLink}
                          to={`/entry-update`}
                          endIcon={<ControlArrowRight />}
                        >
                          {t("teamDetails", "Team details")}
                        </Button>
                      </Box>
                      <Box p={1}>
                        <Button
                          width={1}
                          size="xs"
                          variant="secondary"
                          to="/player"
                          as={RouterLink}
                          endIcon={<ControlArrowRight />}
                        >
                          {t("myTeam.userProfile", "User profile")}
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Panel.Body>
              </Panel>
            </Box>
          )}
        </Box>
      </>
    );
  }
}

export { Entry as EntryTest };

interface IState {
  favTeamArticle: any | null;
}

const mapStateToProps = (
  state: RootState,
  ownProps: IOwnProps
): IPropsFromState => {
  const player = getPlayerData(state);
  const entryId = ownProps.entryId;

  const fanLeagueDetails = getFanLeagueDetails(state, entryId);
  const fanLeagueUrl = fanLeagueDetails
    ? providerUrls[fanLeagueDetails.team.code]
    : null;
  const fanLeagueRssObj = fanLeagueUrl ? getURL(state, fanLeagueUrl) : null;

  return {
    entry: getEntry(state, entryId),
    mine: Boolean(player && player.entry && player.entry === entryId),
    now: getCurrentEvent(state),
    privateClassicLeagues: getPrivateClassicLeaguesForEntry(state, entryId),
    privateClassicCupLeagues: getPrivateClassicCupLeaguesForEntry(
      state,
      entryId
    ),
    privateH2HLeagues: getPrivateH2HLeaguesForEntry(state, entryId),
    publicClassicLeagues: getPublicClassicLeaguesForEntry(state, entryId),
    publicH2HLeagues: getPublicH2HLeaguesForEntry(state, entryId),
    systemClassicLeagues: getSystemClassicLeaguesForEntry(state, entryId),
    teamsById: getTeamsById(state),
    transfersState: getTransferState(state),
    totalPlayers: getTotalPlayers(state),
    fanLeagueRssObj,
    fanLeagueDetails,
    publicClassicCupLeagues: getPublicClassicCupLeaguesForEntry(state, entryId),
    systemClassicCupLeagues: getSystemClassicCupLeaguesForEntry(state, entryId),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchEntrySummary: (entryId) => dispatch(fetchEntrySummary(entryId)),
  fetchMyTeam: () => dispatch(fetchMyTeam()),
  fetchUrl: (url) => dispatch(fetchURL(url)),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Entry)
);
