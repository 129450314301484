import { Link as ReachLink, RouteComponentProps } from "@reach/router";
import range from "lodash/range";
import { hideVisually, size } from "polished";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled, { css } from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import {
  getElementsById,
  getElementsEventDataById,
} from "../../core/store/elements/reducers";
import { fetchEventLive } from "../../core/store/elements/thunks";
import {
  IElementsById,
  IElementsEventDataById,
} from "../../core/store/elements/types";
import {
  getEntry,
  getEntryEventFormation,
  getEntryEventPicks,
  getEntryEventPoints,
} from "../../core/store/entries/reducers";
import {
  fetchEntryEventPicks,
  fetchEntrySummary,
} from "../../core/store/entries/thunks";
import {
  IEntry,
  IEntryEvent,
  IPickLight,
} from "../../core/store/entries/types";
import {
  getCurrentEvent,
  getEventsById,
} from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import { getFixturesForEventById } from "../../core/store/fixtures/reducers";
import { fetchFixtures } from "../../core/store/fixtures/thunks";
import { IFixture } from "../../core/store/fixtures/types";
import { getSettings } from "../../core/store/game/reducers";
import { ISettings } from "../../core/store/game/types";
import { getPlayerData } from "../../core/store/player/reducers";
import { IPlayer } from "../../core/store/player/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeamsById } from "../../core/store/teams/types";
import { ReactComponent as BaseDreamTeam } from "../../img/icons/dreamteam.svg";
import { ReactComponent as BaseReload } from "../../img/icons/reload.svg";
import { ChipName, getChipName } from "../../utils/chips";
import ButtonLink from "../ButtonLink";
import Entry from "../Entry";
import Fixtures from "../Fixtures";
import { ControlArrowLeft, ControlArrowRight } from "../icons/Arrows";
import DreamTeam from "../icons/DreamTeam";
import { Main, Secondary, Wrapper } from "../Layout";
import Link from "../Link";
import {
  EventPager,
  PagerButton,
  PagerButtonNext,
  PagerHeading,
} from "../Pager";
import { ElementRow } from "../Pitch";
import { NotFound } from "../Routes";
import Table from "../Table";
import TabPanel from "../tabs/TabPanel";
import Tabs from "../tabs/Tabs";
import Title from "../Title";
import BenchUnit from "./BenchUnit";
import ElementExplainDialog from "./ElementExplainDialog";
import EntryEventTable from "./EntryEventTable";
import PitchWrapper from "./PitchWrapper";
import PitchFormation from "./PitchFormation";
import valueForPlayerElement from "./valueForPlayedElement";

const PointsScoreboardWrap = styled.div`
  margin: ${({ theme }) => theme.space[2]};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.radii[0]};
  background: ${({ theme }) => theme.colors.backgroundwhite50};

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    margin: ${({ theme }) => `${theme.space[3]} ${theme.space[0]}`};
  }
`;

const StyledEventPager = styled(EventPager)`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding: 0 23%;
  }
`;

const StyledPagerHeading = styled(PagerHeading)`
  margin-bottom: -2.4rem;
  font-family: ${({ theme }) => theme.fonts.impact};
  font-size: ${({ theme }) => theme.fontSizes[5]};
  text-align: center;
  padding: 1rem 0;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-bottom: -2rem;
    padding: 1.8rem 0;
  }
`;

const PagerButtonStyles = css`
  flex-basis: 10%;
  margin-top: ${({ theme }) => `-${theme.space[4]}`};
  font-family: ${({ theme }) => theme.fonts.impactMedium};

  > a {
    min-width: 110px;
    padding: 0.6rem 1.5rem;
    border-radius: 40px;
    background-color: ${({ theme }) => theme.colors.primary};
    font-size: ${({ theme }) => theme.fontSizes[3]};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-top: 0.3rem;
  }
`;

const StyledPagerButton = styled(PagerButton)`
  ${PagerButtonStyles};
`;

const StyledPagerButtonNext = styled(PagerButtonNext)`
  ${PagerButtonStyles};
`;

const DreamTeamWrap = styled.div`
  width: 60%;
  margin: ${({ theme }) => theme.space[4]} auto;
  font-weight: 600;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: 40%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 30%;
  }
`;

const TableWrap = styled.div`
  margin-bottom: ${({ theme }) => theme.space[2]};
`;

const PointsScoreboard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: ${({ theme }) => theme.space[3]} 0;
  text-align: center;
`;

const ScoreboardPrimary = styled.div`
  flex: 0 0 38%;
  border: 1px solid ${({ theme }) => theme.colors.greyDarker4};
  border-radius: ${({ theme }) => theme.radii[1]};
  background-color: ${({ theme }) => theme.colors.greyDarker2};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex-basis: calc(22% - ${({ theme }) => theme.space[1]});
  }
`;

interface IPrimaryPanelProps {
  chipPlayed?: boolean;
}

const PrimaryPanel = styled.div<IPrimaryPanelProps>`
  position: relative;
`;

const PrimaryHeading = styled.h4`
  margin: 0;
  padding-top: ${({ theme }) => theme.space[3]};
  font-family: ${({ theme }) => theme.fonts.base};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  font-weight: 300;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding-top: ${({ theme }) => theme.space[3]};
  }
`;

const PrimaryValue = styled.div`
  position: relative;
  font-family: ${({ theme }) => theme.fonts.impact};
  font-size: ${({ theme }) => theme.fontSizes[7]};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    font-size: ${({ theme }) => theme.fontSizes[6]};
  }
`;

const ReloadButton = styled.button`
  position: absolute;
  top: 50%;
  left: ${({ theme }) => theme.space[0]};
  transform: translateY(-50%);
  padding: ${({ theme }) => theme.space[1]};
  border: 0;
  background-color: ${({ theme }) => theme.colors.primary};
  line-height: 1;
  cursor: pointer;
`;

const Reload = styled(BaseReload)`
  fill: ${({ theme }) => theme.colors.black};
`;

const ReloadButtonText = styled.span`
  ${hideVisually()}
`;

const ScoreboardSecondary = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const SecondaryPanel = styled.div`
  flex: 1;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    justify-content: space-around;
  }
`;

const SecondaryItem = styled.div`
  overflow: auto;
  text-align: center;
`;

const SecondaryHeading = styled.h4`
  margin: ${({ theme }) => theme.space[1]} 0;
  font-family: ${({ theme }) => theme.fonts.base};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  font-weight: 300;
`;

const SecondaryValue = styled.div`
  margin: ${({ theme }) => `${theme.space[1]} ${theme.space[0]}`};
  font-family: ${({ theme }) => theme.fonts.base};
  font-size: 2.2rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    font-family: ${({ theme }) => theme.fonts.impact};
    font-size: 2.4rem;
  }
`;

const DreamTeamLinkIcon = styled(BaseDreamTeam)`
  ${size(16)}
  margin-right: ${({ theme }) => theme.space[1]};
  color: ${({ theme }) => theme.colors.white};
  fill: ${({ theme }) => theme.colors.red};
`;

const DreamTeamLink = styled(ReachLink)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: ${({ theme }) => theme.space[1]};
  border-radius: 15px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.red};
  text-decoration: none;
`;

const ChipStatus = styled.div`
  padding: 1.6rem ${({ theme }) => theme.space[2]};
  border-bottom-left-radius: ${({ theme }) => theme.radii[1]};
  border-bottom-right-radius: ${({ theme }) => theme.radii[1]};
  background-color: ${({ theme }) => theme.colors.blue};
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
`;

type OwnProps = RouteComponentProps<{ entryId: string; eventId: string }> &
  WithTranslation;

interface IPropsFromState {
  elementsById: IElementsById;
  elementsDataById: IElementsEventDataById;
  entry: IEntry | null;
  entryData: IEntryEvent | null;
  event: IEvent | null;
  fixturesById: Record<string, IFixture> | null;
  formation: string;
  mine: boolean;
  now: IEvent | null;
  points: number;
  settings: ISettings;
  teamsById: ITeamsById;
}

interface IPropsFromDispatch {
  fetchEntryEventPicks: (entryId: number, eventId: number) => void;
  fetchEntrySummary: (entryId: number) => void;
  fetchEventLive: (entryId: number) => void;
  fetchFixturesForEvent: (eventId: number) => void;
}

type Props = OwnProps & IPropsFromState & IPropsFromDispatch;

interface IState {
  pickForMenu: IPickLight | null;
}

class EntryEvent extends React.Component<Props, IState> {
  public state: IState = {
    pickForMenu: null,
  };

  public handleShowMenuForPickElement = (element: number) => {
    const matches = this.props.entryData!.picks.filter(
      (p) => p.element === element
    );
    if (matches.length) {
      this.setState({ pickForMenu: matches[0] });
    }
  };

  public handleHideMenuForPick = () => {
    this.setState({ pickForMenu: null });
  };

  public renderDreamTeam = (pick: IPickLight) =>
    this.props.elementsDataById &&
    this.props.elementsDataById[pick.element] &&
    this.props.elementsDataById[pick.element].stats.in_dreamteam ? (
      <Link to={`/dream-team/${this.props.event!.id}`}>
        <DreamTeam />
      </Link>
    ) : null;

  public fetchDataAndFixtures = () => {
    this.fetchData();
    this.props.fetchFixturesForEvent(Number(this.props.eventId));
  };

  public fetchData = () => {
    this.props.fetchEntrySummary(Number(this.props.entryId));
    this.props.fetchEntryEventPicks(
      Number(this.props.entryId),
      Number(this.props.eventId)
    );
    this.props.fetchEventLive(Number(this.props.eventId));
  };

  public componentDidMount() {
    this.fetchData();
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      prevProps.eventId !== this.props.eventId ||
      prevProps.entryId !== this.props.entryId
    ) {
      this.fetchData();
    }
  }
  public render() {
    const {
      elementsById,
      elementsDataById,
      entry,
      entryData,
      event,
      fixturesById,
      formation,
      mine,
      now,
      points,
      settings,
      teamsById,
      t,
    } = this.props;
    // Handle unknown and unstarted events
    if (!now || !event || event.id > now.id) {
      return <NotFound />;
    }
    if (!entry || !entryData) {
      return null;
    }

    const chipName = entryData.active_chip
      ? getChipName(entryData.active_chip as ChipName, t)
      : "";

    // Create a new function on each render as data could have changed and
    // need to ensure a render of connected subcomponents
    const renderPickValue = valueForPlayerElement({
      elementsById,
      elementsDataById,
      fixturesById,
      teamsById,
    });
    const benchHeading: Record<string, React.ReactNode> = {
      11: <abbr title="Goalkeeper">GK</abbr>,
      12: "1",
      13: "2",
      14: "3",
    };

    // Handle events before the entry started
    return (
      <Wrapper>
        <Main>
          <Box mx={2}>
            <Title>
              {t("entryEvent.pointsTitle", "Points")} - {entry.name}
            </Title>
          </Box>
          <PointsScoreboardWrap>
            <StyledPagerHeading>{event.name}</StyledPagerHeading>
            {(event.id > entry.started_event || event.id < now.id) && (
              <StyledEventPager>
                {event.id > entry.started_event && (
                  <StyledPagerButton>
                    <ButtonLink
                      to={`/entry/${entry.id}/event/${event.id - 1}`}
                      variant="secondary"
                    >
                      <ControlArrowLeft />
                      {t("entryEvent.previous", "Previous")}
                    </ButtonLink>
                  </StyledPagerButton>
                )}
                {event.id < now.id && (
                  <StyledPagerButtonNext>
                    <ButtonLink
                      to={`/entry/${entry.id}/event/${event.id + 1}`}
                      variant="secondary"
                    >
                      {t("entryEvent.next", "Next")}
                      <ControlArrowRight />
                    </ButtonLink>
                  </StyledPagerButtonNext>
                )}
                {/* Also need to check isn't last event */}
                {mine && event.id === now.id && (
                  <StyledPagerButtonNext>
                    <ButtonLink to={`/my-team`} variant="secondary">
                      {t("entryEvent.next", "Next")}
                      <ControlArrowRight />
                    </ButtonLink>
                  </StyledPagerButtonNext>
                )}
              </StyledEventPager>
            )}
            <PointsScoreboard>
              <ScoreboardSecondary>
                <SecondaryPanel>
                  <SecondaryItem>
                    <SecondaryHeading>
                      {t("entryEvent.avgPoints", "Average Pts")}
                    </SecondaryHeading>
                    <SecondaryValue>
                      {event.average_entry_score || "-"}
                    </SecondaryValue>
                  </SecondaryItem>
                  <SecondaryItem>
                    <SecondaryHeading>
                      {t("entryEvent.highestPoints", "Highest Pts")}
                    </SecondaryHeading>
                    <SecondaryValue>
                      {event.highest_scoring_entry ? (
                        <Link
                          to={`/entry/${event.highest_scoring_entry}/event/${event.id}`}
                        >
                          {event.highest_score || "???"}
                        </Link>
                      ) : (
                        "-"
                      )}
                    </SecondaryValue>
                  </SecondaryItem>
                </SecondaryPanel>
                <ScoreboardPrimary>
                  <Box>
                    <PrimaryPanel chipPlayed={Boolean(chipName)}>
                      <PrimaryHeading>
                        {event.finished
                          ? t("entryEvent.finalPoints", "Final Points")
                          : t("entryEvent.latestPoints", "Latest Points")}
                      </PrimaryHeading>
                      <PrimaryValue>
                        {points}
                        {!event.finished && (
                          <ReloadButton onClick={this.fetchDataAndFixtures}>
                            <ReloadButtonText>
                              {t("entryEvent.reload", "Reload Points")}
                            </ReloadButtonText>
                            <Reload />
                          </ReloadButton>
                        )}
                      </PrimaryValue>
                    </PrimaryPanel>
                    {chipName && (
                      <ChipStatus>
                        {t("entryEvent.chipPlayed", "{{ chipName }} played", {
                          chipName: chipName,
                        })}
                      </ChipStatus>
                    )}
                  </Box>
                </ScoreboardPrimary>
                <SecondaryPanel>
                  <SecondaryItem>
                    <SecondaryHeading>
                      {t("entryEvent.rdRank", "RD Rank")}
                    </SecondaryHeading>
                    <SecondaryValue>
                      {entryData.entry_history.rank
                        ? entryData.entry_history.rank.toLocaleString()
                        : "-"}
                    </SecondaryValue>
                  </SecondaryItem>
                  <SecondaryItem>
                    <SecondaryHeading>
                      {t("entryEvent.transfers", "Transfers")}
                    </SecondaryHeading>
                    <SecondaryValue>
                      <Link to={`/entry/${entry.id}/transfers`}>
                        {entryData.entry_history.event_transfers}
                      </Link>{" "}
                      {entryData.entry_history.event_transfers_cost
                        ? `(-${entryData.entry_history.event_transfers_cost} pts)`
                        : ""}
                    </SecondaryValue>
                  </SecondaryItem>
                </SecondaryPanel>
              </ScoreboardSecondary>
            </PointsScoreboard>
            <DreamTeamWrap>
              <DreamTeamLink to={`/dream-team/${event.id}`}>
                <DreamTeamLinkIcon />
                {t("entryEvent.dreamTeam", "Dream Team")}
                <ControlArrowRight />
              </DreamTeamLink>
            </DreamTeamWrap>
          </PointsScoreboardWrap>

          {/* TEAM */}
          <PitchWrapper>
            <Box pt={4}>
              <Box mx={2} mb={2}>
                <Tabs centered={true}>
                  <TabPanel
                    label={t("entryEvent.pitchView", "Pitch View")}
                    link="pitch"
                  >
                    <Box mx={-2} pt={4} mt={2}>
                      <PitchFormation
                        chipName={entryData.active_chip}
                        formation={formation}
                        picks={entryData.picks}
                        renderDreamTeam={this.renderDreamTeam}
                        renderElementMenu={this.handleShowMenuForPickElement}
                        renderPickValue={renderPickValue}
                      />
                      <ElementRow>
                        {range(
                          settings.squad_squadplay,
                          settings.squad_squadsize
                        ).map((i) => (
                          <BenchUnit
                            key={i}
                            chipName={entryData.active_chip}
                            heading={benchHeading[i]}
                            pick={entryData.picks[i]}
                            renderDreamTeam={this.renderDreamTeam}
                            renderElementMenu={() =>
                              this.handleShowMenuForPickElement(
                                entryData.picks[i].element
                              )
                            }
                            renderPickValue={renderPickValue}
                          />
                        ))}
                      </ElementRow>
                    </Box>
                  </TabPanel>
                  <TabPanel
                    label={t("entryEvent.listView", "List View")}
                    link="list"
                  >
                    <Box mb={4} mt={2}>
                      <TableWrap>
                        <EntryEventTable
                          title="Starters"
                          picks={entryData.picks.slice(
                            0,
                            settings.squad_squadplay
                          )}
                          renderElementMenu={this.handleShowMenuForPickElement}
                          chipName={entryData.active_chip}
                          dataById={elementsDataById}
                        />
                      </TableWrap>
                      <TableWrap>
                        <EntryEventTable
                          title="Substitutes"
                          picks={entryData.picks.slice(
                            settings.squad_squadplay
                          )}
                          renderElementMenu={this.handleShowMenuForPickElement}
                          chipName={entryData.active_chip}
                          dataById={elementsDataById}
                        />
                      </TableWrap>
                    </Box>
                  </TabPanel>
                </Tabs>
              </Box>
            </Box>
          </PitchWrapper>
          {entryData.automatic_subs.length > 0 && (
            <>
              <Box mx={2}>
                <Title>
                  {t("entryEvent.automaticSubs", "Automatic Substitutions")}
                </Title>
              </Box>
              <Table>
                <thead>
                  <tr>
                    <th>{t("entryEvent.playerOut", "Player out")}</th>
                    <th>{t("entryEvent.playerIn", "Player in")}</th>
                  </tr>
                </thead>
                <tbody>
                  {entryData.automatic_subs.map((s) => (
                    <tr key={s.element_out}>
                      <td>{elementsById[s.element_out].web_name}</td>
                      <td>{elementsById[s.element_in].web_name}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
          {this.state.pickForMenu && (
            <ElementExplainDialog
              elementId={this.state.pickForMenu.element}
              eventId={event.id}
              closeDialog={this.handleHideMenuForPick}
            />
          )}
          <Fixtures eventId={event.id} />
        </Main>
        <Secondary>
          <Entry entryId={entry.id} />
        </Secondary>
      </Wrapper>
    );
  }
}
export { EntryEvent as EntryEventTest };

const mapStateToProps = (
  state: RootState,
  ownProps: OwnProps
): IPropsFromState => {
  const entryId = Number(ownProps.entryId);
  const eventId = Number(ownProps.eventId);
  const player = getPlayerData(state) as IPlayer;
  return {
    elementsById: getElementsById(state),
    elementsDataById: getElementsEventDataById(state, eventId),
    entry: getEntry(state, entryId),
    event: getEventsById(state)[eventId],
    now: getCurrentEvent(state),
    entryData: getEntryEventPicks(state, entryId, eventId),
    fixturesById: getFixturesForEventById(state, eventId),
    formation: getEntryEventFormation(state, entryId, eventId),
    points: getEntryEventPoints(state, entryId, eventId),
    settings: getSettings(state) as ISettings,
    teamsById: getTeamsById(state),
    mine: Boolean(player && player.entry && player.entry === entryId),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchEntryEventPicks: (entryId, eventId) =>
    dispatch(fetchEntryEventPicks(entryId, eventId)),
  fetchEntrySummary: (entryId) => dispatch(fetchEntrySummary(entryId)),
  fetchEventLive: (eventId) => dispatch(fetchEventLive(eventId)),
  fetchFixturesForEvent: (eventId) => dispatch(fetchFixtures(eventId)),
});

// Have to split declarations due to TS error - https://github.com/DefinitelyTyped/DefinitelyTyped/issues/50211
const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(EntryEvent));
const translated = withTranslation()(connected);

export default translated;
