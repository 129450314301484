import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Parser from "rss-parser";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import { getURL } from "../../core/store/urls/reducers";
import { fetchURL } from "../../core/store/urls/thunks";
import ProviderHeader from "../provider-components/ProviderHeader";
import {
  ProviderArticleImg,
  ProviderContent,
  ProviderLink,
  ProviderSection,
} from "../provider-components/ProviderStyles";
import filterOnSSL from "./filterOnSSL";

const parser = new Parser();

const Article = styled.article`
  padding: ${({ theme }) => theme.space[4]};
  border: 3px solid transparent;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    border: 3px solid ${({ theme }) => theme.colors.blueDarker1};
    border-radius: ${({ theme }) => theme.radii[0]};
    background-color: ${({ theme }) => theme.colors.backgroundwhite50};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    padding: ${({ theme }) => theme.space[2]};
    width: 33%;
  }
`;

interface IProps {
  link: string;
  url: string;
  title: string;
  numberOfArticles: number;
}

const ScoutProvider: React.FC<IProps> = ({
  link,
  url,
  title,
  numberOfArticles,
}) => {
  const [providerData, setProviderData] = useState<Parser.Item[] | null>(null);

  // Fetch RSS
  const reduxDispatch = useDispatch<ThunkDispatch>();
  useEffect(() => {
    reduxDispatch(fetchURL(url));
  }, [reduxDispatch, url]);

  // Get raw RSS content, will be null until we have some
  const content = useSelector((state: RootState) => getURL(state, url));

  // Parse RSS data
  useEffect(() => {
    const parseContent = async () => {
      try {
        const feed = await parser.parseString(content.text);
        if (feed && feed.items) {
          const filteredSslFeedItems = filterOnSSL(feed.items).splice(
            0,
            numberOfArticles
          );

          setProviderData(filteredSslFeedItems);
        }
      } catch (e) {}
    };
    if (content) {
      parseContent();
    }
  }, [content, numberOfArticles]);

  if (!providerData) {
    return null;
  }

  return (
    <ProviderSection>
      <ProviderHeader title={title} link={link} />
      <ProviderContent backgroundColor="transparent">
        {providerData.map((item: Parser.Item) => {
          return (
            <Article key={item.title}>
              <ProviderLink
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.enclosure && (
                  <ProviderArticleImg
                    src={item.enclosure.url}
                    alt="Article fea"
                  />
                )}
                <h4>{item.title}</h4>
              </ProviderLink>
            </Article>
          );
        })}
      </ProviderContent>
    </ProviderSection>
  );
};

export default ScoutProvider;
