import { createGlobalStyle } from "styled-components/macro";
import theme from "./theme";

const GlobalStyle = createGlobalStyle`
  html {
    min-height: 100%;
    font-family: ${theme.fonts.base};
	/* has to be important so affiliate CSS doesn't override */
	font-size: 62.5% !important;
	line-height: 1.5;
	-webkit-font-smoothing: antialiased;
  }

  body {
    margin: 0;
	color: ${theme.colors.black};
    font-size: ${theme.fontSizes[2]};
    background: ${theme.colors.backgroundGradient};
	}

	.ism {
		box-sizing: border-box;
		font-family: ${theme.fonts.base};

		*, *::before, *::after {
			box-sizing: inherit;
		}


		p {
			line-height: 1.6;
			font-size: 14px;
		}

		input[type="search"] {
			box-sizing: inherit;
		}
	}
`;

export default GlobalStyle;
