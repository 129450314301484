import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { getTeamsById } from "../core/store/teams/reducers";
import { ITeam } from "../core/store/teams/types";
import Badge from "./Badge";

const BannerBackground = styled.div`
  background-color: transparent;
`;

const Banner = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: ${({ theme }) => theme.space[4]};
    max-width: 1220px;
    margin: auto;
    padding: ${({ theme }) => `${theme.space[2]} 0`};
  }
`;

const StyledBadge = styled(Badge)`
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.2);
  }
`;

const BadgeBanner = () => {
  const teamsById = useSelector(getTeamsById);
  const teams = Object.values(teamsById);
  const validTeams = teams.filter((team) => !team.unavailable);

  const clubUrls: { [key: number]: string } = {
    16: "https://www.fkh.no/",
    48: "https://www.sandefjordfotball.no/",
    1438: "https://kaaffa.no/",
    5043: "https://www.sarpsborg08.no/",
    9: "https://www.godset.no/",
    2718: "https://www.kristiansundbk.no/",
    1: "https://www.brann.no/",
    6: "https://www.moldefk.no/",
    3: "https://www.til.no/",
    8: "https://www.glimt.no/",
    5: "https://www.rbk.no/",
    7: "https://www.vikingfotball.no/",
    40: "https://www.fredrikstadfk.no/",
    50: "https://www.hamkam.no/",
    13: "https://www.brynefk.no/",
    15: "https://www.vif-fotball.no/",
  };

  if (!teams) {
    return null;
  }

  return (
    <BannerBackground>
      <Banner>
        {validTeams.map((team: ITeam, index: number) => (
          <a
            href={clubUrls[team.code]}
            target="_blank"
            rel="noopener noreferrer"
            key={`${index}-${team.code}`}
          >
            <StyledBadge
              className="badgeHeader"
              team={team}
              isPresentation={false}
              sizes="30px"
            />
          </a>
        ))}
      </Banner>
    </BannerBackground>
  );
};

export default BadgeBanner;
